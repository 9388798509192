import { productCategories } from 'globals/constants';
import dotPngToSvg from './dotPngToSvg';

export default product => {
  const productObject = {
    id: product.ID,
    title: product.post_title,
    imageAltText: product.post_title,
    imageURL: dotPngToSvg(product.thumbnail || ''),
    categories: product.categories,
    cartValue: product.price || '',
    productPageLink: product.url,
    isPurchased: true,
    postExcerpt: product.post_excerpt,
    isExternal: product.type === 'external'
    // userMeta: product.user_meta || []
  };

  const isPaper = product.categories.some(cat => productCategories.papers.includes(cat.slug));
  if (isPaper) {
    productObject.isPaper = isPaper;
  }

  // If mark schemes add test_file as well
  const isMarkSchemes = product.categories.some(cat => cat.slug === productCategories.markSchemes);
  if (isMarkSchemes) {
    productObject.test_file = product.test_file;
    productObject.isMarkSchemes = isMarkSchemes;
  }

  const isMock = product.categories.some(cat => cat.slug === productCategories.mock);
  if (isMock) {
    productObject.userMeta = product.user_meta || [];
    productObject.isMock = isMock;
    productObject.type = 'mock';
    productObject.cutOffDate = product?.cutoffDate;
    productObject.orderDate = product?.orderDate;
  }

  const isWritingService = product.categories.some(
    cat => cat.slug === productCategories.writingService
  );
  if (isWritingService) {
    productObject.isWritingService = isWritingService;
  }

  return productObject;
};
