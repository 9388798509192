import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { useResetPassword } from 'api/auth';

import Button from 'components/htmlElements/Button';
import H2 from 'components/htmlElements/H2';

// Styles
import { spacer } from '@styles/utilities';

const Schema = Yup.object().shape({
  password: Yup.string()
    .required()
    .min(6),
  confirmPassword: Yup.string()
    .required()
    .test('passwords-match', 'Passwords not match with password typed above', function checkValue(
      value
    ) {
      return this.parent.password === value;
    })
});

const SetPassword = ({ queryParamKey, queryParamLogin }) => {
  const [formSubmitted, updateFormSubmission] = useState({ success: false, message: '' });
  const { resetPasswordMutation, status } = useResetPassword();

  const handleSubmit = async (values, formikBag) => {
    formikBag.setSubmitting(true);

    const response = await resetPasswordMutation({
      pass_1: values.password,
      pass_2: values.confirmPassword,
      key: queryParamKey,
      login: queryParamLogin
    });
    formikBag.setSubmitting(false);

    if (response.error) {
      formikBag.setErrors({ confirmPassword: response.error });
    } else {
      updateFormSubmission({
        success: true,
        message: response.message
      });
    }
  };

  return (
    <Fragment>
      {!formSubmitted.success ? (
        <Fragment>
          <H2 style={{ marginBottom: 20 }}>Create a new Password</H2>
          <Formik
            className="set-password-modal-form clearfix"
            initialValues={{
              password: '',
              confirmPassword: ''
            }}
            onSubmit={handleSubmit}
            validationSchema={Schema}
          >
            {({ errors, touched, isSubmitting, isValid }) => (
              <Form className="set-password-modal-form clearfix">
                <div className="input-field-wrapper">
                  <Field type="password" name="password" id="set-password" />
                  {errors.password && touched.password && (
                    <label id="set-password-error" className="error">
                      {errors.password}
                    </label>
                  )}
                  <label className="placeholder" htmlFor="set-password">
                    Password
                  </label>
                </div>
                <div className="input-field-wrapper">
                  <Field type="password" name="confirmPassword" id="confirm-set-password" />
                  {errors.confirmPassword && touched.confirmPassword && (
                    <label id="confirm-set-password-error" className="error">
                      {errors.confirmPassword}
                    </label>
                  )}
                  <label className="placeholder" htmlFor="confirm-set-password">
                    Confirm Password
                  </label>
                </div>
                <div className="txt-right mr-t-20">
                  <Button
                    disabled={isSubmitting || !isValid}
                    type="submit"
                    loading={status === 'loading'}
                  >
                    Save Password
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </Fragment>
      ) : (
        <H2 css={spacer.mrBT30}>{formSubmitted.message}</H2>
      )}
    </Fragment>
  );
};

SetPassword.propTypes = {
  queryParamKey: PropTypes.string,
  queryParamLogin: PropTypes.string
};

SetPassword.defaultProps = {
  queryParamKey: '',
  queryParamLogin: ''
};

export default SetPassword;
