import React, { Fragment, useEffect } from 'react';

import { UserInfoContainer, useValidateToken } from 'api/auth';
import { navigate } from '@reach/router';
import { ROUTE_PATHS } from 'globals/constants';

export default props => {
  const { validateToken } = useValidateToken();
  const { accepted_terms: acceptedTerms, token } = UserInfoContainer.useContainer();

  useEffect(() => {
    validateToken();
  }, [validateToken, token]);

  if (token && acceptedTerms === 0) {
    navigate(ROUTE_PATHS.termsAndConditions);
    return null;
  }

  return <Fragment {...props} />;
};
