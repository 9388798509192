/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/** @jsx jsx */
import { Fragment, useState, useEffect } from 'react';
import { css, jsx } from '@emotion/core';
import PropTypes from 'prop-types';
import Img from 'react-image';

import Icon from 'components/Icon';
import currencySymbol from 'utils/getCurrencySymbol';
import { Link } from 'components/Router/Router';
import A from 'components/htmlElements/A';

// import { useAddToCart, useGetCart } from 'api/coCart';
import { site } from 'globals/constants';

const getLinkText = (type, progress) => {
  switch (type) {
    case 'video':
      return progress ? 'Continue Watching' : 'Watch Now';
    case 'cart':
      return 'Add to Basket';
    case 'cart-added':
      return 'Added';
    case 'mock':
      return 'View Details';
    default:
      return 'Download';
  }
};

const ProductCard = ({
  onClick,
  id: productId,
  type,
  title,
  link,
  imageAltText,
  imageURL,
  progress,
  date,
  duration,
  cartValue,
  cartCurrency,
  ...restProps
}) => {
  const WrapperTag = link ? Link : 'div';

  const [imageLoaded, toggleImageLoaded] = useState(false);

  // // Updating cart
  // const { addToCart, status: addToCartStatus } = useAddToCart();
  // const { data: cartData } = useGetCart();

  const [inCart, updateInCart] = useState(false);
  // useEffect(() => {
  //   if (cartData && Object.keys(cartData)) {
  //     updateInCart(Object.keys(cartData).some(item => cartData[item].product_id === productId));
  //   }
  // }, [cartData, productId]);

  // const handleCartClick = event => {
  //   event.preventDefault();
  //   if (!inCart) {
  //     addToCart(productId);
  //   }
  // };

  const handleCardClick = () => {
    // Exit if product is purchaseable
    if (!restProps.isPurchased && !restProps.isSolution) window.open(restProps.productPageLink);
  };

  return (
    <div
      css={css`
        cursor: pointer;
      `}
      onClick={onClick}
      onKeyDown={() => {}}
      className={`product-card ${type === 'cart' ? 'paper-card' : 'solution-card'}`}
    >
      <WrapperTag to={link} onClick={handleCardClick}>
        {type === 'cart' ? (
          <img src={imageURL} alt={imageAltText} />
        ) : (
          <Fragment>
            <div className="solution-card-cover-wrapper">
              {!imageLoaded && <div className="image-placeholder" />}
              <Img src={imageURL} onLoad={() => toggleImageLoaded(true)} />
              <div className="card-icon-wrapper">
                {type === 'pdfProduct' && (
                  <div className="card-icon">
                    <Icon icon="arrow-down" className="icon-arrow-down action-icon" />
                  </div>
                )}
              </div>
            </div>
            {!!progress && (
              <div className="progress-bar-wrapper">
                <div className="progress-indicator" style={{ width: `${progress}%` }} />
              </div>
            )}
          </Fragment>
        )}
        <h4
          className="product-title eqHeightElem"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: title }}
        />
        {/* action btn:
        Report Card:
          Download / More Details
          - In case it is download use the download modal component to dynamically update the file download link
        Video Card:
          Watch Now / Continue Watching
      */}
        {type !== 'cart' && (
          <h5 className="card-action-btn inside-a">{getLinkText(type, progress)}</h5>
        )}
      </WrapperTag>
      {type === 'cart' && (
        <Fragment>
          {date && (
            <h5 className="h5-v2 paper-card-datetime">
              <span>{date}</span>
              {duration && <span>{duration}</span>}
            </h5>
          )}
          <div className="product-action-row">
            <div>
              {!restProps.isExternal && (
                <h5 className="price">
                  {cartCurrency}
                  {cartValue}
                </h5>
              )}
            </div>
            <div>
              {/* {restProps.isMock ? ( */}
              <A
                href={restProps.productPageLink}
                target="_blank"
                rel="noopener noreferrer"
                className="add_to_cart_button h5"
              >
                View Product
              </A>
              {/* ) : (
                <A
                  as="p"
                  onClick={event => handleCartClick(event)}
                  className="add_to_cart_button h5"
                >
                  {addToCartStatus === 'loading'
                    ? 'Adding'
                    : getLinkText(inCart ? 'cart-added' : type)}
                </A>
              )} */}
            </div>
          </div>
          {inCart ? (
            <a
              href={site.pages.basket}
              target="_blank"
              rel="noopener noreferrer"
              className="added_to_cart h5"
              title="View Basket"
            >
              View Basket
              <Icon icon="arrow-right" />
            </a>
          ) : (
            ''
          )}
          <div className="lili" />
        </Fragment>
      )}
    </div>
  );
};

ProductCard.propTypes = {
  type: PropTypes.string,
  id: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  link: PropTypes.string,
  imageAltText: PropTypes.string.isRequired,
  progress: PropTypes.number,
  date: PropTypes.string,
  duration: PropTypes.string,
  cartValue: PropTypes.string,
  cartCurrency: PropTypes.string,
  imageURL: PropTypes.string,
  onClick: PropTypes.func
};

ProductCard.defaultProps = {
  link: '',
  imageURL: '',
  type: 'pdfProduct',
  progress: 0,
  date: '',
  duration: '',
  cartValue: '',
  cartCurrency: currencySymbol,
  onClick: () => {}
};

export default ProductCard;
