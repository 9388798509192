/** @jsx jsx */
import FlipMove from 'react-flip-move';
import { jsx } from '@emotion/core';
import { spacer } from '@styles/utilities';
import ProductCard from 'components/ProductCard/ProductCard';
import SectionTitle from 'components/SectionTitle/SectionTitle';
import PropTypes from 'prop-types';
import { Waypoint } from 'react-waypoint';
import NoProducts from 'components/NoProducts/NoProducts';
import { Fragment } from 'react';
import { eCommerceSiteLinks } from 'globals/constants';
import PageLoader from 'components/PageLoader/PageLoader';

const CoursesPage = ({ courses, canFetchMore, fetchMore, isFetchingMore }) => {
  return (
    <Fragment>
      <div className="container no-push-container" css={spacer.mrBT40}>
        <div className="row">
          <div className="col-12">
            <div css={spacer.padLR30}>
              {!courses.length ? (
                <NoProducts productName="Masterclasses" linkToBuy={eCommerceSiteLinks.courses} />
              ) : (
                <Fragment>
                  <SectionTitle title="Masterclasses" />
                </Fragment>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="container no-push-container" css={spacer.mrBT40}>
        <FlipMove className="row">
          {courses.map(paper => (
            <div className="col-6 col-md-4 col-xl-3" key={paper.id} css={[spacer.padB80]}>
              <ProductCard {...paper} />
            </div>
          ))}
        </FlipMove>
        <Waypoint
          bottomOffset="-40%"
          onEnter={() => {
            console.info('Loading more Courses');
            if (canFetchMore) {
              fetchMore();
            }
          }}
        />
        {isFetchingMore ? <PageLoader height="100px" isRelative /> : ''}
      </div>
    </Fragment>
  );
};

CoursesPage.propTypes = {
  courses: PropTypes.array.isRequired,
  fetchMore: PropTypes.func,
  // canFetchMore can be number or boolean, number if more pages are available, boolean `false` if no next page available
  canFetchMore: PropTypes.any,
  isFetchingMore: PropTypes.bool
};
CoursesPage.defaultProps = {
  fetchMore: () => {},
  canFetchMore: false,
  isFetchingMore: false
};

export default CoursesPage;
