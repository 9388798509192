// This file should contain all global styles
import { css } from '@emotion/core';

import { colors, mixins, misc } from '@styles/utilities';
import { overlayNotification } from 'components/NotificationBox/NotificationBox.styles';
// import reboot from './bootstrap-reboot';
import { navGlobalStyles } from 'components/Navigation/Navigation.styles';
import FormFieldResets from './FormFieldResets';

// Import additional global styles
const globalStyles = css`
  ${'' /* ${reboot} */}

  html, body {
    height: 100%;
    margin: 0;
    padding: 0;
    width: 100%;
    background-color: ${colors.skyBlue};
  }

  body {
    ${overlayNotification};
  }

  #app {
    background-color: ${colors.skyBlue};
  }

  a,
  .a {
    ${mixins.transition()};
    color: ${colors.primaryLinkHover};
    outline: none;
    text-decoration: none;
    &.underline {
      text-decoration: underline;
    }
    &.inherit-color {
      color: inherit;
    }
    .inside-a,
    svg {
      ${mixins.transition()};
    }
    &:hover {
      color: ${colors.primaryLinkHover};
      outline: none;
      text-decoration: none;
      svg {
        fill: ${colors.primaryLinkHover};
      }
      .inside-a {
        color: ${colors.primaryLinkHover};
      }
    }
    &:active,
    &:focus {
      color: ${colors.primaryLinkHover};
      outline: none;
      text-decoration: none;
      svg {
        fill: ${colors.primaryLinkHover};
      }
    }
  }

  a.reverse-a,
  a.active {
    color: ${colors.primaryLinkHover};
    &:hover {
      color: ${colors.secondaryLinkHover};
    }
  }

  img {
    vertical-align: bottom;
    height: auto;
  }

  video {
    vertical-align: bottom;
  }

  svg {
    vertical-align: bottom;
  }

  ul,
  ol {
    padding: 0;
  }

  .styled-list {
    ul,
    ol {
      padding: 0 0 0 2.5rem;
      li {
        margin-top: 10px;
      }
    }
    ol {
      list-style-type: decimal;
    }
    ul {
      list-style-type: disc;
    }
  }

  html.touch {
    video::-webkit-media-controls-start-playback-button {
      display: none !important; /* stylelint-disable-line declaration-no-important */
      -webkit-appearance: none;
    }

    video::-webkit-media-controls {
      display: none !important; /* stylelint-disable-line declaration-no-important */
    }
  }

  /* Centered columns styles
  @source [http://www.minimit.com/articles/solutions-tutorials/bootstrap-3-responsive-centered-columns] */
  .row-centered {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    html.no-flexbox & {
      text-align: center;
    }

    &.table-cell-fallback {
      html.no-flexbox & {
        ${mixins.vCenter()};
        > * {
          width: 1%;
        }
      }
    }

    &.flex-nowrap {
      flex-wrap: nowrap;
    }
  }

  .col-centered {
    float: none;
    flex: 0 1 auto; /* See http://caniuse.com/#search=flex issue 8 */
    flex-basis: auto;

    html.no-flexbox & {
      display: inline-block;
      margin-right: -4px;
      vertical-align: top;
    }
  }

  .container {
    ${mixins.respondTo(misc.viewport['b-vp'])} {
      padding-left: 20px;
      padding-right: 20px;
    }
    ${mixins.respondTo(misc.viewport['c-vp'])} {
      padding-left: 15px;
      padding-right: 15px;
    }
    ${mixins.respondTo(misc.viewport['e-vp'])} {
      padding-left: 45px;
      padding-right: 45px;
    }
  }
  .no-push-container {
    ${mixins.respondTo(misc.viewport['c-vp'])} {
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  ${mixins.respondToMax(misc.containerPaddingBreakpoint())} {
    .container {
      padding-left: 8px;
      padding-right: 8px;
    }
    .container:not(.no-push-container) {
      padding-left: 23px; /* 15 + 8. 15px is smaller spacing for cards on mobile */
      padding-right: 23px;
    }
    .row {
      margin-left: -8px;
      margin-right: -8px;
    }
    [class*='col-'],
    .card-column,
    .products-card-carousel-section .card-column {
      padding-left: 8px;
      padding-right: 8px;
    }
  }
  ${mixins.respondToMax(misc.viewport['b-vp'])} {
    .container {
      padding-left: 10px;
      padding-right: 10px;
    }
    .container:not(.no-push-container) {
      padding-left: 10px; /* 15 + 8. 15px is smaller spacing for cards on mobile */
      padding-right: 10px;
    }
    .row {
      margin-left: -10px;
      margin-right: -10px;
    }
    [class*='col-'],
    .card-column,
    .products-card-carousel-section .card-column {
      padding-left: 10px;
      padding-right: 10px;
    }
  }

  ${FormFieldResets}
  ${navGlobalStyles}
`;

// eslint-disable-next-line import/prefer-default-export
export const globals = {
  ...globalStyles
};
