import React from 'react';

import UserSolutionsContainer from 'api/userSolutions';

import Layout from 'layouts/';
import { Redirect } from '@reach/router';
import { ROUTE_PATHS } from 'globals/constants';
import PageLoader from 'components/PageLoader/PageLoader';
import SolutionsPage from './page';

const SolutionsContainer = () => {
  const {
    solutions,
    error,
    status
    // canFetchMore,
    // fetchMore,
    // isFetchingMore
  } = UserSolutionsContainer.useContainer();
  if (error) {
    return <Redirect to={ROUTE_PATHS.notFound} />;
  }

  return (
    <Layout>
      {status !== 'success' && <PageLoader isFetching isRelative atPageHeight />}
      <SolutionsPage
        solutions={solutions}
        // canFetchMore={canFetchMore}
        // fetchMore={fetchMore}
        // isFetchingMore={isFetchingMore}
      />
    </Layout>
  );
};
export default () => (
  <UserSolutionsContainer.Provider>
    <SolutionsContainer />
  </UserSolutionsContainer.Provider>
);
