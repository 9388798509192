/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Link } from 'components/Router';

const NavLink = props => (
  <Link
    {...props}
    getProps={({ isCurrent }) => {
      // the object returned here is passed to the
      // anchor element's props
      return {
        active: isCurrent ? 'true' : 'false'
      };
    }}
  />
);

export default NavLink;
