/* eslint-disable no-unused-vars */
/** @jsx jsx */
import { jsx } from '@emotion/core';
// import PropTypes from 'prop-types';
import { useState, useRef, useEffect, useCallback, useLayoutEffect, Fragment } from 'react';
import { createContainer } from 'unstated-next';
import scrollbarWidth from 'scrollbarwidth';
import { globalHistory } from '@reach/router';

import useCurrentBreakpoint from 'hooks/useCurrentBreakpoint';
import useBelowNavBreakpoint from 'hooks/useBelowNavBreakpoint';
import useChevron from 'hooks/useChevron';

import Icon from 'components/Icon';
import A from 'components/htmlElements/A';
import NotificationBox from 'components/NotificationBox';
import ProfileBox from 'components/ProfileBox';
import BlackNav from 'components/BlackNav';
import StyledNavLink from 'components/htmlElements/StyledNavLink';

// Get user info
import { UserInfoContainer } from 'api/auth';

import { spacer, bgColor, txtColor } from '@styles/utilities';

import { ROUTE_PATHS, site } from 'globals/constants';
import UserNotificationsContainer, { useToggleNotificationRead } from 'api/userNotifications';
import useBottomNavHeight from 'hooks/useBottomNavigationHeight';
import usePrimarnavHeight from 'hooks/usePrimarynavHeight';
import useClickedOutsideComponent from 'hooks/useClickedOutsideComponent';
// import { useGetCart } from 'api/coCart';
import NavLink from './NavLink';
import MobileNavOverlay from './MobileNavOverlay';

import * as styles from './Navigation.styles';

const { HAMBURGER_OPEN_CLASS } = styles;

const useNavigationGlobals = () => {
  const [fauxMobileNavHeight, updateFauxMobileNavHeight] = useState(0);

  const setHeight = height => updateFauxMobileNavHeight(height);

  return { fauxMobileNavHeight, setHeight };
};
export const NavigationGlobalsContainer = createContainer(useNavigationGlobals);

const Navigation = () => {
  const primaryNav = useRef(null);

  const notificationBtn = useRef(null);
  const notificationBtnMainNav = useRef(null);
  const notificationBoxEl = useRef(null);

  const profileBoxBtn = useRef(null);
  const profileBoxBtnMobile = useRef(null);
  const profileBoxEl = useRef(null);

  const mainNav = useRef(null);

  const hamburger = useRef(null);
  const mobileNavLinkScreen = useRef(null);

  const { breakpointInfo } = useCurrentBreakpoint();
  const isBelowNavBreakpoint = useBelowNavBreakpoint();

  // const html = document.documentElement;
  const { body } = document;

  // Get user info from local state
  const userInfo = UserInfoContainer.useContainer();
  const { unreadNotifications } = UserNotificationsContainer.useContainer();
  const { toggleNotificationRead } = useToggleNotificationRead();

  // Toggle notification box
  const [notificationState, toggleNotificationState] = useState({
    notificationsVisible: false,
    top: 0,
    left: 0,
    height: `auto`
  });
  const { bottomNavHeight } = useBottomNavHeight();
  const { primarynavHeight } = usePrimarnavHeight();

  const notificationToggle = useCallback(() => {
    toggleNotificationState(prevState => ({
      ...prevState,
      notificationsVisible: !prevState.notificationsVisible
    }));
  }, []);
  useClickedOutsideComponent(notificationBoxEl, notificationToggle);

  useEffect(() => {
    if (notificationState.notificationsVisible) {
      body.classList.add('overlay-notification');
    } else {
      body.classList.remove('overlay-notification');
    }
  }, [body.classList, notificationState.notificationsVisible]);

  // Update notification box position
  useLayoutEffect(() => {
    if (notificationBoxEl.current) {
      const bounds = notificationBtn.current
        ? notificationBtn.current.getBoundingClientRect()
        : notificationBtnMainNav.current.getBoundingClientRect();

      const notificationBoxBounds = notificationBoxEl.current.getBoundingClientRect();
      const windowHeight = window.innerHeight;
      let top = 0;
      let left = 0;
      let height = 0;
      if (breakpointInfo.breakpoint.includes('c-vp')) {
        top = `${primarynavHeight}px`;
        left = 'auto';
        height = `${windowHeight - primarynavHeight - bottomNavHeight}px`;
      } else {
        top = bounds.top + bounds.height + 5;
        left = bounds.left + bounds.width - notificationBoxBounds.width;
        height = `auto`;
      }

      toggleNotificationState(prevState => ({
        ...prevState,
        top,
        left,
        height
      }));
    }
  }, [
    bottomNavHeight,
    breakpointInfo.breakpoint,
    primarynavHeight,
    notificationState.notificationsVisible
  ]);

  // Toggle Profile Box
  const { chevron, toggleChevronDirection } = useChevron();
  const [profileBoxState, toggleProfileBoxState] = useState({
    profileBoxVisible: false,
    top: 0,
    left: 0
  });

  const profileBoxToggle = useCallback(
    (event, updateProfileBoxState = profileBoxState) => {
      toggleProfileBoxState(prevState => ({
        ...prevState,
        profileBoxVisible: !updateProfileBoxState.profileBoxVisible
      }));
      toggleChevronDirection(updateProfileBoxState ? 'chevron-top' : 'chevron-bottom');
    },
    [profileBoxState, toggleChevronDirection]
  );

  useClickedOutsideComponent(profileBoxEl, profileBoxToggle);

  // Update profile box position
  useLayoutEffect(() => {
    if (profileBoxEl.current) {
      const bounds = profileBoxBtn.current && profileBoxBtn.current.getBoundingClientRect();
      const boundsMobile =
        profileBoxBtnMobile.current && profileBoxBtnMobile.current.getBoundingClientRect();
      const profileBoxBounds = profileBoxEl.current.getBoundingClientRect();

      let top = 0;
      let left = 0;
      if (isBelowNavBreakpoint) {
        top = boundsMobile.top - profileBoxBounds.height - 5;
        left = boundsMobile.left + boundsMobile.width - profileBoxBounds.width;
      } else {
        top = bounds.top + bounds.height + 5;
        left = bounds.left + bounds.width - profileBoxBounds.width;
      }

      toggleProfileBoxState(prevState => ({
        ...prevState,
        top,
        left
      }));
    }
  }, [isBelowNavBreakpoint, profileBoxEl, profileBoxState.profileBoxVisible]);

  // // Set faux mobile nav height (Required for footer alignment)
  // const { setHeight } = NavigationGlobalsContainer.useContainer();
  // useLayoutEffect(() => {
  //   if (isBelowNavBreakpoint) {
  //     setHeight(mainNav.current.clientHeight);
  //   } else {
  //     setHeight(0);
  //   }
  // }, [isBelowNavBreakpoint, setHeight]);

  // // Cart APIs
  // const { data: cart, status: cartStatus } = useGetCart();
  const [cartCount, updateCartCount] = useState(0);
  // useEffect(() => {
  //   if (cartStatus === 'success') {
  //     updateCartCount(cart ? Object.keys(cart).length : 0);
  //   }
  // }, [cart, cartStatus]);

  // Hamburger state
  const [hamburgerActive, isHamburgerActive] = useState(false);

  // Reset nav function (Used in multiple places)
  const resetNav = useCallback(() => {
    isHamburgerActive(false);
    body.classList.remove(HAMBURGER_OPEN_CLASS);
    body.style.paddingRight = '';

    if (mobileNavLinkScreen.current) {
      mobileNavLinkScreen.current.style.top = '';
    }
  }, [body.classList, body.style.paddingRight]);

  // Toggle Hamburger state
  function hamburgerClickEvent() {
    const primaryNavBounds = primaryNav.current.getBoundingClientRect();

    if (!hamburgerActive) {
      isHamburgerActive(true);
      body.classList.add(HAMBURGER_OPEN_CLASS);
      body.style.paddingRight = `${scrollbarWidth()}px`;

      // Adjust links screen position
      mobileNavLinkScreen.current.style.top = `${primaryNavBounds.top + primaryNavBounds.height}px`;
    } else {
      resetNav();
    }
  }

  useEffect(() => {
    const currentInstance = hamburger.current;
    currentInstance.addEventListener('click', hamburgerClickEvent);
    return () => {
      currentInstance.removeEventListener('click', hamburgerClickEvent);
    };
  });

  // Reset open state of mobile links if resized
  const toggleNavResetOnResize = useCallback(() => {
    if (!isBelowNavBreakpoint) {
      resetNav();
    }
  }, [isBelowNavBreakpoint, resetNav]);
  useEffect(() => {
    window.addEventListener('resize', toggleNavResetOnResize);
    return () => {
      window.removeEventListener('resize', toggleNavResetOnResize);
    };
  }, [toggleNavResetOnResize]);

  // Listen for global history on mount. (To clear any mobile open state)
  useEffect(() => {
    // Solution ref: https://github.com/reach/router/issues/262
    globalHistory.listen(({ action }) => {
      if (action === 'PUSH') {
        resetNav();
      }
    });
  }, [resetNav]);

  return (
    <Fragment>
      <div css={styles.primaryNavFaux} style={{ height: primarynavHeight }} />
      <header className="header primary-nav" ref={primaryNav} css={styles.navigationStyles}>
        {!isBelowNavBreakpoint && (
          <BlackNav
            checkoutUrl={site.pages.basket}
            itemsInCart={cartCount}
            unreadNotifications={unreadNotifications.length}
            notificationToggle={notificationToggle}
            ref={{ notificationRef: notificationBtn, profileRef: profileBoxBtn }}
            chevron={chevron}
            profileBoxToggle={profileBoxToggle}
          />
        )}
        <div className="container main-nav-container">
          <div className="row">
            <div className="nav-col col-sm-12">
              <div className="nav-band">
                <div className="nav nav-left">
                  <div className="logo">
                    <A href={site.url} target="_blank">
                      <img src={require('images/logo-horizontal.svg').default} alt="logo.svg" />
                    </A>
                  </div>
                </div>
                <div className="nav nav-right">
                  {!isBelowNavBreakpoint && (
                    <nav className="main-nav nav-links-container" ref={mainNav}>
                      <ul className="main-nav-inner clearfix">
                        <li className="nav-item">
                          <NavLink to={ROUTE_PATHS.home}>Dashboard</NavLink>
                        </li>
                        <li className="nav-item">
                          <NavLink to={ROUTE_PATHS.papers}>Papers</NavLink>
                        </li>
                        <li className="nav-item">
                          <NavLink to={ROUTE_PATHS.solutions}>Solutions</NavLink>
                        </li>
                        <li className="nav-item">
                          <NavLink to={ROUTE_PATHS.courses}>Masterclasses</NavLink>
                        </li>
                        <li className="nav-item">
                          <NavLink to={ROUTE_PATHS.mocks}>Mock Exams</NavLink>
                        </li>
                        <li className="nav-item">
                          <NavLink to={ROUTE_PATHS.writingService}>Writing</NavLink>
                        </li>

                        {isBelowNavBreakpoint && (
                          <li className="nav-item">
                            <a
                              href="#"
                              onClick={profileBoxToggle}
                              ref={profileBoxBtnMobile}
                              className="nav-link profile-box-action ellipsis"
                            >
                              {userInfo && userInfo.first_name && userInfo.first_name}
                              <Icon icon={chevron} css={[spacer.mrL5]} />
                            </a>
                          </li>
                        )}
                      </ul>
                    </nav>
                  )}
                  <div className="secondary-nav">
                    <ul className="secondary-nav-inner">
                      {isBelowNavBreakpoint && (
                        <Fragment>
                          <li className="nav-item">
                            <StyledNavLink
                              href={site.pages.basket}
                              target="_blank"
                              rel="noopener noreferrer"
                              className={`nav-btn btn-cart ${cartCount === 0 ? 'disabled' : ''}`}
                            >
                              <span
                                className="btn-box"
                                css={cartCount && [bgColor.lightblue, txtColor.white]}
                              >
                                {cartCount}
                              </span>
                              <span className="btn-box d-none d-xl-table-cell">Checkout</span>
                              <span className="btn-box d-xl-none">
                                <Icon icon="cart-icon" className="cart-btn-icon" />
                              </span>
                            </StyledNavLink>
                          </li>

                          <li className="nav-item">
                            <StyledNavLink
                              href="#"
                              onClick={notificationToggle}
                              ref={notificationBtnMainNav}
                              className="nav-btn btn-notification"
                            >
                              <span className="btn-box">{unreadNotifications.length}</span>
                              <span className="btn-box">
                                <Icon icon="bell-icon" className="notif-btn-icon" />
                              </span>
                            </StyledNavLink>
                          </li>
                        </Fragment>
                      )}
                      {/* {!isBelowNavBreakpoint && (
                        <li className="nav-item">
                          <a
                            href="#a"
                            onClick={profileBoxToggle}
                            ref={profileBoxBtn}
                            className="nav-link profile-box-action ellipsis"
                          >
                            {userInfo && userInfo.first_name && userInfo.first_name}
                            <Icon icon={chevron} css={[spacer.mrL5]} />
                          </a>
                        </li>
                      )} */}
                      <li className="nav-item">
                        <div css={styles.hamburger} ref={hamburger}>
                          <div className="hamburger-inner">
                            <div className="bar" />
                            <div className="bar" />
                            <div className="bar" />
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {notificationState.notificationsVisible && (
          <NotificationBox
            toggleNotificationRead={toggleNotificationRead}
            notifications={unreadNotifications}
            toggleVisibility={notificationToggle}
            ref={notificationBoxEl}
            position={{
              top: notificationState.top,
              left: notificationState.left
            }}
            height={notificationState.height}
          />
        )}
        {profileBoxState.profileBoxVisible && (
          <ProfileBox
            ref={profileBoxEl}
            position={{
              top: profileBoxState.top,
              left: profileBoxState.left
            }}
          />
        )}
        {hamburgerActive && isBelowNavBreakpoint && <MobileNavOverlay ref={mobileNavLinkScreen} />}
      </header>
    </Fragment>
  );
};

Navigation.propTypes = {};

export default () => (
  <UserNotificationsContainer.Provider>
    <Navigation />
  </UserNotificationsContainer.Provider>
);
