import request from 'utils/request';
import { useMutation } from 'react-query';
import { api } from 'globals/constants';
import { UserInfoContainer } from 'api/auth';

const useWelcomeTourAPI = () => {
  const { setUserInfo, ID } = UserInfoContainer.useContainer();

  const [welcomeTourWatched, { data, error, status }] = useMutation(
    async () => {
      const { response } = await request({
        method: 'PUT',
        endpoint: api.endpoints.welcomeTour({ userId: ID }),
        payload: {
          watched_welcome_tour: 1
        }
      });
      return response;
    },
    {
      onMutate() {
        setUserInfo(currentInfo => ({ ...currentInfo, watched_welcome_tour: 1 }));
      },
      onSuccess(res) {
        if (!res.error) {
          setUserInfo(currentInfo => ({ ...currentInfo, watched_welcome_tour: 1 }));
        }
      },
      onError(err) {
        console.info(err, 'error');
      }
    }
  );
  return { welcomeTourWatched, data, error, status };
};

export default useWelcomeTourAPI;
