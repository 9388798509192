const getLessonBookmarks = (sections, videoId) => {
  const parsedVideoId = parseInt(videoId);

  const filteredSection = sections.filter(({ bookmarks }) => {
    return bookmarks.some(bookmark => bookmark.course_video_id === parsedVideoId);
  });

  const filteredBookmarks = filteredSection.length
    ? filteredSection[0].bookmarks.filter(bookmark => bookmark.course_video_id === parsedVideoId)
    : [];

  return filteredBookmarks;
};

export default getLessonBookmarks;
