/** @jsx jsx */
import Button from 'components/htmlElements/Button';
import { css, jsx } from '@emotion/core';
import { spacer } from '@styles/utilities';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { uniqBy } from 'lodash-es';
import { productCategories, recommendationsTabsOrder } from 'globals/constants';

export const useFiltersRow = ({ products }) => {
  const [activeFilter, setActiveFilter] = useState('');
  const handleClick = e => {
    const { filter } = e.target.dataset;
    setActiveFilter(filter);
  };

  const productsCategories = products
    .map(({ categories }) => categories)
    .flat(1)
    .filter(category =>
      [...productCategories.papers, productCategories.markSchemes].includes(category?.slug)
    );

  const uniqueCategories = uniqBy(productsCategories, 'slug').sort(
    (a, b) => recommendationsTabsOrder.indexOf(a.slug) - recommendationsTabsOrder.indexOf(b.slug)
  );

  return {
    filters: uniqueCategories,
    activeFilter,
    onFilterClick: handleClick
  };
};

const FiltersRow = ({ filters, onFilterClick, activeFilter }) => {
  return (
    <div
      css={css`
        ${spacer.mrB30};
        & > button {
          line-height: 1;
          ${spacer.mrR10};
          ${spacer.mrB10};
          :last-child {
            ${spacer.mrR0};
          }
        }
      `}
    >
      {/* if just 1 filter dont render */}
      {filters.length > 1 && (
        <Button
          className={!activeFilter ? 'active' : ''}
          data-filter=""
          tertiary
          onClick={onFilterClick}
        >
          All
        </Button>
      )}
      {filters.map(filter => (
        <Button
          key={filter.name}
          data-filter={filter.slug}
          id={filter.term_id}
          tertiary
          onClick={onFilterClick}
          className={activeFilter === filter.slug ? 'active' : ''}
        >
          {filter.name}
        </Button>
      ))}
    </div>
  );
};

FiltersRow.propTypes = {
  filters: PropTypes.array.isRequired,
  onFilterClick: PropTypes.func.isRequired,
  activeFilter: PropTypes.string.isRequired
};

export default FiltersRow;
