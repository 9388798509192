import { useQuery, useMutation, queryCache } from 'react-query';
import request from 'utils/request';
import { api } from 'globals/constants';
import { UserInfoContainer } from 'api/auth';

const useFindAllVideoBookmarks = ({ courseId, videoId }) => {
  const { ID: userId } = UserInfoContainer.useContainer();

  const { data, error, status, refetch } = useQuery(
    ['courseVideoBookmarks', { userId, courseId, videoId }],
    async () => {
      const { response } = await request({
        method: 'GET',
        endpoint: api.endpoints.userCourseVideoBookmarks.findAll({ courseId, userId, videoId })
      });
      return response;
    },
    {
      manual: true
    }
  );
  return { refetch, data, error, status };
};

const useFindVideoBookmark = ({ courseId, videoId, bookmarkId }) => {
  const { ID: userId } = UserInfoContainer.useContainer();

  const { data, error, status, refetch } = useQuery(
    ['courseVideoBookmark', { userId, courseId, videoId, bookmarkId }],
    async () => {
      const { response } = await request({
        method: 'GET',
        endpoint: api.endpoints.userCourseVideoBookmarks.find({
          courseId,
          userId,
          videoId,
          bookmarkId
        })
      });
      return response;
    },
    {
      manual: true
    }
  );
  return { refetch, data, error, status };
};

const useCreateVideoBookmark = () => {
  const { ID: userId } = UserInfoContainer.useContainer();

  const [createBookmark, { data, error, status }] = useMutation(
    async ({ courseId, videoId, bookmarkText, bookmarkTime }) => {
      const { response } = await request({
        method: 'POST',
        endpoint: api.endpoints.userCourseVideoBookmarks.create({ courseId, userId, videoId }),
        payload: {
          name: bookmarkText,
          bookmark_time: bookmarkTime
        }
      });
      return response;
    },
    {
      onSettled(newData, err, variables) {
        queryCache.refetchQueries('userCourseBookmarks', {
          courseId: variables.courseId,
          userId: variables.userId
        });
      }
    }
  );

  return { createBookmark, data, error, status };
};

const useUpdateVideoBookmark = () => {
  const { ID: userId } = UserInfoContainer.useContainer();

  const [updateBookmark, { data, error, status }] = useMutation(
    async ({ courseId, videoId, bookmarkId, bookmarkText, bookmarkTime }) => {
      const { response } = await request({
        method: 'PUT',
        endpoint: api.endpoints.userCourseVideoBookmarks.update({
          courseId,
          userId,
          bookmarkId,
          videoId
        }),
        payload: {
          name: bookmarkText,
          bookmark_time: bookmarkTime
        }
      });
      return response;
    },
    {
      onSettled(newData, err, variables) {
        queryCache.refetchQueries('userCourseBookmarks', {
          courseId: variables.courseId,
          userId: variables.userId
        });
      }
    }
  );

  return { updateBookmark, data, error, status };
};

const useDeleteVideoBookmark = () => {
  const { ID: userId } = UserInfoContainer.useContainer();

  const [deleteBookmark, { data, error, status }] = useMutation(
    async ({ courseId, videoId, bookmarkId }) => {
      const { response } = await request({
        method: 'DELETE',
        endpoint: api.endpoints.userCourseVideoBookmarks.delete({
          userId,
          courseId,
          videoId,
          bookmarkId
        })
      });
      return response;
    },
    {
      onSettled(newData, err, variables) {
        queryCache.refetchQueries('userCourseBookmarks', {
          courseId: variables.courseId,
          userId: variables.userId
        });
      }
    }
  );

  return { deleteBookmark, data, error, status };
};

export {
  useFindAllVideoBookmarks,
  useFindVideoBookmark,
  useCreateVideoBookmark,
  useUpdateVideoBookmark,
  useDeleteVideoBookmark
};
