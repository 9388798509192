import { useState, useEffect, useCallback } from 'react';
import * as queryString from 'querystringify';
import { useLocation, useNavigate } from 'components/Router';

const useParseQueryString = () => {
  const location = useLocation();
  const [queryStringData, updateQueryString] = useState(queryString.parse(location.search));

  // Get query string
  useEffect(() => {
    updateQueryString(queryString.parse(location.search));
  }, [location.search]);

  return queryStringData;
};

// Set query string
export const setQueryParams = queryParams => {
  const queryStringData = queryString.parse(window.location.search);
  if (Object.keys(queryParams).length) {
    Object.keys(queryParams).forEach(key => {
      queryStringData[key] = queryParams[key];
    });
  }
  return queryString.stringify(queryStringData);
};

export const useUpdateLocationQueryString = () => {
  const queryStringData = useParseQueryString();
  const navigate = useNavigate();

  const updateLocationString = useCallback(
    newQueryParams => {
      Object.keys(newQueryParams).forEach(key => {
        queryStringData[key] = newQueryParams[key];
      });

      navigate(`?${setQueryParams(queryStringData)}`);
    },
    [navigate, queryStringData]
  );

  return updateLocationString;
};

export default useParseQueryString;
