import { useInfiniteQuery } from 'react-query';
import request from 'utils/request';
import { api, ROUTE_PATHS } from 'globals/constants';
import { createContainer } from 'unstated-next';
import useDeepCompareEffect from 'use-deep-compare-effect';
import getProductFields from 'utils/getProductFields';
import { useState } from 'react';
import { UserInfoContainer } from './auth';

const useUserCourses = () => {
  const { ID } = UserInfoContainer.useContainer();
  const [courses, setCourses] = useState([]);
  const {
    status,
    data,
    isFetching,
    isFetchingMore,
    fetchMore,
    canFetchMore,
    error
  } = useInfiniteQuery(
    'userCourses',
    async (key, page = 0) => {
      const { response } = await request({
        method: 'GET',
        endpoint: `${api.endpoints.userCourses.findAll({ userId: ID })}?page=${page}`
      });
      return response;
    },
    {
      refetchOnWindowFocus: false,
      getFetchMore: lastGroup => {
        return lastGroup.next_page_url ? lastGroup.current_page + 1 : false;
      }
    }
  );

  useDeepCompareEffect(() => {
    if (data) {
      const newCourses = data
        .map(pageData => {
          return pageData.data.map(course => ({
            ...getProductFields(course.product),
            type: 'video',
            link: `${ROUTE_PATHS.courses}/${course.id}`
          }));
        })
        .flat(1);
      setCourses(newCourses);
    }
  }, [data]);

  return { courses, status, fetchMore, canFetchMore, isFetchingMore, isFetching, error };
};

const UserCoursesContainer = createContainer(useUserCourses);

export default UserCoursesContainer;
