import { useState, useEffect } from 'react';
import { debounce } from 'lodash-es';

const useBottomNavHeight = () => {
  const [bottomNavHeight, setBottomNavHeight] = useState(0);

  useEffect(() => {
    const bottomNav = document.querySelector('.nav-links-container');
    if (!bottomNav) return null;

    setBottomNavHeight(bottomNav.clientHeight);

    const handleResize = debounce(() => {
      setBottomNavHeight(bottomNav.clientHeight);
    }, 100);

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return { bottomNavHeight };
};

export default useBottomNavHeight;
