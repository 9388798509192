import styled from '@emotion/styled';
import styledMap from 'styled-map';
import facepaint from 'facepaint';
import isPropValid from '@emotion/is-prop-valid';
import { typography, colors, mixins, misc } from '@styles/utilities';

const breakPoints = facepaint([mixins.respondTo(misc.viewport['e-vp'])]);

const H4 = styled('h4', {
  shouldForwardProp: prop => isPropValid(prop)
})`
  font-family: ${typography.fonts.gothamRounded.fontFamily};
  font-weight: ${styledMap`
    book: ${typography.fonts.gothamRounded.fontWeight.book};
    default: ${typography.fonts.gothamRounded.fontWeight.medium};
  `};
  line-height: 140%;
  margin: ${misc.defaultTextMargin};
  color: ${props => (props.color ? colors[props.color] : colors.black)};
  ${breakPoints({
    fontSize: ['14px', typography.baseSizes.delta]
  })};
`;

export default H4;
