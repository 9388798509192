/** @jsx jsx */
import { jsx } from '@emotion/core';
import H1 from 'components/htmlElements/H1';
import H4 from 'components/htmlElements/H4';
import H3 from 'components/htmlElements/H3';
// import P from 'components/htmlElements/P';
import Button from 'components/htmlElements/Button';
import { ROUTE_PATHS } from 'globals/constants';
import { spacer } from '@styles/utilities';
import { Link } from 'components/Router/Router';
import useTermsAndConditions from 'api/termsAndConditions';
import { useLogout, UserInfoContainer } from 'api/auth';
// import PropTypes from 'prop-types';

const TermsAndCondition = () => {
  const userInfo = UserInfoContainer.useContainer();
  const userId = userInfo.ID;
  const { acceptTermsAndConditions, status } = useTermsAndConditions();
  const logout = useLogout();

  return (
    <section className="h-100" css={spacer.padBT40}>
      <div className="container">
        <div className="row">
          <div className="col-12 txt-center mr-t-20 mr-b-40">
            <div className="login-logo">
              <img src={require('images/logo-horizontal.svg').default} alt="logo.svg" />
            </div>
          </div>
        </div>

        <div className="row justify-content-center">
          <div className="col-md-12 col-md-10 col-xl-9">
            <H1 css={spacer.mrB20}>Terms</H1>
            <H4 as="p" book>
              Please read the following terms and conditions for usage of our site very carefully.
              Your use of service is subject to your acceptance of and compliance with the following
              terms and conditions (&quot;Terms&quot;). By subscribing to or using any of our
              services you agree that you have read, understood and are bound by the Terms,
              regardless of how you subscribe to or use the services. If you do not want to be bound
              by the Terms, you must not subscribe to or use our services. In these Terms,
              references to &quot;you&quot;, &quot;User&quot; shall mean the end user accessing the
              Website, its contents and using the Services offered through the Website.
              &quot;Service Providers&quot; mean independent third party service providers, and
              &quot;we&quot;, &quot;us&quot; and &quot;our&quot; shall mean ExamPapersPlus, its
              franchisor, affiliates and partners.
            </H4>
            <H3 css={spacer.mrT30}>Introduction:</H3>
            <H4 as="ol" className="styled-list" book>
              <li>
                The www.exampapersplus.co.uk website (&quot;Website&quot;) is an Internet based
                content and e-commerce portal operated by Exam Papers Plus Limited, a company
                incorporated under the laws of the UK.
              </li>
              <li>
                Use of the Website is offered to you conditioned on acceptance without modification
                of all the terms, conditions and notices contained in these Terms, as may be posted
                on the Website from time to time. exampapersplus.co.uk at its sole discretion
                reserves the right not to accept a User from registering on the Website without
                assigning any reason thereof.
              </li>
            </H4>
            <H3 css={spacer.mrT30}>User Account, Password, and Security:</H3>
            <H4 as="p" book>
              You are responsible for maintaining the confidentiality of the password and account,
              and are fully responsible for all activities that occur under your password or
              account. You agree to (a) immediately notify exampapersplus.co.uk of any unauthorised
              use of your password or account or any other breach of security, and (b) ensure that
              you exit from your account at the end of each session. exampapersplus.co.uk cannot and
              will not be liable for any loss or damage arising from your failure to comply with
              this.
            </H4>
            <H3 css={spacer.mrT30}>Services Offered:</H3>
            <H4 as="p" book>
              exampapersplus.co.uk provides a number of Internet-based services through the Web Site
              (all such services, collectively, the &quot;Service&quot;). One such service enables
              users to purchase original merchandise such as home furniture (collectively,
              &quot;Products&quot;). Upon placing an order, exampapersplus.co.uk shall ship the
              product to you and be entitled to its payment for the Services.
            </H4>
            <H3 css={spacer.mrT30}>Privacy Policy:</H3>
            <H4 as="p" book>
              The User hereby consents, expresses and agrees that he has read and fully understands
              the Privacy Policy of exampapersplus.co.uk the Website. The user further consents that
              the terms and contents of such Privacy Policy are acceptable to him.
            </H4>
            <H3 css={spacer.mrT30}>Limited User:</H3>
            <H4 as="p" book>
              The User agrees and undertakes not to reverse engineer, modify, copy, distribute,
              transmit, display, perform, reproduce, publish, license, create derivative works from,
              transfer, or sell any information, products or software obtained from the Website.
              Limited reproduction and copying of the content of the Website is permitted provided
              that exampapersplus.co.uk’s name is stated as the source and prior written permission
              of exampapersplus.co.uk is sought. For the removal of doubt, it is clarified that
              unlimited or wholesale reproduction, copying of the content for commercial or
              non-commercial purposes and unwarranted modification of data and information within
              the content of the Website is not permitted. Products are licensed for personal use
              for one individual with their own children only. They are not licensed for tutoring
              with more than one student. Forwarding and distributing to others to use is against
              the terms of service and will result in the order becoming invalid. Such distribution
              breaches our copyright and intellectual property.
            </H4>
            <H3 css={spacer.mrT30}>User Conduct and Rules:</H3>
            <H4 as="p" book>
              You agree and undertake to use the Website and the Service only to post and upload
              messages and material that are proper. By way of example, and not as a limitation, you
              agree and undertake that when using a Service, you will not:
            </H4>
            <H4 as="ol" className="styled-list" book>
              <li>
                Defame, abuse, harass, stalk, threaten or otherwise violate the legal rights of
                others;
              </li>
              <li>
                Publish, post, upload, distribute or disseminate any inappropriate, profane,
                defamatory, infringing, obscene, indecent or unlawful topic, name, material or
                information;
              </li>
              <li>
                Upload files that contain software or other material protected by intellectual
                property laws unless you own or control the rights thereto or have received all
                necessary consents;
              </li>
              <li>
                Upload or distribute files that contain viruses, corrupted files, or any other
                similar software or programs that may damage the operation of the Website or
                another’s computer;
              </li>
              <li>Conduct or forward surveys, contests, pyramid schemes or chain letters;</li>
              <li>
                Download any file posted by another user of a Service that you know, or reasonably
                should know, cannot be legally distributed in such manner;
              </li>
              <li>
                Falsify or delete any author attributions, legal or other proper notices or
                proprietary designations or labels of the origin or source of software or other
                material contained in a file that is uploaded;
              </li>
              <li>
                Violate any code of conduct or other guidelines, which may be applicable for or to
                any particular Service;
              </li>
              <li>
                Violate any applicable laws or regulations for the time being in force in or outside
                India; and
              </li>
              <li>
                Violate any of the terms and conditions of this Agreement or any other terms and
                conditions for the use of the Website contained elsewhere herein.
              </li>
            </H4>
            <H3 css={spacer.mrT30}>User Warranty and Representation:</H3>
            <H4 as="p" book>
              The user guarantees, warrants, and certifies that you are the owner of the content
              which you submit or otherwise authorized to use the content and that the content does
              not infringe upon the property rights, intellectual property rights or other rights of
              others. You further warrant that to your knowledge, no action, suit, proceeding, or
              investigation has been instituted or threatened relating to any content, including
              trademark, trade name service mark, and copyright formerly or currently used by you in
              connection with the Services rendered by exampapersplus.co.uk.
            </H4>

            <H3 css={spacer.mrT30}>Exactness Not Guaranteed:</H3>
            <H4 as="p" book>
              exampapersplus.co.uk hereby disclaims any guarantees of exactness as to the finish and
              appearance of the final Product as ordered by the user. The quality of any products,
              Services, information, or other material purchased or obtained by you through the
              Website may not meet your expectations. In the case of any significant material damage
              to the Product, the customer must send photographic evidence of the damaged product
              via email to exampapersplus.co.uk within 7 days of receiving the Product. A
              representative from exampapersplus.co.uk will review the evidence, and if it is found
              valid, exampapersplus.co.uk will send an email communication to you requesting for a
              replacement. You agree in this instance, that exampapersplus.co.uk will make the final
              judgment on whether a Product has been significantly damaged.
            </H4>
            <H3 css={spacer.mrT30}>Intellectual Property Rights:</H3>
            <H4 as="ol" className="styled-list" book>
              <li>
                Unless otherwise indicated or anything contained to the contrary or any proprietary
                material owned by a third party and so expressly mentioned, exampapersplus.co.uk
                owns all Intellectual Property Rights to and into the Website, including, without
                limitation, any and all rights, title and interest in and to copyright, related
                rights, patents, utility models, trademarks, trade names, service marks, designs,
                know-how, trade secrets and inventions (whether patentable or not), goodwill, source
                code, meta tags, databases, text, content, graphics, icons, and hyperlinks. You
                acknowledge and agree that you shall not use, reproduce or distribute any content
                from the Website belonging to exampapersplus.co.uk without obtaining authorization
                from exampapersplus.co.uk.
              </li>
              <li>
                Notwithstanding the foregoing, it is expressly clarified that you will retain
                ownership and shall solely be responsible for any content that you provide or upload
                when using any Service, including any text, data, information, images, photographs,
                music, sound, video or any other material which you may upload, transmit or store
                when making use of our various Service. However, you expressly agree that by
                uploading and posting content on to the Website for public viewing and
                reproduction/use of your content by third party users, you accept the User whereby
                you grant a non-exclusive license for the use of the same.
              </li>
            </H4>
            <H3 css={spacer.mrT30}>Links To Third Party Sites:</H3>
            <H4 as="p" book>
              The Website may contain links to other websites (&quot;Linked Sites&quot;).The Linked
              Sites are not under the control of exampapersplus.co.uk or the Website and
              exampapersplus.co.uk is not responsible for the contents of any Linked Site, including
              without limitation any link contained in a Linked Site, or any changes or updates to a
              Linked Site. exampapersplus.co.uk is not responsible for any form of transmission,
              whatsoever, received by you from any Linked Site. exampapersplus.co.uk is providing
              these links to you only as a convenience, and the inclusion of any link does not imply
              endorsement by exampapersplus.co.uk or the Website of the Linked Sites or any
              association with its operators or owners including the legal heirs or assigns thereof.
              The users are requested to verify the accuracy of all information on their own before
              undertaking any reliance on such information.
            </H4>
            <H3 css={spacer.mrT30}>Disclaimer Of Warranties/Limitation Of Liability:</H3>
            <H4 as="p" book>
              exampapersplus.co.uk has endeavoured to ensure that all the information on the Website
              is correct, but exampapersplus.co.uk neither warrants nor makes any representations
              regarding the quality, accuracy or completeness of any data, information, product or
              Service. In no event shall exampapersplus.co.uk be liable for any direct, indirect,
              punitive, incidental, special, consequential damages or any other damages resulting
              from: (a) the use or the inability to use the Services; (b) unauthorized access to or
              alteration of the user’s transmissions or data; (c) any other matter relating to the
              services; including, without limitation, damages for loss of use, data or profits,
              arising out of or in any way connected with the use or performance of the Website or
              Service. Neither shall exampapersplus.co.uk be responsible for the delay or inability
              to use the Website or related services, the provision of or failure to provide
              Services, or for any information, software, products, services and related graphics
              obtained through the Website, or otherwise arising out of the use of the website,
              whether based on contract, tort, negligence, strict liability or otherwise. Further,
              exampapersplus.co.uk shall not be held responsible for non-availability of the Website
              during periodic maintenance operations or any unplanned suspension of access to the
              website that may occur due to technical reasons or for any reason beyond
              exampapersplus.co.uk’s control. The user understands and agrees that any material
              and/or data downloaded or otherwise obtained through the Website is done entirely at
              their own discretion and risk and they will be solely responsible for any damage to
              their computer systems or loss of data that results from the download of such material
              and/or data.We as a merchant shall be under no liability whatsoever in respect of any
              loss or damage arising directly or indirectly out of the decline of authorization for
              any Transaction, on Account of the Cardholder having exceeded the preset limit
              mutually agreed by us with our acquiring bank from time to time
            </H4>
            <H3 css={spacer.mrT30}>Indemnification:</H3>
            <H4 as="p" book>
              You agree to indemnify, defend and hold harmless exampapersplus.co.uk from and against
              any and all losses, liabilities, claims, damages, costs and expenses (including legal
              fees and disbursements in connection therewith and interest chargeable thereon)
              asserted against or incurred by exampapersplus.co.uk that arise out of, result from,
              or may be payable by virtue of, any breach or non-performance of any representation,
              warranty, covenant or agreement made or obligation to be performed by you pursuant to
              these Terms.
            </H4>
            <H3 css={spacer.mrT30}>Pricing:</H3>
            <H4 as="p" book>
              Prices for products are described on our Website and are incorporated into these Terms
              by reference. All prices are in British pounds. Prices, Products and Services may
              change at exampapersplus.co.uk’s discretion.Any discount code / cashback that is
              applied on a specific order has a maximum limit of 1 code / coupon / cashback per
              order.
            </H4>
            <H3 css={spacer.mrT30}>Refunds:</H3>
            <H4 as="p" book>
              Due to the nature of digital products, we can unfortunately not offer refunds or
              returns on any products once they have been purchased. If you are unsatisfied with the
              product or are facing technical issues or have any other concerns, please contact us
              at info@exampapersplus.co.uk and we will be delighted to help resolve the problem.
            </H4>
            <H3 css={spacer.mrT30}>Termination:</H3>
            <H4 as="ol" className="styled-list" book>
              <li>
                exampapersplus.co.uk may suspend or terminate your use of the Website or any Service
                if it believes, in its sole and absolute discretion that you have breached any of
                the Terms.
              </li>
              <li>
                If you or exampapersplus.co.uk terminates your use of the Website or any Service,
                exampapersplus.co.uk may delete any content or other materials relating to your use
                of the Service and exampapersplus.co.uk will have no liability to you or any third
                party for doing so.
              </li>
              <li>
                You shall be liable to pay for any Service or product that you have already ordered
                till the time of Termination by either party whatsoever. Further, you shall be
                entitled to your royalty payments as per the User License Agreement that has or is
                legally deemed accrued to you.
              </li>
            </H4>
            <H3 css={spacer.mrT30}>Governing Law:</H3>
            <H4 as="p" book>
              These terms shall be governed by and constructed in accordance with the laws of the UK
              without reference to conflict of laws principles and disputes arising in relation
              hereto shall be subject to the exclusive jurisdiction of the courts in the UK.
            </H4>
            <H3 css={spacer.mrT30}>Headings:</H3>
            <H4 as="p" book>
              The headings and subheadings herein are included for convenience and identification
              only and are not intended to describe, interpret, define or limit the scope, extent or
              intent of the Terms or the right to use the Website by you contained herein or any
              other section or pages of the Website or any Linked Sites in any manner whatsoever.
            </H4>
            <H3 css={spacer.mrT30}>Interpretation Of Number And Genders:</H3>
            <H4 as="p" book>
              The Terms herein shall apply equally to both the singular and plural form of the terms
              defined. Whenever the context may require, any pronoun shall include the corresponding
              masculine and feminine. The words &quot;include&quot;, &quot;includes&quot; and
              &quot;including&quot; shall be deemed to be followed by the phrase &quot;without
              limitation&quot;. Unless the context otherwise requires, the terms &quot;herein&quot;,
              &quot;hereof&quot;, &quot;hereto&quot;, &quot;hereunder&quot; and words of similar
              import refer to the Terms as a whole.
            </H4>
            <H3 css={spacer.mrT30}>Severability:</H3>
            <H4 as="p" book>
              If any provision of the Terms is determined to be invalid or unenforceable in whole or
              in part, such invalidity or unenforceability shall attach only to such provision or
              part of such provision and the remaining part of such provision and all other
              provisions of these Terms shall continue to be in full force and effect.
            </H4>
            <H3 css={spacer.mrT30}>Report Abuse:</H3>
            <H4 as="p" book>
              As per these Terms, users are solely responsible for every material or content
              uploaded on to the Website. exampapersplus.co.uk does not review the contents in any
              way before they appear on the Website. exampapersplus.co.uk does not verify, endorse
              or otherwise vouch for the contents of any user or any content generally posted or
              uploaded on to the Website. Users can be held legally liable for their contents and
              may be held legally accountable if their contents or material include, for example,
              defamatory comments or material protected by copyright, trademark, etc. If you come
              across any abuse or violation of these Terms, please report to
              info@exampapersplus.co.uk.
            </H4>
            <div className="d-flex align-items-center justify-content-end" css={spacer.mrBT80}>
              <Link to={ROUTE_PATHS.home} css={spacer.mrR20} onClick={logout}>
                Decline and Exit
              </Link>
              <Button
                onClick={() => acceptTermsAndConditions({ userId })}
                loading={status === 'loading'}
              >
                Accept Terms
              </Button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

TermsAndCondition.propTypes = {};

export default TermsAndCondition;
