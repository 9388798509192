/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'components/Icon';
import Controls from 'components/Carousel/CarouselControls';
import { UnstyledLink } from 'components/Router/Router';

const SectionTitle = ({ title, arrow, seeAll, onNext, onPrev, carouselInstance, noBorder }) => {
  return (
    <div
      className={`section-title-wrapper is-relative mr-b-20 section-primary-title ${
        !noBorder ? 'br-b' : ''
      }`}
    >
      <div className="section-title-txt-wrapper">
        <h1 className="section-title-txt">{title}</h1>
      </div>

      <div className="section-action-btn-wrapper">
        {arrow && (
          <React.Fragment>
            <Controls.PrevArrow
              className="section-action-arrow section-action-prev"
              onClick={() => carouselInstance.current && carouselInstance.current.slickPrev()}
            />
            <Controls.NextArrow
              className="section-action-arrow section-action-next txt-right"
              onClick={() => carouselInstance.current && carouselInstance.current.slickNext()}
            />
          </React.Fragment>
        )}
        {seeAll && (
          <h3 className="h3-v2 section-see-all-link">
            <UnstyledLink to={seeAll}>View all</UnstyledLink>
          </h3>
        )}
      </div>
    </div>
  );
};

SectionTitle.propTypes = {
  title: PropTypes.string.isRequired,
  arrow: PropTypes.bool,
  noBorder: PropTypes.bool,
  seeAll: PropTypes.string,
  onNext: PropTypes.func,
  onPrev: PropTypes.func
};

SectionTitle.defaultProps = {
  arrow: false,
  noBorder: false,
  seeAll: '',
  onNext: () => {},
  onPrev: () => {}
};

export default SectionTitle;
