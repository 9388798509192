/* eslint-disable no-unused-vars */
/** @jsx jsx */
import FlipMove from 'react-flip-move';
import { jsx } from '@emotion/core';
import { spacer } from '@styles/utilities';
import ProductCard from 'components/ProductCard/ProductCard';
import SectionTitle from 'components/SectionTitle/SectionTitle';
import PropTypes from 'prop-types';

import { Waypoint } from 'react-waypoint';
import NoProducts from 'components/NoProducts/NoProducts';
import { eCommerceSiteLinks } from 'globals/constants';
import { Fragment, useMemo, useState } from 'react';
import Modal, { useModal } from 'components/Modal/Modal';
import PageLoader from 'components/PageLoader/PageLoader';

import MockModal from 'components/MockModal/MockModal';

const MocksPage = ({ mocks, canFetchMore, fetchMore, isFetchingMore }) => {
  const { isOpen, toggle } = useModal(false);
  const [clickedItem, setClickedItem] = useState({});

  const filteredMocks = useMemo(
    () =>
      mocks.filter(exam => {
        if (!exam?.cutOffDate) {
          return true;
        }
        return new Date(exam?.orderDate).getTime() > new Date(exam.cutOffDate).getTime();
      }),
    [mocks]
  );

  return (
    <Fragment>
      <div className="container no-push-container" css={spacer.mrBT40}>
        <div className="row">
          <div className="col-12">
            <div css={spacer.padLR30}>
              {!filteredMocks.length ? (
                <NoProducts productName="Mock Exams" linkToBuy={eCommerceSiteLinks.mocks} />
              ) : (
                <Fragment>
                  <SectionTitle title="Mock Exams" />
                </Fragment>
              )}
            </div>
          </div>
        </div>
      </div>
      {filteredMocks.length > 0 && (
        <div className="container no-push-container" css={spacer.mrBT40}>
          <FlipMove className="row">
            {filteredMocks.map(mock => (
              <div className="col-6 col-md-4 col-xl-3" key={mock.id} css={[spacer.padB80]}>
                <ProductCard
                  {...mock}
                  onClick={() => {
                    setClickedItem(mock);
                    toggle();
                  }}
                />
              </div>
            ))}
          </FlipMove>
          <Waypoint
            bottomOffset="-40%"
            onEnter={() => {
              console.info('Loading more Mocks');
              if (canFetchMore) {
                fetchMore();
              }
            }}
          />
          {isFetchingMore ? <PageLoader height="100px" isRelative /> : ''}
        </div>
      )}
      <Modal isOpen={isOpen} toggle={toggle}>
        <MockModal mockData={clickedItem} />
      </Modal>
    </Fragment>
  );
};
MocksPage.propTypes = {
  mocks: PropTypes.array.isRequired,
  fetchMore: PropTypes.func,
  // canFetchMore can be number or boolean, number if more pages are available, boolean `false` if no next page available
  canFetchMore: PropTypes.any,
  isFetchingMore: PropTypes.bool
};
MocksPage.defaultProps = {
  fetchMore: () => {},
  canFetchMore: false,
  isFetchingMore: false
};
export default MocksPage;
