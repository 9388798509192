import { keyframes, css } from '@emotion/core';
import styled from '@emotion/styled';
import isPropValid from '@emotion/is-prop-valid';
import styledMap from 'styled-map';
import facepaint from 'facepaint';
import { typography, misc, mixins, colors } from '@styles/utilities';
import { Link } from 'components/Router/Router';

const breakPoints = facepaint([mixins.respondTo(misc.viewport['d-vp'])]);

const rotate = keyframes`
  0% {
    transform: rotate(0deg) translate(-50%,-50%);
  }
  100% {
    transform: rotate(360deg) translate(-50%,-50%);
  }
`;

const baseButton = styled('button', {
  shouldForwardProp: prop => {
    return prop !== 'loading' && isPropValid(prop);
  }
})`
  display: inline-block;
  vertical-align: middle;
  padding: ${props => {
    if (props.large) {
      return '14px 24px';
    }
    if (props.icon || props.tertiary) {
      return '10px';
    }
    return `${misc.input.paddingVertical} ${misc.input.paddingHorizontal}`;
  }};
  line-height: ${props => {
    if (props.icon) {
      return '1';
    }
    return 'unset';
  }};
  text-align: center;
  opacity: 1;
  margin: 0;
  border-radius: ${misc.mediumBorderRadius};
  outline: none;
  cursor: pointer;
  white-space: nowrap;
  text-decoration: none;
  ${mixins.fontHacks()}
  overflow: hidden;
  -webkit-appearance: none;
  transform: translateZ(0);
  ${mixins.transition()}
  width: ${styledMap`
  wide: 100%;
  default: auto;
  `};
  border: 2px solid ${colors.darkblue};
  font-family: ${typography.fonts.gothamRounded.fontFamily};
  font-weight: ${typography.fonts.gothamRounded.fontWeight.book};

  border-width: ${styledMap`
    tertiary: 1px;
    default: 2px;
  `};
  background: ${styledMap`
    tertiary: transparent;
    secondary: transparent;
    default: linear-gradient(to bottom, #4ba3da 0%, #3377b9 100%);
  `};
  color: ${styledMap`
    tertiary: ${colors.black};
    secondary: ${colors.darkblue};
    default: ${colors.white};
  `};
  border-color: ${styledMap`
    tertiary: ${colors.black};
    secondary: ${colors.darkblue};
    default: ${colors.darkblue};
  `};
  fill: ${styledMap`
    tertiary: ${colors.black};
    secondary: ${colors.darkblue};
    default: ${colors.white};
  `};
  ${mixins.linkActiveStyles()} {
    outline: none;
    text-decoration: none;
  }
  &.active,
  &:hover {
    background: ${styledMap`
      secondary: ${colors.darkblue};
      default: linear-gradient(to bottom, #439fff 0%, #4097f3 100%);
    `};
    color: ${styledMap`
      tertiary: ${colors.primaryLinkHover};
      secondary: ${colors.white};
      default: ${colors.white};
    `};
    border-color: ${styledMap`
      tertiary: ${colors.primaryLinkHover};
      secondary: ${colors.darkblue};
      default: ${colors.darkblue};
    `};
    fill: ${styledMap`
      tertiary: ${colors.primaryLinkHover};
      secondary: ${colors.white};
      default: ${colors.darkblue};
    `};
  };
  &:active {
    background-color: ${styledMap`
      tertiary: transparent;
      secondary: transparent;
      default: ${colors.darkblue};
    `};
    color: ${styledMap`
      tertiary: ${colors.black};
      secondary: ${colors.darkblue};
      default: ${colors.white};
    `};
    border-color: ${styledMap`
      tertiary: ${colors.black};
      secondary: ${colors.darkblue};
      default: ${colors.darkblue};
    `};
    fill: ${styledMap`
      tertiary: ${colors.black};
      secondary: ${colors.darkblue};
      default: ${colors.white};
    `};
  };
  &[disabled] {
    pointer-events: none;
    background: rgba(0, 0, 0, 0.4);
    color: ${colors.white};
    fill: ${colors.white};
    border-color: rgba(0, 0, 0, 0.3);
  }

  ${props => {
    // Loader
    if (props.loading) {
      return `
        border-color: ${colors.darkblue};
        pointer-events: none;
        opacity: 0.8;
        &:before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: ${colors.darkblue};
          display: block;
        }
        &:after {
          content: '';
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          transform-origin: 0% 0%;
          width: 1.5em;
          height: 1.5em;
          display: block;
          border: 2px solid white;
          border-left-color: transparent;
          border-radius: 50%;
        }
      `;
    }
    return `

    `;
  }}
  &:after {
    ${() => css`
      animation: ${rotate} 1s linear infinite;
    `}
  }

`;

const Button = styled(baseButton)(props =>
  breakPoints({
    fontSize: props.large
      ? ['16px', typography.baseSizes.gamma]
      : ['14px', typography.baseSizes.delta]
  })
);

export const LinkButton = Button.withComponent(Link);
export default Button;
