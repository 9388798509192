/** @jsx jsx */
import { useInfiniteQuery } from 'react-query';
import request from 'utils/request';
import { api } from 'globals/constants';
import { createContainer } from 'unstated-next';
import getProductFields from 'utils/getProductFields';
import { useState } from 'react';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { UserInfoContainer } from './auth';

const useUserMocks = () => {
  const { ID } = UserInfoContainer.useContainer();
  const [mocks, setMocks] = useState([]);
  const {
    status,
    data,
    isFetching,
    isFetchingMore,
    fetchMore,
    canFetchMore,
    error
  } = useInfiniteQuery(
    'userMocks',
    async (key, page = 0) => {
      const { response } = await request({
        method: 'GET',
        endpoint: `${api.endpoints.userMocks.findAll({ userId: ID })}?page=${page}`
      });
      return response;
    },
    {
      refetchOnWindowFocus: false,
      getFetchMore: lastGroup => {
        return lastGroup.next_page_url ? lastGroup.current_page + 1 : false;
      }
    }
  );
  useDeepCompareEffect(() => {
    if (data) {
      const newMocks = data
        .map(pageData => {
          return pageData.data.map(getProductFields);
        })
        .flat(1);
      setMocks(newMocks);
    }
  }, [data]);

  return { mocks, status, fetchMore, canFetchMore, isFetchingMore, isFetching, error };
};

const UserMocksContainer = createContainer(useUserMocks);

export default UserMocksContainer;
