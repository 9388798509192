/** @jsx jsx */
import { jsx } from '@emotion/core';
import H1 from 'components/htmlElements/H1';
import Button from 'components/htmlElements/Button';
import PropTypes from 'prop-types';

import { spacer } from '@styles/utilities';

const NoProducts = ({ productName, linkToBuy }) => (
  <div className="text-center" css={spacer.mrBT40}>
    <H1 css={spacer.mrB20}>No {productName} found</H1>
    <Button as="a" target="_blank" href={linkToBuy}>
      Purchase {productName}
    </Button>
  </div>
);

NoProducts.propTypes = {
  productName: PropTypes.string.isRequired,
  linkToBuy: PropTypes.string.isRequired
};

export default NoProducts;
