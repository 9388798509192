/* eslint-disable import/prefer-default-export */

export const ROUTE_PATHS = {
  login: '/login',
  home: '/',
  profile: '/profile',
  notifications: '/notifications',
  notFound: '/404',
  papers: '/papers',
  solutions: '/solutions',
  solutionSingle: '/solutions/:solutionId',
  courses: '/masterclasses',
  mocks: '/mock-exams',
  markSchemes: '/mark-schemes',
  termsAndConditions: '/terms-and-conditions',
  courseSingle: 'masterclasses/:courseId',
  writingService: '/writing-service'
};

export const eCommerceSiteLinks = {
  papers: `${process.env.SITE_URL}/papers/`,
  markSchemes: `${process.env.SITE_URL}/mark-schemes/`,
  mocks: `${process.env.SITE_URL}/mock-exams/`,
  courses: `${process.env.SITE_URL}/masterclasses/`,
  writingService: `${process.env.SITE_URL}/writing-marking-service/`
};

export const site = {
  name: 'Exam Papers Plus Dashboard',
  url: process.env.SITE_URL,
  themeColor: '#428bca',
  socialMedia: {
    facebook: '#',
    twitter: '#'
  },
  telephone: '+44 845 591 1211',
  pages: {
    basket: `${process.env.SITE_URL}/basket/`
  }
};

const apiHost = `${process.env.SITE_URL}/account/api`;
export const api = {
  endpoints: {
    authenticateWithUserCredentials: `${apiHost}/authenticate`,
    authenticateToken: `${apiHost}/authenticate-token`,
    loginWP: `${site.url}/wp-json/epp/login`,
    // logout: `${apiHost}/logout`,
    requestResetPasswordEmail: `${site.url}/wp-json/epp/forgot-password`,
    resetPasswordEmail: `${site.url}/wp-json/epp/reset-password`,
    coCart: `${site.url}/wp-json/cocart/v1`,
    promotions: `${apiHost}/promotions`,
    paperPdfDownload: ({ userId, paperId }) =>
      `${apiHost}/users/${userId}/papers/${paperId}/downloads`,
    markSchemePdfDownload: ({ userId, markSchemeId }) =>
      `${apiHost}/users/${userId}/mark-schemes/${markSchemeId}/downloads`,
    writingServicePdfDownload: ({ userId, writingServiceId }) =>
      `${apiHost}/users/${userId}/writing-service/${writingServiceId}/downloads`,
    updateUserDetails: ({ userId }) => `${apiHost}/users/${userId}`,
    updatePassword: ({ userId }) => `${apiHost}/users/${userId}/password`,
    userNotifications: {
      findAll: ({ userId }) => {
        return `${apiHost}/users/${userId}/notifications`;
      },
      update: ({ userId, notificationId }) => {
        return `${apiHost}/users/${userId}/notifications/${notificationId}`;
      }
    },
    acceptTermsAndConditions: ({ userId }) => `${apiHost}/users/${userId}/terms-and-conditions`,
    userOrders: {
      find: ({ userId, orderId }) => {
        return `${apiHost}/users/${userId}/orders/${orderId}`;
      },
      findAll: ({ userId }) => {
        return `${apiHost}/users/${userId}/orders`;
      }
    },
    userOrderProducts: {
      find: ({ userId, orderId, productId }) => {
        return `${apiHost}/users/${userId}/orders/${orderId}/products/${productId}`;
      },
      findAll: ({ userId, orderId }) => {
        return `${apiHost}/users/${userId}/orders/${orderId}/products`;
      }
    },
    userPapers: {
      findAll: ({ userId }) => {
        return `${apiHost}/users/${userId}/papers`;
      }
    },
    userSolutions: {
      findAll: ({ userId }) => {
        return `${apiHost}/users/${userId}/solutions`;
      }
    },
    userCourses: {
      find: ({ userId, courseId }) => {
        return `${apiHost}/users/${userId}/courses/${courseId}`;
      },
      findAll: ({ userId }) => {
        return `${apiHost}/users/${userId}/courses`;
      },
      restart: ({ userId, courseId }) => {
        return `${apiHost}/users/${userId}/courses/${courseId}`;
      }
    },
    userMocks: {
      findAll: ({ userId }) => {
        return `${apiHost}/users/${userId}/mocks`;
      }
    },
    userMarkSchemes: {
      findAll: ({ userId }) => {
        return `${apiHost}/users/${userId}/mark-schemes`;
      }
    },
    userWritingService: {
      findAll: ({ userId }) => {
        return `${apiHost}/users/${userId}/writing-service`;
      }
    },
    userRecommendedProducts: {
      findAll: ({ userId }) => {
        return `${apiHost}/users/${userId}/recommended-products`;
      }
    },
    userCourseVideo: {
      update: ({ courseId, userId, videoId }) => {
        return `${apiHost}/users/${userId}/courses/${courseId}/videos/${videoId}`;
      }
    },
    userCoursesBookmarks: {
      findAll: ({ courseId, userId }) => {
        return `${apiHost}/users/${userId}/courses/${courseId}/bookmarks`;
      }
    },
    userCourseVideoBookmarks: {
      findAll: ({ courseId, userId, videoId }) => {
        return `${apiHost}/users/${userId}/courses/${courseId}/videos/${videoId}/bookmarks`;
      },
      find: ({ courseId, userId, videoId, bookmarkId }) => {
        return `${apiHost}/users/${userId}/courses/${courseId}/videos/${videoId}/bookmarks/${bookmarkId}`;
      },
      create: ({ courseId, userId, videoId }) => {
        return `${apiHost}/users/${userId}/courses/${courseId}/videos/${videoId}/bookmarks`;
      },
      update: ({ courseId, userId, videoId, bookmarkId }) => {
        return `${apiHost}/users/${userId}/courses/${courseId}/videos/${videoId}/bookmarks/${bookmarkId}`;
      },
      delete: ({ courseId, userId, videoId, bookmarkId }) => {
        return `${apiHost}/users/${userId}/courses/${courseId}/videos/${videoId}/bookmarks/${bookmarkId}`;
      }
    },

    userSolution: {
      find: ({ userId, solutionId }) => {
        return `${apiHost}/users/${userId}/solutions/${solutionId}`;
      },
      findAll: ({ userId }) => {
        return `${apiHost}/users/${userId}/solutions`;
      },
      restart: ({ userId, solutionId }) => {
        return `${apiHost}/users/${userId}/solutions/${solutionId}`;
      }
    },
    userSolutionVideo: {
      update: ({ solutionId, userId, videoId }) => {
        return `${apiHost}/users/${userId}/solutions/${solutionId}/videos/${videoId}`;
      }
    },
    welcomeTour: ({ userId }) => `${apiHost}/users/${userId}/welcome-tour`
  }
};

export const sentryDSN =
  'https://794641580e4641abb6f8fed28451762c@o355942.ingest.sentry.io/2754842';

export const productCategories = {
  papers: [
    'papers',
    'five-plus',
    'six-plus',
    'seven-plus',
    'eight-plus',
    'nine-plus',
    'ten-plus',
    'eleven-plus',
    'twelve-plus',
    'thirteen-plus',
    'sixteen-plus',
    'gcse',
    'sats'
  ],
  courses: 'courses',
  offlineCourses: 'offline-courses',
  mock: 'mock',
  markSchemes: 'mark-schemes',
  writingService: 'writing'
};

// const otherCategories = [
//   'english',
//   'biology',
//   'physics',
//   'chemistry',
//   'reasoning',
//   'verbal-reasoning',
//   'non-verbal-reasoning',
//   'independant-schools',
//   'maths',
//   'grammar-schools',
//   'psle'
// ];

// this needs to include all possible values with required order
export const recommendationsTabsOrder = [
  ...productCategories.papers,
  productCategories.courses,
  productCategories.mock,
  productCategories.markSchemes
];

// Video Player States
export const COURSE_STATES = {
  NEW: 'NEW',
  PROGRESS: 'PROGRESS',
  COMPLETED: 'COMPLETED',
  PLAY_NEXT: 'PLAY_NEXT'
};

export const LESSON_WATCHED_STATES = {
  NOT_STARTED: 0,
  WATCHING: 1,
  INCOMPLETE: 2,
  COMPLETED: 3
};

export const authErrors = {
  parallelLogin: {
    key: 'parallelLogin'
  },
  // ipBlocked: {
  //   key: 'ipBlocked',
  //   message: 'Maximum number of connected devices reached'
  // },
  fingerprintLimitReached: {
    key: 'fingerprintBlocked',
    fingerprint_limit_reached: 'fingerprint_limit_reached'
  }
};

export const apiErrorCodes = {
  download_expired: 'download_expired'
};

export const externalSites = {
  dailyQuestPlus: 'https://dailyquestplus.co.uk/',
  pretestPlus: 'https://pretestplus.co.uk/'
};
