/* eslint-disable no-unused-vars */
/* eslint-disable no-console */

function prev(current, pages) {
  const index = pages.indexOf(current);
  if (index === 0) {
    return pages[pages.length - 1];
    // return null;
  }
  return pages[index - 1];
}

function next(current, pages) {
  const index = pages.indexOf(current);
  if (index === pages.length - 1) {
    return pages[0];
    // return null;
  }
  return pages[index + 1];
}

export const getPrevLesson = ({ data, videoId }) => {
  const parsedVideoId = parseInt(videoId);

  const filteredSection = data.sections.filter(({ videos }) => {
    return videos.some(video => video.id === parsedVideoId);
  });

  // If there are no results bail out
  if (!filteredSection.length) {
    return null;
  }

  const flattenVideos = data.sections
    .map(section => {
      return section.videos.map(video => {
        return {
          ...video,
          sectionId: section.id,
          sectionName: section.name
        };
      });
    })
    .flat();

  const videoIds = flattenVideos.map(video => video.id);

  const prevId = prev(parsedVideoId, videoIds);

  const prevLessonObj = flattenVideos.filter(video => video.id === prevId);

  return prevLessonObj[0];
};

export const getNextLesson = ({ data, videoId }) => {
  const parsedVideoId = parseInt(videoId);

  const filteredSection = data.sections.filter(({ videos }) => {
    return videos.some(video => video.id === parsedVideoId);
  });

  // If there are no results bail out
  if (!filteredSection.length) {
    return null;
  }

  const flattenVideos = data.sections
    .map(section => {
      return section.videos.map(video => {
        return {
          ...video,
          sectionId: section.id,
          sectionName: section.name
        };
      });
    })
    .flat();

  const videoIds = flattenVideos.map(video => video.id);

  const nextId = next(parsedVideoId, videoIds);

  const nextLessonObj = flattenVideos.filter(video => video.id === nextId);

  return nextLessonObj[0];
};
