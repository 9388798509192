// import Fuse from 'fuse.js';
import { Fzf } from 'fzf';

// const searchFuseOptions = {
//   keys: ['title'],
//   isCaseSensitive: false
// };

const searchOptions = {
  selector: item => item.title
};

const fuzzySearch = ({ query, items }) => {
  //   const fuseSearch = new Fuse(items, searchFuseOptions);

  const search = new Fzf(items, searchOptions);

  return search.find(query);
};

export default fuzzySearch;
