/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import PropTypes from 'prop-types';

import dayjs from 'dayjs';
import { spacer } from '@styles/utilities';
import { useRef } from 'react';
import { newNotification, notificationRow, notificationDot } from './NotificationBox.styles';

const NotificationItem = ({
  id,
  text,
  link,
  linkText,
  markedAsRead,
  createdAt,
  toggleNotificationRead
}) => {
  const notificationItem = useRef(null);
  return (
    <li css={[notificationRow, !markedAsRead && newNotification]}>
      <h5 className="title mr-bt-0">{text}</h5>
      {linkText && (
        <h5 className="h5-v2 mr-bt-0">
          <a
            href={link}
            target="_blank"
            rel="noopener noreferrer"
            onClick={() => toggleNotificationRead({ notificationId: id, value: +!markedAsRead })}
          >
            {linkText}
          </a>
        </h5>
      )}
      <p className="timestamp mr-bt-0">{dayjs(createdAt).format('D MMM')}</p>
      <div
        ref={notificationItem}
        onClick={() => toggleNotificationRead({ notificationId: id, value: +!markedAsRead })}
        aria-label={`Mark as ${markedAsRead ? 'unread' : 'read'}`}
        title={`Mark as ${markedAsRead ? 'unread' : 'read'}`}
        role="button"
        tabIndex={0}
        onKeyDown={() => notificationItem.current && notificationItem.current.focus()}
        css={[
          css`
            ${notificationDot({ markedAsRead, isStacked: true })}
          `,
          spacer.mrT5
        ]}
      >
        Mark as Read
      </div>
    </li>
  );
};

NotificationItem.propTypes = {
  id: PropTypes.number,
  text: PropTypes.string.isRequired,
  link: PropTypes.string,
  linkText: PropTypes.string,
  markedAsRead: PropTypes.number,
  createdAt: PropTypes.string,
  toggleNotificationRead: PropTypes.func
};

NotificationItem.defaultProps = {
  id: 1,
  link: '',
  linkText: '',
  markedAsRead: false,
  createdAt: '',
  toggleNotificationRead: () => {}
};

export default NotificationItem;
