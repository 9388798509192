import { useState, useCallback, useMemo } from 'react';
import { useMutation, useInfiniteQuery, queryCache } from 'react-query';
import request from 'utils/request';
import { api } from 'globals/constants';
import { createContainer } from 'unstated-next';
import { get } from 'lodash-es';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { UserInfoContainer } from './auth';

// ESlint doesnt allow snake_case
const transformAPIFieldsToCamelCase = notification => {
  return {
    id: notification.id,
    text: notification.text,
    link: notification.link,
    linkText: notification.link_text,
    createdAt: notification.created_at,
    markedAsRead: notification.marked_as_read
  };
};

const useUserNotifications = () => {
  const [allNotifications, setAllNotifications] = useState([]);
  const [unreadNotifications, setUnreadNotifications] = useState([]);
  const { ID, token } = UserInfoContainer.useContainer();

  const { status, data, fetchMore, canFetchMore, isFetching } = useInfiniteQuery(
    'userNotifications',
    async (key, page = 1) => {
      const { response } = await request({
        method: 'GET',
        endpoint: `${api.endpoints.userNotifications.findAll({ userId: ID })}?page=${page}`,
        token
      });
      return response;
    },
    {
      refetchOnWindowFocus: true,
      getFetchMore: lastGroup => {
        return lastGroup.next_page_url ? lastGroup.current_page + 1 : false;
      }
    }
  );

  useDeepCompareEffect(() => {
    const newNotifications = data
      .map(pageData => {
        return get(pageData, 'data', []).map(transformAPIFieldsToCamelCase);
      })
      .flat(1);

    const newUnreadNotifications = newNotifications.filter(
      notification => notification.markedAsRead !== 1
    );

    // if (!isEqual(unreadNotifications, newUnreadNotifications)) {
    setUnreadNotifications(newUnreadNotifications);
    // }
    // if (!isEqual(allNotifications, newNotifications)) {
    setAllNotifications(newNotifications);
    // }
  }, [allNotifications, data, unreadNotifications]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fetchMoreMemo = useCallback(() => fetchMore(), []);

  return useMemo(
    () => ({
      allNotifications,
      unreadNotifications,
      fetchMore: fetchMoreMemo,
      status,
      canFetchMore,
      isFetching
    }),
    [allNotifications, canFetchMore, fetchMoreMemo, isFetching, status, unreadNotifications]
  );
};

export const useToggleNotificationRead = () => {
  const { ID } = UserInfoContainer.useContainer();
  const [toggleNotificationMutation, { data, status, error }] = useMutation(
    async ({ notificationId, value }) => {
      const { response } = await request({
        method: 'PUT',
        endpoint: api.endpoints.userNotifications.update({ userId: ID, notificationId }),
        payload: {
          marked_as_read: value
        }
      });
      return response;
    },
    {
      onSuccess: () => {
        queryCache.refetchQueries('userNotifications');
      }
    }
  );

  return { toggleNotificationRead: toggleNotificationMutation, data, status, error };
};

const UserNotificationsContainer = createContainer(useUserNotifications);
export default UserNotificationsContainer;
