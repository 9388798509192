/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useRef, useState, Fragment } from 'react';
import Modal from 'components/Modal/Modal';
import Carousel from 'components/Carousel/Carousel';
import Controls from 'components/Carousel/CarouselControls';
import { UserInfoContainer } from 'api/auth';

import ImageWelcome from 'images/welcome-tour/slide-welcome.jpg';
import ImageDownloadPapers from 'images/welcome-tour/slide-download-papers.jpg';
import ImageViewSolutions from 'images/welcome-tour/slide-view-solutions.jpg';
import ImageAccountUpdate from 'images/welcome-tour/slide-account-update.jpg';
import ImageBuyProducts from 'images/welcome-tour/slide-buy-products.jpg';
import ImageNotifications from 'images/welcome-tour/slide-notifications.jpg';
import ImageEnd from 'images/welcome-tour/slide-end.jpg';

import { get } from 'lodash-es';
import A from 'components/htmlElements/A';
import { site } from 'globals/constants';
import { useWelcomeTourModalContext } from './WelcomeTourModalContext';
import Slide from './Slide';
import TourControls from './TourControls';
import { modalContainerStyles, controlArrowStyles } from './WelcomeTourModal.styles';

const staticSlides = [
  {
    title: 'Download your papers',
    subtitle:
      'Click on the download button to access your papers. The password to open the files is set as your email address.',
    gifURL: ImageDownloadPapers
  },
  {
    title: 'View Solutions',
    subtitle:
      'Just click on set of solutions to watch the detailed tutorial videos. You can watch these videos as many times as you like!',
    gifURL: ImageViewSolutions
  },
  {
    title: 'Change your Password',
    subtitle: 'Click My Account then Change Password to change your password.',
    gifURL: ImageAccountUpdate
  },
  {
    title: 'Browse Products',
    subtitle: 'Click here to return to our online shop to buy more products.',
    gifURL: ImageBuyProducts
  },
  {
    title: 'Notifications',
    subtitle: "We'll send you notifications here when we release new products.",
    gifURL: ImageNotifications
  }
];

const CAROUSEL_SETTINGS = {
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: true,
  nextArrow: <Controls.NextArrow fill="currentColor" css={controlArrowStyles} />,
  prevArrow: <Controls.PrevArrow fill="currentColor" css={controlArrowStyles} />
};

const WelcomeTourModal = () => {
  const { isOpen, closeWelcomeTourModal } = useWelcomeTourModalContext();
  const user = UserInfoContainer.useContainer();
  const billingItems = get(user, 'billing.items', {});

  const firstSlide = {
    title: `Welcome to your dashboard, ${billingItems.first_name || ''}!`,
    subtitle: 'From here you can easily access all your papers and video solutions.',
    gifURL: ImageWelcome
  };

  const lastSlideSubtitle = (
    <Fragment>
      You can view this tour again by clicking &apos;My Account&apos; and then &apos;Help&apos;.
      Feel free to{' '}
      <A href={`${site.url}/contact/`} target="_blank">
        contact us
      </A>{' '}
      if you have any questions or queries.
    </Fragment>
  );
  const lastSlide = {
    title: 'The End!',
    subtitle: lastSlideSubtitle,
    gifURL: ImageEnd
  };

  const slides = [firstSlide, ...staticSlides, lastSlide];

  const [currentSlide, setCurrentSlide] = useState(0);
  const carouselRef = useRef(null);
  const isLastSlide = currentSlide === slides.length - 1;
  const isFirstSlide = currentSlide === 0;
  const gotoNextSlide = () => {
    if (isLastSlide) {
      closeWelcomeTourModal();
    } else {
      // eslint-disable-next-line no-unused-expressions
      carouselRef.current && carouselRef.current.slickNext();
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={closeWelcomeTourModal}
      css={modalContainerStyles({ isFirstSlide, isLastSlide })}
    >
      <Carousel
        ref={carouselRef}
        beforeChange={(_, next) => setCurrentSlide(next)}
        settings={CAROUSEL_SETTINGS}
      >
        {slides.map(({ gifURL, title, subtitle }) => (
          <Slide key={title} gifURL={gifURL} title={title} subtitle={subtitle} />
        ))}
      </Carousel>
      <TourControls
        isFirstSlide={isFirstSlide}
        closeModal={closeWelcomeTourModal}
        isLastSlide={isLastSlide}
        gotoNextSlide={gotoNextSlide}
      />
    </Modal>
  );
};

export default WelcomeTourModal;
