import React, { useRef, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import stickybits from 'stickybits';
import { Waypoint } from 'react-waypoint';
import { groupBy } from 'lodash-es';
import usePrimarnavHeight from 'hooks/usePrimarynavHeight';
import useCurrentBreakpoint from 'hooks/useCurrentBreakpoint';
import getMonthFromTimestamp from 'utils/getMonthFromTimestamp';
// import Timeline from 'components/NotificationsPageComponents/Timeline';
import NotificationList from 'components/NotificationsPageComponents/NotificationList';
import PageLoader from 'components/PageLoader/PageLoader';

const STICKY_OFFSET_DESKTOP = 0;
const STICKY_OFFSET_MOBILE = 10;

const NotificationsPage = ({
  notifications,
  fetchMore,
  canFetchMore,
  isFetching,
  toggleNotificationRead
}) => {
  const timelineContainer = useRef(null);
  const { primarynavHeight } = usePrimarnavHeight();
  const { breakpointInfo } = useCurrentBreakpoint();
  const isBelowTabletViewport = breakpointInfo.breakpoint.includes('d-vp');
  // const handleMonthLinkClick = e => {
  //   const { target } = e;
  //   const notificationItem = document.querySelector(target.dataset.month);
  //   if (notificationItem) {
  //     window.scrollTo({
  //       top: notificationItem.offsetTop + primarynavHeight + 40,
  //       behavior: `smooth`
  //     });
  //   }
  // };
  useEffect(() => {
    const offset =
      primarynavHeight + (isBelowTabletViewport ? STICKY_OFFSET_DESKTOP : STICKY_OFFSET_MOBILE);
    if (timelineContainer.current) {
      stickybits(timelineContainer.current, { stickyBitStickyOffset: offset });
    }
  }, [timelineContainer, isBelowTabletViewport, primarynavHeight]);

  const groupedNotifications = useMemo(
    () => groupBy(notifications, notification => getMonthFromTimestamp(notification.createdAt)),
    [notifications]
  );

  // const notificationMonths = Object.keys(groupedNotifications);

  return (
    <section className="mr-bt-40">
      <div className="container no-push-container">
        <div className="row">
          <div className="col-xs-12">
            <div className="card-space">
              <div className="section-title-wrapper is-relative mr-b-20  section-primary-title">
                <div className="section-title-txt-wrapper">
                  <h1 className="section-title-txt">
                    {notifications.length < 1 && 'No '}Notifications
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {notifications.length > 0 && (
        <div className="notifications-main-wrapper">
          <div className="container no-push-container">
            <div className="row justify-content-between">
              <div className="order-2 col-lg-8">
                <NotificationList
                  toggleNotificationRead={toggleNotificationRead}
                  groupedNotifications={groupedNotifications}
                  onSelect={() => {}}
                />
                {isFetching && <PageLoader height="100px" isRelative />}
                <Waypoint
                  bottomOffset="-40%"
                  onEnter={() => {
                    // console.info('Loading more notifications');
                    // eslint-disable-next-line no-unused-expressions
                    canFetchMore && fetchMore();
                  }}
                />
              </div>

              {/* <div
                className="order-1 order-md-2 col-md-4 col-lg-3"
                ref={timelineContainer}
                style={{ height: `100%` }}
              >
                <Timeline months={notificationMonths} onMonthLinkClick={handleMonthLinkClick} />
              </div> */}
            </div>
          </div>
        </div>
      )}
    </section>
  );
};

NotificationsPage.propTypes = {
  notifications: PropTypes.array,
  toggleNotificationRead: PropTypes.func,
  fetchMore: PropTypes.func,
  // canFetchMore can be number or boolean, number if more pages are available, boolean `false` if no next page available
  canFetchMore: PropTypes.any,
  isFetching: PropTypes.bool
};

NotificationsPage.defaultProps = {
  notifications: [],
  toggleNotificationRead: () => {},
  fetchMore: () => {},
  canFetchMore: false,
  isFetching: false
};

export default NotificationsPage;
