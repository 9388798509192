/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import Modal from 'components/Modal/Modal';
import H1 from 'components/htmlElements/H1';
import Button from 'components/htmlElements/Button';
import notice from 'images/notice.svg';
import H3 from 'components/htmlElements/H3';
import { spacer } from '@styles/utilities';
import PropTypes from 'prop-types';
import { site } from 'globals/constants';

const IpRestrictionNoticeModal = ({ isOpen, toggle }) => (
  <Modal isOpen={isOpen} toggle={toggle}>
    <div
      css={css`
        text-align: center;
      `}
    >
      <img css={spacer.mrB10} src={notice} alt="notice" width="100px" />
      <H1 css={spacer.mrB10}>Account locked!</H1>
      <H3 css={spacer.mrB20} book>
        You have exceeded the number of allowed devices you can login from. Please contact support.
      </H3>
      <Button as="a" href={`${site.url}/contact/`} target="_blank">
        Contact Support
      </Button>
    </div>
  </Modal>
);

IpRestrictionNoticeModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired
};

export default IpRestrictionNoticeModal;
