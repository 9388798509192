import { css } from '@emotion/core';
import { breakpoints, colors, spacer, mixins, misc } from '@styles/utilities';

// eslint-disable-next-line import/prefer-default-export
export const modalContainerStyles = ({ isFirstSlide, isLastSlide }) => css`
  ${mixins.respondToMax(misc.viewport['b-vp'])} {
    & .container,
    & .row,
    & .row,
    & .row > div,
    & .modal-contents,
    & .modal-content-wrapper {
      padding: 0;
      margin: 0;
      width: 100%;
      height: 100%;
      & > div {
        border-radius: 0;
      }
    }

    .modal-content-wrapper {
      display: flex;
      flex-direction: column;
      .slick-slider,
      .slick-list,
      .slick-track {
        height: 100%;
        min-height: 0;
        min-width: 0;
      }
      .slick-slide > div {
        height: 100%;
        display: flex;
        flex-direction: column;
      }
    }
  }
  .modal-padding {
    padding: 0;
  }
  .slick-slider {
    position: relative;
    .slick-prev,
    .slick-next {
      z-index: 1;
      position: absolute;
      top: auto;
      bottom: 0;
      ${breakpoints({
        opacity: [0, isFirstSlide ? 0 : 1]
      })}
    }
    .slick-prev {
      left: -2%;
    }
    .slick-next {
      right: -2%;
      color: ${isLastSlide ? colors['grey-shade-1'] : colors.primaryLinkHover};
      cursor: ${isLastSlide ? 'not-allowed' : 'pointer'};
    }
  }
`;

export const controlArrowStyles = breakpoints({
  fontSize: [14, 14, 18, 24]
});

export const carouselDotsStyles = css`
  display: flex;
  margin: 0;
  padding: 0;
  justify-content: center;
  & > li {
    cursor: pointer;
    ${spacer.mrR10};
    width: 10px;
    height: 10px;
    display: inline-block;
    background-color: ${colors['grey-shade-1']};
    border-radius: 50%;
    text-align: center;
    color: transparent;
    &.slick-active {
      background-color: ${colors.darkblue};
    }
  }
`;

export const slideContainerStyles = css`
  ${mixins.respondToMax(misc.viewport['b-vp'])} {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  text-align: center;
  position: relative;
`;
