/** @jsx jsx */
import { useInfiniteQuery } from 'react-query';
import request from 'utils/request';
import { api } from 'globals/constants';
import { createContainer } from 'unstated-next';
import getProductFields from 'utils/getProductFields';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { useState } from 'react';
import { UserInfoContainer } from './auth';

const useUserWritingService = () => {
  const { ID } = UserInfoContainer.useContainer();
  const [writingService, setWritingServices] = useState([]);
  const {
    status,
    data,
    isFetching,
    isFetchingMore,
    fetchMore,
    canFetchMore,
    error
  } = useInfiniteQuery(
    'userWritingServiceKey',
    async (key, page = 0) => {
      const { response } = await request({
        method: 'GET',
        endpoint: `${api.endpoints.userWritingService.findAll({ userId: ID })}?page=${page}`
      });
      return response;
    },
    {
      refetchOnWindowFocus: false,
      getFetchMore: lastGroup => {
        return lastGroup.next_page_url ? lastGroup.current_page + 1 : false;
      }
    }
  );

  useDeepCompareEffect(() => {
    if (data && data.data !== 'undefined') {
      const newWritingService = data
        .map(pageData => {
          if (!pageData.data) {
            return false;
          }

          return pageData.data.map(getProductFields);
        })
        .flat(1);
      setWritingServices(newWritingService);
    }
  }, [data, data.data]);

  return { writingService, error, status, isFetching, isFetchingMore, fetchMore, canFetchMore };
};

const UserWritingServiceContainer = createContainer(useUserWritingService);

export default UserWritingServiceContainer;
