import styled from '@emotion/styled';
import styledMap from 'styled-map';
import facepaint from 'facepaint';
import isPropValid from '@emotion/is-prop-valid';
import { typography, colors, mixins, misc } from '@styles/utilities';

const breakPoints = facepaint([mixins.respondTo(misc.viewport['e-vp'])]);

const H5 = styled('h5', {
  shouldForwardProp: prop => isPropValid(prop)
})`
  font-family: ${typography.fonts.gothamRounded.fontFamily};
  font-weight: ${styledMap`
    book: ${typography.fonts.gothamRounded.fontWeight.book};
    default: ${typography.fonts.gothamRounded.fontWeight.medium};
  `};
  line-height: 140%;
  font-style: normal;
  margin: ${misc.defaultTextMargin};
  letter-spacing: normal;
  color: ${props => (props.color ? colors[props.color] : colors.black)};
  ${breakPoints({
    fontSize: ['12px', typography.baseSizes.epsilon]
  })};
`;

export default H5;
