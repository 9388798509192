/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import Accordion from 'react-bootstrap/Accordion';
import CourseSectionHeading from 'components/CourseSectionHeading';
import LessonBand from 'components/LessonBand';

import getLessonBookmarks from 'utils/videoHelpers/getLessonBookmarks';

const CourseContent = props => {
  const getSectionBookmarks = sectionId => {
    if (props.courseBookmarks) {
      const currentSection = props.courseBookmarks.filter(section => section.id === sectionId);
      return currentSection.length ? currentSection[0].bookmarks.length : 0;
    }
    return undefined;
  };

  const toggleContentAccordion = index => props.setActiveContentAccordion(index);

  return (
    <Accordion defaultActiveKey={props.activeContentAccordion}>
      {props.sectionsData
        ? props.sectionsData.map((section, index) => (
            <Fragment key={section.id}>
              <Accordion.Toggle
                as="div"
                className="course-section-anchor"
                onClick={() => toggleContentAccordion(index)}
                eventKey={index.toString()}
              >
                <CourseSectionHeading
                  title={section.name}
                  lessonsWatched={section.watched_videos}
                  totalLessons={section.total_videos}
                  totalLessonsDuration={section.total_time}
                  totalBookmarks={getSectionBookmarks(section.id)}
                />
              </Accordion.Toggle>
              <Accordion.Collapse eventKey={index.toString()}>
                <Fragment>
                  {section.videos
                    ? section.videos.map(video => {
                        return (
                          <LessonBand
                            key={video.id}
                            lessonId={video.id}
                            title={video.name}
                            totalBookmarks={
                              props.courseBookmarks !== undefined
                                ? getLessonBookmarks(props.courseBookmarks, video.id).length
                                : undefined
                            }
                            lessonDuration={video.time}
                            lessonWatched={video.watched}
                            sectionId={section.id}
                          />
                        );
                      })
                    : null}
                </Fragment>
              </Accordion.Collapse>
            </Fragment>
          ))
        : null}
    </Accordion>
  );
};

CourseContent.propTypes = {
  sectionsData: PropTypes.array.isRequired,
  courseBookmarks: PropTypes.array,
  activeContentAccordion: PropTypes.number,
  setActiveContentAccordion: PropTypes.func
};

CourseContent.defaultProps = {
  activeContentAccordion: 0,
  setActiveContentAccordion: () => {},
  courseBookmarks: undefined
};

export default CourseContent;
