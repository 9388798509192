import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'components/Router';
import PageLoader from 'components/PageLoader';
import { ROUTE_PATHS } from 'globals/constants';
import Layout from 'layouts';

// Fetch from api
import { UserInfoContainer } from 'api/auth';
import UserCourseContainer, { useUserCourseVideo, useUserCourseRestart } from 'api/userCourse';
import CommonVideoApiContainer from 'hooks/useCommonVideoApi';

import VideoPlayerStateContainer from 'hooks/useVideoPlayerState';
import CourseSingle from './page';

const CourseContainer = () => {
  const {
    data,
    courseError,
    courseStatus,
    courseBookmarksError,
    courseBookmarksStatus
  } = UserCourseContainer.useContainer();

  return (
    <Layout>
      <VideoPlayerStateContainer.Provider>
        {(courseError || courseBookmarksError) && <Redirect to={ROUTE_PATHS.notFound} />}
        {(courseStatus !== 'success' || courseBookmarksStatus !== 'success') && (
          <PageLoader isFetching isRelative atPageHeight />
        )}
        {data.courseData && Object.keys(data.courseData).length && data.courseBookmarks && (
          <CourseSingle courseData={data.courseData} courseBookmarks={data.courseBookmarks} />
        )}
      </VideoPlayerStateContainer.Provider>
    </Layout>
  );
};

const ContainerWithProvider = props => {
  const { ID: userId } = UserInfoContainer.useContainer();
  return (
    <UserCourseContainer.Provider initialState={{ courseId: props.courseId, userId }}>
      <CommonVideoApiContainer.Provider
        initialState={{
          updateVideoHook: useUserCourseVideo,
          restartAllHook: useUserCourseRestart
        }}
      >
        <CourseContainer {...props} />
      </CommonVideoApiContainer.Provider>
    </UserCourseContainer.Provider>
  );
};

ContainerWithProvider.propTypes = {
  courseId: PropTypes.string.isRequired
};

export default ContainerWithProvider;
