import request from 'utils/request';
import { useMutation, queryCache } from 'react-query';
import { api, ROUTE_PATHS } from 'globals/constants';
import { navigate } from '@reach/router';
import { UserInfoContainer } from 'api/auth';

const useTermsAndConditions = () => {
  const { setUserInfo } = UserInfoContainer.useContainer();

  const [acceptTermsAndConditions, { data, error, status }] = useMutation(
    async ({ userId }) => {
      const { response } = await request({
        method: 'PUT',
        endpoint: api.endpoints.acceptTermsAndConditions({ userId }),
        payload: {
          accepted_terms: 1
        }
      });
      return response;
    },
    {
      onSuccess(res) {
        if (!res.error) {
          setUserInfo(currentInfo => ({ ...currentInfo, accepted_terms: 1 }));
          navigate(ROUTE_PATHS.home);
          queryCache.refetchQueries('userRecommendedProductsKey');
        }
      },
      onError(err) {
        console.info(err, 'error');
      }
    }
  );
  return { acceptTermsAndConditions, data, error, status };
};

export default useTermsAndConditions;
