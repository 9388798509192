import { useState, useEffect } from 'react';
import { misc } from '@styles/utilities';

const useCurrentBreakpoint = () => {
  // const getBreakpoint = () => {
  //   switch (true) {
  //     case parseInt(misc.viewport['a-vp']) >= window.innerWidth:
  //       return 'a-vp';
  //     case parseInt(misc.viewport['b-vp']) >= window.innerWidth:
  //       return 'b-vp';
  //     case parseInt(misc.viewport['c-vp']) >= window.innerWidth:
  //       return 'c-vp';
  //     case parseInt(misc.viewport['d-vp']) >= window.innerWidth:
  //       return 'd-vp';
  //     case parseInt(misc.viewport['e-vp']) >= window.innerWidth:
  //       return 'e-vp';
  //     default:
  //       return 'f-vp';
  //   }
  // };

  const getBreakpoint = () => {
    // const activeViewPorts = [];
    const activeViewPorts = Object.keys(misc.viewport).map(viewportKey => {
      return parseInt(misc.viewport[viewportKey]) >= window.innerWidth && viewportKey;
    });
    return activeViewPorts;
  };

  const [breakpointInfo, updateBreakpointValue] = useState({
    width: window.innerWidth,
    breakpoint: getBreakpoint()
  });

  function triggerBreakpointUpdate() {
    updateBreakpointValue({
      width: window.innerWidth,
      breakpoint: getBreakpoint()
    });
  }

  useEffect(() => {
    window.addEventListener('resize', triggerBreakpointUpdate);
    return () => {
      window.removeEventListener('resize', triggerBreakpointUpdate);
    };
  });

  return {
    breakpointInfo,
    triggerBreakpointUpdate
  };
};

export default useCurrentBreakpoint;
