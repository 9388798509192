import { css } from '@emotion/core';

import { colors, mixins, misc } from '@styles/utilities';

export const notificationWrapper = css`
  position: absolute;
  z-index: ${parseInt(misc.navZindex) + 1};
  background-color: ${colors['white-shade-1']};
  width: 100%;
  opacity: 0;
  visibility: hidden;
  padding: 0;
  overflow: hidden;
  overflow-y: auto;
  ${mixins.transition({
    properties: 'opacity'
  })};
  ${mixins.respondTo(misc.viewport['c-vp'])} {
    border-radius: ${misc.largeBorderRadius};
    border: ${misc.border.default};
    max-height: 345px;
    max-width: 300px;
  }
  &.active {
    opacity: 1;
    visibility: visible;
  }
`;

export const notificationList = css`
  position: relative;
  overflow: hidden;
  overflow-y: auto;
  max-height: calc(100% - 40px);
  ${mixins.respondTo(misc.viewport['c-vp'])} {
    max-height: 300px;
    max-width: 300px;
  }
`;

export const allNotificationLink = css`
  padding: 10px ${misc.cardSpacing.small};
  border-top: ${misc.border.default};
  ${mixins.respondTo(misc.viewport['c-vp'])} {
    padding: 10px 20px;
  }
`;

export const notificationRow = css`
  padding: ${misc.cardSpacing.small};
  border-bottom: ${misc.border.default};
  &:last-child {
    border-bottom: none;
  }
  &.all-notifications-link {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  ${mixins.respondTo(misc.viewport['c-vp'])} {
    padding: 20px;
  }
`;

export const newNotification = css`
  position: relative;
`;
export const notificationDot = ({ markedAsRead, isStacked }) => css`
  color: ${colors.darkblue};
  cursor: ${markedAsRead ? `initial` : `pointer`};
  opacity: 1;
  font-size: 10px;
  &:hover {
    opacity: ${markedAsRead ? 1 : 0.7};
  }
  ${mixins.transition()}
  ${mixins.respondTo(misc.viewport['c-vp'])} {
    position: ${isStacked ? 'relative' : 'absolute'};
  }
`;

// Used globally for `body` tag
export const overlayNotification = css`
  &.overlay-notification {
    ${mixins.respondToMax(misc.viewport['c-vp'])} {
      overflow: hidden;
      &:after {
        content: '';
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: ${colors['white-shade-1']};
        z-index: 1;
        ${mixins.respondTo(misc.viewport['c-vp'])} {
          display: none;
        }
      }
    }
  }
`;
