import React from 'react';
import ReactDOM from 'react-dom';

// Sentry & Logrocket logging
// import 'utils/logging';

import App from './App';

if (process.env.NODE_ENV === 'development') {
  const whyDidYouRender = require('@welldone-software/why-did-you-render');
  whyDidYouRender(React);
}
const rootElement = document.getElementById('app');
ReactDOM[rootElement.hasChildNodes() ? 'hydrate' : 'render'](<App />, rootElement);
