import { object, string, ref } from 'yup';

const DEFAULT_REQUIRED_MESSAGE = 'This field is required';
export const billingDetailsFormValidationSchema = object().shape({
  first_name: string().required(DEFAULT_REQUIRED_MESSAGE),
  last_name: string().required(DEFAULT_REQUIRED_MESSAGE),
  billing_phone: string().required(DEFAULT_REQUIRED_MESSAGE),
  billing_address_1: string().required(DEFAULT_REQUIRED_MESSAGE),
  billing_postcode: string().required(DEFAULT_REQUIRED_MESSAGE),
  billing_city: string().required(DEFAULT_REQUIRED_MESSAGE),
  billing_country: string().required(DEFAULT_REQUIRED_MESSAGE),
  user_email: string()
    .email()
    .required(DEFAULT_REQUIRED_MESSAGE)
});

export const passwordFormValidationSchema = object().shape({
  current_password: string().required(DEFAULT_REQUIRED_MESSAGE),
  password: string()
    .min(8, 'Password is too short - should be 8 chars minimum.')
    .required(DEFAULT_REQUIRED_MESSAGE),
  password_confirmation: string()
    .oneOf([ref('password'), null], "Passwords don't match")
    .required(DEFAULT_REQUIRED_MESSAGE)
});
