import React from 'react';

import UserPapersContainer from 'api/userPapers';
import UserMarkSchemesContainer from 'api/userMarkSchemes';

import Layout from 'layouts/';
import { Redirect } from '@reach/router';
import { ROUTE_PATHS } from 'globals/constants';
import PageLoader from 'components/PageLoader/PageLoader';
import PapersPage from './page';

const PapersContainer = () => {
  const {
    papers,
    error,
    status
    // canFetchMore,
    // fetchMore,
    // isFetchingMore
  } = UserPapersContainer.useContainer();

  const {
    markSchemes,
    error: markSchemesError,
    status: markSchemesStatus
    // canFetchMore: markSchemesCanFetchMore,
    // fetchMore: markSchemesFetchMore,
    // isFetchingMore: markSchemesIsFetchingMore
  } = UserMarkSchemesContainer.useContainer();

  if (error || markSchemesError) {
    return <Redirect to={ROUTE_PATHS.notFound} />;
  }

  return (
    <Layout>
      {status !== 'success' && markSchemesStatus !== 'success' ? (
        <PageLoader isFetching isRelative atPageHeight />
      ) : (
        <PapersPage
          papers={[...papers, ...markSchemes]}
          // canFetchMore={canFetchMore}
          // fetchMore={fetchMore}
          // isFetchingMore={isFetchingMore}
          // markSchemesCanFetchMore={markSchemesCanFetchMore}
          // markSchemesFetchMore={markSchemesFetchMore}
          // markSchemesIsFetchingMore={markSchemesIsFetchingMore}
        />
      )}
    </Layout>
  );
};
export default () => (
  <UserPapersContainer.Provider>
    <UserMarkSchemesContainer.Provider>
      <PapersContainer />
    </UserMarkSchemesContainer.Provider>
  </UserPapersContainer.Provider>
);
