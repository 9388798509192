/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Bookmark from 'components/Bookmark';
import LoaderOverlay, { loaderOverlayClass } from 'components/LoaderOverlay';
import A from 'components/htmlElements/A';
import Icon from 'components/Icon';
import H6 from 'components/htmlElements/H6';

import useParseQueryString from 'hooks/useLocationQueryString';
import VideoPlayerStateContainer from 'hooks/useVideoPlayerState';

import { useCreateVideoBookmark } from 'api/userCourseVideoBookmarks';
import secondsToHms from 'utils/secondsToHms';

import { colors, txtColor } from '@styles/utilities';

// import useWhyDidYouUpdate from 'hooks/useWhyDidYouUpdate';

const VideoSidebar = props => {
  // useWhyDidYouUpdate('VideoSidebar', props);

  const newBookmarkInput = useRef(null);
  const bookmarksList = useRef(null);

  const [loading, toggleLoading] = useState(false);
  const [newBookmarkText, updateNewBookmarkText] = useState('');
  const [addingBookmark, toggleAddingBookmark] = useState(false);
  const [seekTime, updateSeekTime] = useState('');
  const [hasError, toggleHasError] = useState(false);
  const [errorText, updatErrorText] = useState('');

  const queryStringData = useParseQueryString();

  const {
    videoPlayerState: { currentLesson, lessonTimeInfinity },
    toggleSidebarVisibility
  } = VideoPlayerStateContainer.useContainer();

  // API methods
  const { createBookmark } = useCreateVideoBookmark();

  // Scroll to the bottom of bookmarks list
  useEffect(() => {
    if (document.body.contains(bookmarksList.current)) {
      bookmarksList.current.scrollTo(0, bookmarksList.current.scrollHeight);
    }
  }, []);

  const releaseTime = () => {
    toggleAddingBookmark(false);
    updateSeekTime('');
  };

  const captureTime = () => {
    if (!addingBookmark) {
      toggleAddingBookmark(true);
      updateSeekTime(secondsToHms(lessonTimeInfinity));
    }
  };

  const addNewBookmark = async event => {
    event.preventDefault();
    const inputFieldValue = newBookmarkInput.current.value.trim();
    if (inputFieldValue.length) {
      // Set loading to true before calling saga
      // @ref: https://github.com/redux-saga/redux-saga/issues/907
      toggleLoading(true);

      // Remove focus from input field
      document.activeElement.blur();

      // API CALL TO "ADD A NEW BOOKMARK"
      await createBookmark({
        courseId: currentLesson.courseId,
        videoId: currentLesson.lessonId,
        bookmarkText: newBookmarkText,
        bookmarkTime: secondsToHms(lessonTimeInfinity)
      });

      // AFTER API CALL IS DONE CALL THIS [OR CALL IN CALLBACK/SUCCESS]
      toggleLoading(false);
      updateNewBookmarkText('');
      releaseTime();
    } else {
      toggleHasError(true);
      updatErrorText('Enter Text');
    }
  };

  const saveOnReturn = event => {
    if (event.keyCode === 13) {
      addNewBookmark(event);
    }
    return false;
  };

  const textAreaOnChange = event => {
    updateNewBookmarkText(event.target.value);
    toggleHasError(false);
    updatErrorText('Enter Text');

    if (event.target.value.length) {
      captureTime();
    } else {
      releaseTime();
    }
  };

  return (
    <div className="sidebar-wrapper">
      {/* <h5 className="sidebar-lesson-title" title={props.lessonTitle}>
        {props.lessonTitle}
      </h5> */}
      <div className="sidebar-tabs">
        <div className="sidebar-tab">
          <h3 className="sidebar-text">Bookmarks</h3>
          <A as="div" className="bookmarks-close" onClick={() => toggleSidebarVisibility(false)}>
            <Icon icon="close" color={colors.primaryAnchorDefaultColor} size="2em" />
          </A>
        </div>

        <div className="sidebar-tabpanel ">
          <div className="sidebar-tabpanel-contents">
            <div className="sidebar-bookmarks-list" ref={bookmarksList}>
              {props.bookmarks.length
                ? props.bookmarks.map(bookmark => {
                    return (
                      <Bookmark
                        key={bookmark.id}
                        id={bookmark.id}
                        sectionId={parseInt(queryStringData.sectionId)}
                        lessonId={parseInt(queryStringData.lessonId)}
                        text={bookmark.name}
                        lessonDuration={bookmark.bookmark_time}
                      />
                    );
                  })
                : null}
            </div>

            <div className={`add-new-bookmark-block ${loading ? loaderOverlayClass : ''}`}>
              <div className="new-bookmark-input-wrapper">
                <textarea
                  ref={newBookmarkInput}
                  className="new-bookmark-input"
                  placeholder="Enter bookmark"
                  value={newBookmarkText}
                  onChange={textAreaOnChange}
                  onKeyDown={saveOnReturn}
                />
                <div
                  className="show-current-lesson-time-wrapper"
                  role="button"
                  tabIndex="0"
                  // onKeyDown={newBookmarkInput.current && newBookmarkInput.current.focus()}
                >
                  {addingBookmark ? (
                    <p className="show-current-lesson-time txt-small">{seekTime}</p>
                  ) : null}
                </div>
              </div>
              <div className="new-bookmark-action-wrapper">
                {hasError ? <p className="new-bookmark-error-text txt-small">{errorText}</p> : null}
                <H6
                  css={[txtColor['white-transparent']]}
                  as="div"
                  className="new-bookmark-action-text"
                >
                  Press <span className="txt-white">Enter</span> to{' '}
                  <A as="span" onClick={addNewBookmark}>
                    Save
                  </A>
                </H6>
              </div>
              {loading ? <LoaderOverlay /> : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

VideoSidebar.propTypes = {
  bookmarks: PropTypes.array.isRequired
};

// VideoSidebar.whyDidYouRender = true;
export default React.memo(VideoSidebar);
