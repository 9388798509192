import React from 'react';
import PropTypes from 'prop-types';

const Container = ({ style, className, children, ...props }) => (
  <div className={`${className || ''} container`} style={style} {...props}>
    {children}
  </div>
);
Container.propTypes = {
  style: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
  className: PropTypes.string,
  children: PropTypes.node.isRequired
};
Container.defaultProps = {
  style: {},
  className: ''
};

const Row = ({ style, className, children, ...props }) => (
  <div className={`${className || ''} row`} style={style} {...props}>
    {children}
  </div>
);
Row.propTypes = {
  style: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
  className: PropTypes.string,
  children: PropTypes.node.isRequired
};
Row.defaultProps = {
  style: {},
  className: ''
};

const Col = ({ style, className, children, ...props }) => (
  <div className={`${className || ''} col-12 col-class`} style={style} {...props}>
    {children}
  </div>
);
Col.propTypes = {
  style: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
  className: PropTypes.string,
  children: PropTypes.node.isRequired
};
Col.defaultProps = {
  style: {},
  className: ''
};

export { Container, Row, Col };
