/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';
import PropTypes from 'prop-types';

import { Link } from 'components/Router/Router';
import { ROUTE_PATHS } from 'globals/constants';

import H5 from 'components/htmlElements/H5';

import * as styles from './NotificationBox.styles';

import NotificationItem from './NotificationItem';

const NotificationBox = React.forwardRef(
  ({ position, notifications, toggleNotificationRead, height }, ref) => {
    return (
      <div
        className="active"
        css={styles.notificationWrapper}
        ref={ref}
        style={{ top: position.top, left: position.left, height }}
      >
        <ul css={styles.notificationList}>
          {notifications.length > 0 ? (
            notifications.map(notification => (
              <NotificationItem
                key={notification.id}
                {...notification}
                toggleNotificationRead={toggleNotificationRead}
              />
            ))
          ) : (
            <li css={styles.notificationRow}>
              <H5>No new notifications</H5>
            </li>
          )}
        </ul>
        <div css={styles.allNotificationLink} className="all-notifications-link">
          <H5
            book
            color="primaryAnchorDefaultColor"
            className="click-outside-trigger"
            as={Link}
            to={ROUTE_PATHS.notifications}
          >
            View all notifications
          </H5>
        </div>
      </div>
    );
  }
);
NotificationBox.propTypes = {
  position: PropTypes.object.isRequired
};

export default NotificationBox;
