/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';
import PropTypes from 'prop-types';
import Wrapper from './Wrapper';

// Styling
import { StyledWrapper } from './InlineFields.styles';

const InlineFields = props => {
  const children = React.Children.map(props.children, child => {
    return <Wrapper>{child}</Wrapper>;
  });

  return (
    <StyledWrapper as={Wrapper} {...props}>
      {children}
    </StyledWrapper>
  );
};

InlineFields.propTypes = {
  children: PropTypes.node.isRequired
};

export default InlineFields;
