/** @jsx jsx */
import { jsx } from '@emotion/core';
// import PropTypes from 'prop-types';

import { NavigationGlobalsContainer } from 'components/Navigation';
import * as styles from './Footer.styles';

const Footer = () => {
  const { fauxMobileNavHeight } = NavigationGlobalsContainer.useContainer();

  return (
    <div className="container-wrapper withlovebd" css={styles.footerStyles}>
      <div className="container">
        <div className="row">
          <div className="bd-row col-sm-12 clearfix">
            <p className="txt-white txt-left txt-upper no-pad l-float">
              &copy; {new Date().getFullYear()}
              <span className="pipe">|</span>
              <span className="footer-meta">Exam Papers Plus Ltd.</span>
            </p>
            {/* <p className="txt-white txt-left txt-upper no-pad THE_DOT r-float">
              Built at{' '}
              <a href="https://translatez.co" target="_blank" rel="noopener noreferrer">
                TranslateZ
              </a>
            </p> */}
          </div>
        </div>
      </div>
      <div className="faux-mobile-nav" style={{ height: `${fauxMobileNavHeight}px` }} />
    </div>
  );
};

Footer.propTypes = {};

export default Footer;
