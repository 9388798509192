const secondsToHms = seconds => {
  const d = Number(seconds);

  const h = Math.floor(d / 3600);
  const m = Math.floor((d % 3600) / 60);
  const s = Math.floor((d % 3600) % 60);

  return `${h > 0 ? `${`0${h}`.slice(-2)}:` : '00:'}${`0${m}`.slice(-2)}:${`0${s}`.slice(-2)}`;
};

export default secondsToHms;
