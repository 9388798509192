/** @jsx jsx */
import { jsx, Global } from '@emotion/core';

import { useState } from 'react';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';

// Components
import { Col } from 'components/Grid';
import Icon from 'components/Icon';

// Styling
import { misc } from '@styles/utilities';
import * as styles from './Modal.styles';

ReactModal.setAppElement('body');

export const useModal = (initialState = false) => {
  const [isOpen, setIsOpen] = useState(initialState);

  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);
  const toggleModal = () => setIsOpen(s => !s);

  return {
    isOpen,
    toggle: toggleModal,
    openModal,
    closeModal
  };
};

const defaultOverlayStyles = {
  zIndex: misc.modalZindex
};

const Modal = ({ colConfig, emotionCss, shouldCloseOnEsc, toggle, children, ...props }) => {
  return (
    <ReactModal
      css={[emotionCss, styles.modalWrapper]}
      shouldCloseOnEsc={shouldCloseOnEsc || true}
      style={{ overlay: defaultOverlayStyles }}
      onRequestClose={toggle}
      shouldCloseOnOverlayClick
      {...props}
    >
      <Global styles={styles.modalGlobalStyles} />
      <Col {...colConfig} style={{ maxWidth: 620 }} className="modal-container">
        <div css={styles.contents} className="modal-contents">
          <button
            type="button"
            className="close modal-close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={toggle}
          >
            <Icon icon="close" />
          </button>
          <div className="modal-content-wrapper modal-padding">{children}</div>
        </div>
      </Col>
    </ReactModal>
  );
};

Modal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  emotionCss: PropTypes.object,
  children: PropTypes.node.isRequired,
  shouldCloseOnEsc: PropTypes.bool,
  colConfig: PropTypes.object
};

Modal.defaultProps = {
  emotionCss: {},
  shouldCloseOnEsc: true,
  colConfig: { md: 10 }
};

export default Modal;
