import styled from '@emotion/styled';
import styledMap from 'styled-map';
import facepaint from 'facepaint';
import { typography, colors, mixins, misc } from '@styles/utilities';

const breakPoints = facepaint([
  mixins.respondTo(misc.viewport['c-vp']),
  mixins.respondTo(misc.viewport['e-vp'])
]);
const H3 = styled.h3`
  font-family: ${typography.fonts.gothamRounded.fontFamily};
  font-weight: ${styledMap`
    book: ${typography.fonts.gothamRounded.fontWeight.book};
    default: ${typography.fonts.gothamRounded.fontWeight.medium};
  `};
  line-height: 140%;
  font-style: normal;
  margin: ${misc.defaultTextMargin};
  letter-spacing: normal;
  color: ${props => (props.color ? colors[props.color] : colors.black)};
  ${breakPoints({
    fontSize: ['14px', '16px', typography.baseSizes.gamma]
  })};
`;

export default H3;
