/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import Button from 'components/htmlElements/Button';
import H2 from 'components/htmlElements/H2';
import H3 from 'components/htmlElements/H3';
import A from 'components/htmlElements/A';
import Modal, { useModal } from 'components/Modal';

import useLogin from 'api/auth';
import { site } from 'globals/constants';
import ForgotPassword from './ForgotPassword';

const Schema = Yup.object().shape({
  user: Yup.string()
    .email('Enter a valid email')
    .required('Email is a required field'),
  password: Yup.string().required()
});

const Login = () => {
  const { isOpen: forgotModal, toggle: toggleForgotModal } = useModal();
  const { login: hookLogin, status } = useLogin();

  const handleForgot = e => {
    e.preventDefault();
    toggleForgotModal(true);
  };

  const handleSubmit = async (values, formikBag) => {
    formikBag.setSubmitting(true);
    const loginResponse = await hookLogin(values);

    if (loginResponse.errors) {
      formikBag.setErrors({ password: loginResponse.errors });
    }
    formikBag.setSubmitting(false);
  };
  return (
    <React.Fragment>
      <section className="login-section">
        <div className="login-container-wrapper">
          <div className="container no-push-container">
            <div className="row">
              <div className="col-xs-12 txt-center mr-bt-80">
                <div className="login-logo">
                  <A href={site.url} target="_blank">
                    <img
                      src={require('images/logo-horizontal.svg').default}
                      alt="Exam Papers Plus"
                    />
                  </A>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-xs-12">
                <div className="login-card bg-white">
                  <H2 style={{ marginBottom: 20 }}>Log In</H2>
                  <Formik
                    className="set-password-modal-form clearfix"
                    initialValues={{
                      user: '',
                      password: ''
                    }}
                    onSubmit={handleSubmit}
                    validationSchema={Schema}
                  >
                    {({ errors, touched, isSubmitting }) => (
                      <Form className="dashboard-login-form clearfix">
                        <div className="input-field-wrapper">
                          <Field type="email" name="user" id="login-username" />
                          {errors.user && touched.user && (
                            <label id="login-username-error" className="error">
                              {errors.user}
                            </label>
                          )}
                          <label className="placeholder" htmlFor="login-username">
                            Email
                          </label>
                        </div>

                        <div className="input-field-wrapper">
                          <Field type="password" name="password" id="login-password" />
                          {errors.password && touched.password && (
                            <label id="login-password-error" className="error">
                              {errors.password}
                            </label>
                          )}
                          <label className="placeholder" htmlFor="login-password">
                            Password
                          </label>
                        </div>

                        <div className="forgot-pass-wrapper mr-t-20">
                          <p className="h3-v2 mr-bt-0">
                            <A href="#" onClick={handleForgot}>
                              Forgotten Password?
                            </A>
                          </p>
                        </div>

                        <div className="login-btn-wrapper mr-t-20">
                          <Button
                            disabled={isSubmitting}
                            type="submit"
                            loading={status === 'loading'}
                          >
                            Log In
                          </Button>
                        </div>
                      </Form>
                    )}
                  </Formik>

                  <Modal isOpen={forgotModal} toggle={toggleForgotModal}>
                    <ForgotPassword />
                  </Modal>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-xs-12 txt-center mr-b-80 mr-t-20">
                <H3 className="h3-v2">Don&apos;t have an account?</H3>
                <p className="h3-v2 mr-bt-0">
                  <a href={site.url} target="_blank" rel="noopener noreferrer">
                    Buy products first
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

Login.propTypes = {};

export default Login;
