import store from 'store';
import Fingerprint2 from 'fingerprintjs2';
import UAParser from 'ua-parser-js';

export default function generateFingerprint() {
  const options = {
    excludes: {
      webdriver: true,
      language: true,
      colorDepth: true,
      deviceMemory: true,
      pixelRatio: true,
      hardwareConcurrency: true,
      screenResolution: true,
      availableScreenResolution: true,
      timezoneOffset: true,
      timezone: true,
      sessionStorage: true,
      localStorage: true,
      indexedDb: true,
      addBehavior: true,
      openDatabase: true,
      cpuClass: true,
      doNotTrack: true,
      plugins: true,
      canvas: true,
      webgl: true,
      webglVendorAndRenderer: true,
      adBlock: true,
      hasLiedLanguages: true,
      hasLiedResolution: true,
      hasLiedOs: true,
      hasLiedBrowser: true,
      touchSupport: true,
      fonts: true,
      fontsFlash: true,
      audio: true,
      enumerateDevices: true
    },
    preprocessor(key, value) {
      if (key === 'userAgent') {
        const parser = new UAParser(value); // https://github.com/faisalman/ua-parser-js
        const userAgentMinusVersion = `${parser.getOS().name} ${parser.getBrowser().name}`;
        return userAgentMinusVersion;
      }
      return value;
    }
  };

  const callback = components => {
    const values = components.map(component => {
      return component.value;
    });
    const murmur = Fingerprint2.x64hash128(values.join(''), 31);
    store.set('ufp', {
      murmur,
      values
    });
  };

  if (window.requestIdleCallback) {
    requestIdleCallback(() => {
      Fingerprint2.get(options, callback);
    });
  } else {
    setTimeout(() => {
      Fingerprint2.get(options, callback);
    }, 500);
  }
}
