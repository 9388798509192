/** @jsx jsx */
import { Fragment, useEffect, useRef, useState } from 'react';
import { jsx } from '@emotion/core';
import { spacer } from '@styles/utilities';
import P from 'components/htmlElements/P';

import Icon from 'components/Icon/Icon';
import A from 'components/htmlElements/A';
import * as styles from './AudioPlayer.styles';
import { useAudioPlayerContext } from './AudioPlayerContext';

const AudioPlayer = () => {
  const { audioPlayer, setAudioPlayer, closeAudioPlayer } = useAudioPlayerContext();
  const [playing, setIsPlaying] = useState(true);
  const audioRef = useRef(null);

  useEffect(() => {
    if (audioPlayer && audioRef.current) {
      setTimeout(() => {
        audioRef.current.src = audioPlayer.file;
        audioRef.current.currentTime = 0;
        audioRef.current.play();
        setIsPlaying(true);
      }, 100);
    }
  }, [audioPlayer, setAudioPlayer]);

  const handleAudio = () => {
    if (playing) {
      audioRef.current.pause();
      setIsPlaying(false);
    } else {
      audioRef.current.play();
      setIsPlaying(true);
    }
  };

  return (
    <Fragment>
      {audioPlayer && audioPlayer.show ? (
        <div
          css={[styles.audioBox, spacer.padBT15, spacer.padLR15]}
          className="d-flex align-items-center"
        >
          <P semiBold>{audioPlayer.name}</P>
          <div className="d-flex align-items-center" css={[spacer.mrL10, styles.inner]}>
            <div className="d-flex">
              <A onClick={handleAudio}>
                <Icon
                  icon={playing ? 'pause' : 'play-filled'}
                  css={spacer.mrL10}
                  size="2em"
                  className="effect"
                />
              </A>
              <A href={audioPlayer.file} target="_blank" download>
                <Icon icon="download" css={spacer.mrL10} size="2em" />
              </A>
            </div>
            <A onClick={closeAudioPlayer} className="close-button">
              <Icon icon="close" css={spacer.mrL10} size="1.6em" />
            </A>
          </div>

          <audio
            ref={audioRef}
            controls
            autoPlay={audioPlayer.show ? 'autoplay' : ''}
            style={{ display: 'none' }}
          >
            <track kind="captions" src="sampleCaptions.vtt" srcLang="en" />
            <source type="audio/mp3" />
          </audio>
        </div>
      ) : null}
    </Fragment>
  );
};

export default AudioPlayer;
