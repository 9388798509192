import React, { useContext, createContext, useMemo, useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { UserInfoContainer } from 'api/auth';

const AudioPlayerContext = createContext();

export const useAudioPlayerContext = () => useContext(AudioPlayerContext);

const AudioPlayerProvider = ({ children }) => {
  const { token } = UserInfoContainer.useContainer();
  const [audioPlayer, setAudioPlayer] = useState({
    show: false,
    file: '',
    name: ''
  });

  useEffect(() => {
    if (!token) setAudioPlayer(null);
  }, [token]);

  const closeAudioPlayer = useCallback(() => {
    setAudioPlayer(null);
  }, []);

  const value = useMemo(() => ({ setAudioPlayer, audioPlayer, closeAudioPlayer }), [
    setAudioPlayer,
    audioPlayer,
    closeAudioPlayer
  ]);

  return <AudioPlayerContext.Provider value={value}>{children}</AudioPlayerContext.Provider>;
};

AudioPlayerProvider.propTypes = {
  children: PropTypes.any.isRequired
};

export default AudioPlayerProvider;
