/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { UnstyledLink } from 'components/Router';
import LoaderOverlay, { loaderOverlayClass } from 'components/LoaderOverlay';
import Icon from 'components/Icon';
import A from 'components/htmlElements/A';
import H6 from 'components/htmlElements/H6';

import { setQueryParams } from 'hooks/useLocationQueryString';
import VideoPlayerStateContainer, { usePlayVideo } from 'hooks/useVideoPlayerState';
import hmsToSeconds from 'utils/hmsToSeconds';
import { useUpdateVideoBookmark, useDeleteVideoBookmark } from 'api/userCourseVideoBookmarks';

import { colors, spacer, txtColor } from '@styles/utilities';

const Bookmark = props => {
  const [isInEditMode, toggleIsInEditMode] = useState(false);

  const textareaInput = useRef(null);
  const [editModeText, setEditModeText] = useState(props.text);

  const [errorState, setErrorState] = useState({
    hasError: false,
    errorText: ''
  });

  const [loading, toggleLoading] = useState(false);

  const [deleteConfirmation, toggleDeleteConfirmation] = useState(false);

  const [bookmarkHoverColor, updateBookmarkHoverColor] = useState('');

  const {
    videoPlayerState: {
      currentLesson: { courseId }
    }
  } = VideoPlayerStateContainer.useContainer();

  // API methods
  const { updateBookmark } = useUpdateVideoBookmark();
  const { deleteBookmark: deleteBookmarkApi } = useDeleteVideoBookmark();

  const editBookmark = event => {
    event.preventDefault();

    // Update states
    toggleIsInEditMode(true);
    setEditModeText(props.text);
  };

  // Focus on the input field after it opens
  useEffect(() => {
    if (isInEditMode) {
      // Focus the text input using the raw DOM API
      if (textareaInput) textareaInput.current.focus();
    }
  }, [isInEditMode]);

  const saveBookmarkEdit = async event => {
    event.preventDefault();
    const inputFieldValue = textareaInput.current.value.trim();
    if (inputFieldValue.length) {
      // Reset Error state
      setErrorState({
        hasError: false,
        errorText: ''
      });

      if (inputFieldValue !== props.text) {
        // Set loading to true before calling saga
        // @ref: https://github.com/redux-saga/redux-saga/issues/907
        toggleLoading(true);

        // Remove focus from input field
        document.activeElement.blur();

        // Call the server and save the value
        await updateBookmark({
          courseId,
          videoId: props.lessonId,
          bookmarkId: props.id,
          bookmarkText: inputFieldValue,
          bookmarkTime: props.lessonDuration
        });
        setEditModeText(inputFieldValue);
        // SERVER CALL GOES HERE

        // Update state again here
        toggleLoading(false);
        toggleIsInEditMode(false);
      } else {
        toggleIsInEditMode(false);
      }
    } else {
      setErrorState({
        hasError: true,
        errorText: 'Enter text'
      });
    }
  };

  const cancelEdit = event => {
    event.preventDefault();
    toggleIsInEditMode(false);
    setEditModeText(props.text);
    setErrorState({
      hasError: false,
      errorText: ''
    });
  };

  const textAreaOnChange = event => setEditModeText(event.target.value);

  const saveOnReturn = event => {
    if (event.keyCode === 13) {
      saveBookmarkEdit(event);
    }
  };

  const deleteBookmark = async event => {
    event.preventDefault();

    // Set loading to true before calling api
    toggleLoading(true);

    // CALL API TO DELETE BOOKMARK
    await deleteBookmarkApi({
      courseId,
      videoId: props.lessonId,
      bookmarkId: props.id
    });

    // Update state again
    toggleLoading(false);
    toggleDeleteConfirmation(false);
  };

  const onTitleMouseEnter = () => updateBookmarkHoverColor('txt-green');

  const onTitleMouseLeave = () => updateBookmarkHoverColor('txt-green');

  const showDeleteConfirmation = () => toggleDeleteConfirmation(true);

  const closeDeleteConfirmation = () => toggleDeleteConfirmation(false);

  // To expand the player window
  const togglePlayMode = usePlayVideo();

  // Info for setting the correct query string parameters
  const currentLessonParams = {
    sectionId: props.sectionId,
    lessonId: props.lessonId,
    currentLessonTime: hmsToSeconds(props.lessonDuration)
  };

  const handleBookmarkClick = () => {
    togglePlayMode(true);
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  return (
    <div
      className={`bookmark-block ${props.theme === 'dark' ? 'bookmark-block-dark' : ''} ${
        loading ? loaderOverlayClass : ''
      }`}
    >
      {props.showBookmarkIcon ? (
        <p className={`bookmark-icon-wrapper ${bookmarkHoverColor || ''}`}>
          <Icon icon="bookmark" />
        </p>
      ) : null}
      <div className="bookmark-block-contents">
        {isInEditMode ? (
          <div className="bookmark-edit">
            <textarea
              ref={textareaInput}
              className="bookmark-edit-input"
              value={editModeText}
              onChange={textAreaOnChange}
              onKeyDown={saveOnReturn}
            />
            <div className="edit-bookmark-actions">
              <H6 as="div" css={spacer.mrBT0}>
                {errorState.hasError ? (
                  <span className="txt-red">{errorState.errorText} or </span>
                ) : (
                  ''
                )}
                <A as="span" className="cancel-edit txt-green" onClick={cancelEdit}>
                  Cancel
                </A>
              </H6>
              <H6
                css={[
                  spacer.mrBT0,
                  txtColor[props.theme === 'dark' ? 'blackTransparent54' : 'white-transparent']
                ]}
                as="div"
              >
                Press{' '}
                <span
                  className="bookmark-action-text-enter"
                  css={txtColor[props.theme === 'dark' ? 'blackTransparent85' : 'white']}
                >
                  Enter
                </span>{' '}
                to{' '}
                <A as="span" className="txt-green" onClick={saveBookmarkEdit}>
                  Save
                </A>
              </H6>
            </div>
          </div>
        ) : (
          <div className="bookmark-unit">
            <div className="bookmark-text">
              {props.titleClickable ? (
                <p>
                  <UnstyledLink
                    to={`?${setQueryParams(currentLessonParams)}`}
                    className="txt-black-2"
                    onMouseEnter={props.showBookmarkIcon ? onTitleMouseEnter : null}
                    onMouseLeave={props.showBookmarkIcon ? onTitleMouseLeave : null}
                    onClick={handleBookmarkClick}
                  >
                    {props.text}
                  </UnstyledLink>
                </p>
              ) : (
                <p>{props.text}</p>
              )}
            </div>
            {deleteConfirmation ? (
              <H6 as="div" className="bookmark-actions">
                <span className="bookmark-action-text">Delete bookmark?</span>
                <A as="span" onClick={deleteBookmark}>
                  Yes
                </A>
                <A as="span" onClick={closeDeleteConfirmation}>
                  No
                </A>
              </H6>
            ) : (
              <H6 as="div" className="bookmark-actions">
                {props.titleClickable ? (
                  <span className="seekto">
                    {props.lessonName} at {props.lessonDuration}
                  </span>
                ) : (
                  <span>
                    <A
                      as={UnstyledLink}
                      to={`?${setQueryParams(currentLessonParams)}`}
                      className="seekto"
                      onClick={handleBookmarkClick}
                    >
                      at {props.lessonDuration}
                    </A>
                  </span>
                )}
                <A
                  as="span"
                  className="edit-bookmark"
                  highlightSvg
                  onClick={editBookmark}
                  color={props.theme === 'dark' ? colors.blackTransparent54 : ''}
                >
                  <Icon icon="edit" color="inherit" />
                </A>
                <A
                  as="span"
                  className="delete-bookmark"
                  highlightSvg
                  onClick={showDeleteConfirmation}
                  color={props.theme === 'dark' ? colors.blackTransparent54 : ''}
                >
                  <Icon icon="delete" color="inherit" />
                </A>
              </H6>
            )}
          </div>
        )}
      </div>
      {loading ? <LoaderOverlay /> : null}
    </div>
  );
};

Bookmark.propTypes = {
  showBookmarkIcon: PropTypes.bool,
  titleClickable: PropTypes.bool,
  theme: PropTypes.string,
  lessonName: PropTypes.string,
  id: PropTypes.number.isRequired,
  sectionId: PropTypes.number.isRequired,
  lessonId: PropTypes.number.isRequired,
  text: PropTypes.string.isRequired,
  lessonDuration: PropTypes.string.isRequired
};

Bookmark.defaultProps = {
  showBookmarkIcon: false,
  titleClickable: false,
  theme: '',
  lessonName: ''
};

export default Bookmark;
