import { useState, useMemo } from 'react';
import { useQuery, useMutation, queryCache } from 'react-query';
import request from 'utils/request';
import { api } from 'globals/constants';
import { createContainer } from 'unstated-next';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { UserInfoContainer } from 'api/auth';

const useUserCourse = ({ courseId, userId }) => {
  const [courseState, updateCourseState] = useState();

  const { data: courseData, error: courseError, status: courseStatus } = useQuery(
    [
      'userCourse',
      {
        courseId: parseInt(courseId),
        userId: parseInt(userId)
      }
    ],
    async () => {
      const { response } = await request({
        method: 'GET',
        endpoint: api.endpoints.userCourses.find({ userId, courseId })
      });
      return response;
    },
    {
      refetchOnWindowFocus: false
    }
  );

  const {
    data: courseBookmarks,
    error: courseBookmarksError,
    status: courseBookmarksStatus
  } = useQuery(
    courseData && [
      'userCourseBookmarks',
      {
        courseId: parseInt(courseId),
        userId: parseInt(userId)
      }
    ],
    async () => {
      const { response } = await request({
        method: 'GET',
        endpoint: api.endpoints.userCoursesBookmarks.findAll({ userId, courseId })
      });
      return response;
    },
    {
      refetchOnWindowFocus: false
    }
  );

  const memoCourseData = useMemo(() => courseData || {}, [courseData]);
  const memoCourseBookmarks = useMemo(() => courseBookmarks || [], [courseBookmarks]);

  useDeepCompareEffect(() => {
    updateCourseState({
      courseData: memoCourseData,
      courseBookmarks: memoCourseBookmarks
    });
  }, [courseBookmarksStatus, memoCourseBookmarks, memoCourseData]);

  return {
    data: { ...courseState },
    courseError,
    courseStatus,
    courseBookmarksError,
    courseBookmarksStatus
  };
};

const userCourseContainer = createContainer(useUserCourse);

// For marking a video watched and updating watched_time
export const useUserCourseVideo = () => {
  const [updateVideo, { data, error, status }] = useMutation(
    async ({ collectionId, lessonId, userId, payload }) => {
      const { response } = await request({
        method: 'PUT',
        endpoint: api.endpoints.userCourseVideo.update({
          courseId: collectionId,
          videoId: lessonId,
          userId
        }),
        payload
      });
      return response;
    },
    {
      onMutate: ({ payload, collectionId, userId }) => {
        // Optimistically update the query watched status
        queryCache.setQueryData(['userCourse', { courseId: collectionId, userId }], previous => {
          return {
            ...previous,
            last_seen: {
              ...previous.last_seen,
              watched: payload.watched
            }
          };
        });
      },
      onError(err) {
        throw new Error(err);
      },

      onSettled(newData, err, variables) {
        queryCache.refetchQueries('userCourse', {
          courseId: variables.collectionId,
          userId: variables.userId
        });
      }
    }
  );

  return { updateVideo, data, error, status };
};

export const useUserCourseRestart = () => {
  const { ID: userId } = UserInfoContainer.useContainer();

  const [restartFn, { data, error, status }] = useMutation(
    async ({ id, restart }) => {
      const { response } = await request({
        method: 'PUT',
        endpoint: api.endpoints.userCourses.restart({ courseId: id, userId }),
        payload: {
          restart,
          restart_confirmation: restart
        }
      });
      return response;
    },
    {
      onSettled(newData, err, variables) {
        queryCache.refetchQueries('userCourse', {
          courseId: variables.id,
          userId
        });
      }
    }
  );
  return { restartFn, data, error, status };
};

export default userCourseContainer;
