/* eslint-disable import/prefer-default-export */
import { css } from '@emotion/core';

import { colors, mixins, misc } from '@styles/utilities';

export const profileBoxWrapper = css`
  position: absolute;
  z-index: ${parseInt(misc.navZindex) + 1};
  background-color: ${colors['white-shade-1']};
  overflow: hidden;
  padding: ${misc.cardSpacing.small};
  border-radius: ${misc.largeBorderRadius};
  border: ${misc.border.default};
  opacity: 0;
  visibility: hidden;
  ${mixins.transition({
    properties: 'opacity'
  })};
  li:not(:last-child) {
    margin-bottom: 10px;
  }
  &.active {
    opacity: 1;
    visibility: visible;
  }
  ${mixins.respondTo(misc.viewport['c-vp'])} {
    padding: 20px;
  }
`;
