import { useEffect, useState } from 'react';
import useParseQueryString, { setQueryParams } from 'hooks/useLocationQueryString';
import hmsToSeconds from 'utils/hmsToSeconds';

const useFirstTimeQueryStringUpdate = (courseData, dataType = 'course') => {
  const queryStringData = useParseQueryString();

  const [currentQueryParams, updateQueryParams] = useState(setQueryParams(queryStringData));

  useEffect(() => {
    // If video is watched get the last watched info
    const lastVideoInfo = {
      sectionId: courseData.last_seen
        ? courseData.last_seen[`${dataType}_section_id`]
        : courseData.sections[0].id,
      lessonId: courseData.last_seen
        ? courseData.last_seen[`${dataType}_video_id`]
        : courseData.sections[0].videos[0].id,
      currentLessonTime:
        courseData.last_seen && courseData.last_seen.watched_time
          ? hmsToSeconds(courseData.last_seen.watched_time)
          : 0
    };

    // Navigate only if there is no query string info set
    if (!queryStringData.lessonId && !queryStringData.sectionId) {
      updateQueryParams(setQueryParams({ ...lastVideoInfo }));
    }
  }, [
    courseData.last_seen,
    courseData.sections,
    dataType,
    queryStringData.lessonId,
    queryStringData.sectionId
  ]);

  return { currentQueryParams };
};

export default useFirstTimeQueryStringUpdate;
