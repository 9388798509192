import { css } from '@emotion/core';
import colorHex from '@styles/tokens/colors';

const colorClasses = [];
Object.keys(colorHex).map(colorName => {
  colorClasses.push({
    [colorName]: css`
      background-color: ${colorHex[colorName]};
    `
  });
  return '';
});

export default Object.assign({}, ...colorClasses);
