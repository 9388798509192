import styled from '@emotion/styled';
import facepaint from 'facepaint';
import { typography, colors, mixins, misc } from '@styles/utilities';

const breakPoints = facepaint([
  mixins.respondTo(misc.viewport['c-vp']),
  mixins.respondTo(misc.viewport['e-vp'])
]);

const H2 = styled.h2`
  font-family: ${typography.fonts.gothamRounded.fontFamily};
  font-weight: ${typography.fonts.gothamRounded.fontWeight.medium};
  line-height: 140%;
  font-style: normal;
  margin: ${misc.defaultTextMargin};
  color: ${props => (props.color ? colors[props.color] : colors.black)};
  ${breakPoints({
    fontSize: ['16px', '20px', typography.baseSizes.beta]
  })};
`;

export default H2;
