import styled from '@emotion/styled';
import isPropValid from '@emotion/is-prop-valid';

import Paragraph from 'components/htmlElements/P';
import { colors, misc, mixins } from '@styles/utilities';

export const StyledInputCSSObject = {
  margin: 0,
  border: `1px solid ${colors.black}`,
  boxShadow: `none`,
  borderRadius: `${misc.smallBorderRadius}`,
  backgroundColor: `${colors.white}`,
  width: `100%`,
  WebkitAppearance: 'none',
  outline: 'none',
  lineHeight: 1,
  fontWeight: 'inherit',
  color: `${colors.blackTransparent84}`,
  '&:hover': {
    borderColor: `${colors.primaryLinkHover}`
  },
  '&:focus': {
    borderColor: `${colors.primaryLinkActive}`
  }
};

const inheritPStyles = Paragraph.withComponent('input');
const StyledInput = styled(inheritPStyles, {
  shouldForwardProp: prop => isPropValid(prop)
})`
  ${StyledInputCSSObject};
  padding: ${props =>
    props.iconPrefix
      ? `${misc.input.paddingVertical} ${parseInt(misc.input.paddingVertical) + 12}px`
      : `${misc.input.paddingVertical} ${misc.input.paddingVertical}`};
  ${mixins.linkActiveStyles} {
    outline: none;
  }
`;

export default StyledInput;
