import { useEffect } from 'react';

/**
 * Hook that alerts clicks outside of the passed ref
 * @ref: https://stackoverflow.com/a/42234988
 */
export default function useClickedOutsideComponent(ref, handleClick = () => {}) {
  /**
   * Clicked on outside of element
   */
  function handleClickOutside(event) {
    if (
      (ref.current && !ref.current.contains(event.target)) ||
      (ref.current && event.target.classList.contains('click-outside-trigger'))
    ) {
      handleClick(event);
    }
  }

  useEffect(() => {
    // Bind the event listener
    document.addEventListener('click', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('click', handleClickOutside);
    };
  });
}
