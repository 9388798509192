/* eslint-disable import/prefer-default-export */
import { css } from '@emotion/core';

import { breakpoints } from '@styles/utilities';

const commonArrowStyles = css`
  ${'' /* position: absolute;
  top: 50%;
  z-index: ${misc.navZindex - 2};
  transform: translateY(-50%); */}
  cursor: pointer;
  .arrow-wrapper {
    ${breakpoints({
      padding: ['10px', '', '', '15px', '']
    })}
  }
  &.small-arrow {
    .arrow-wrapper {
      ${breakpoints({
        padding: ['5px', '', '', '10px', '']
      })}
    }
  }
  &.vertical-arrow {
    left: 50%;
    transform: translateX(-50%) rotate(90deg);
  }
`;

export const prevArrowStyles = css`
  ${commonArrowStyles}
  ${'' /* ${breakpoints({
    left: ['0', '45px', '', '60px', '', '']
  })}
  &.small-arrow {
    right: auto;
  }
  &.vertical-arrow {
    top: auto;
    bottom: 100%;
    margin-bottom: -20px;
  } */}
`;

export const nextArrowStyles = css`
  ${commonArrowStyles}
  ${'' /* ${breakpoints({
    right: ['0', '45px', '', '60px', '', '']
  })}
  &.small-arrow {
    right: auto;
  }
  &.vertical-arrow {
    top: 100%;
    margin-top: -20px;
  } */}
`;
