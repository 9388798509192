import React from 'react';
import { Router } from 'components/Router';
import { ROUTE_PATHS } from 'globals/constants';

import ScrollToTop from 'utils/ScrollToTop';

// Pages
import Home from 'pages/Home/container';
import Login from 'pages/Login/page';
import Notifications from 'pages/Notifications/container';
import NotFound from 'pages/NotFound/page';
import Papers from 'pages/Papers/container';
import Profile from 'pages/Profile/page';
import TermsAndConditions from 'pages/TermsAndConditions/page';
import Solutions from 'pages/Solutions/container';
import SolutionSingle from 'pages/SolutionSingle/container';
import Courses from 'pages/Courses/container';
import CourseSingle from 'pages/CourseSingle/container';
import Mocks from 'pages/Mocks/container';
import WritingService from 'pages/WritingService/container';

import PrivateRoute from 'components/PrivateRoute';

// To disable scroll position see -> https://github.com/reach/router/issues/242
const Routes = () => {
  return (
    <Router className="main" primary={false}>
      <ScrollToTop path={ROUTE_PATHS.home}>
        <Login path={ROUTE_PATHS.login} />
        <PrivateRoute component={Profile} path={ROUTE_PATHS.profile} />
        <PrivateRoute component={TermsAndConditions} path={ROUTE_PATHS.termsAndConditions} />
        <PrivateRoute component={Home} path={ROUTE_PATHS.home} />
        <PrivateRoute component={Notifications} path={ROUTE_PATHS.notifications} />
        <PrivateRoute component={Papers} path={ROUTE_PATHS.papers} />
        <PrivateRoute component={Solutions} path={ROUTE_PATHS.solutions} />
        <PrivateRoute component={Courses} path={ROUTE_PATHS.courses} />
        <PrivateRoute component={Mocks} path={ROUTE_PATHS.mocks} />
        <PrivateRoute component={WritingService} path={ROUTE_PATHS.writingService} />
        <PrivateRoute component={CourseSingle} path={ROUTE_PATHS.courseSingle} />
        <PrivateRoute component={SolutionSingle} path={ROUTE_PATHS.solutionSingle} />
        <NotFound default />
      </ScrollToTop>
    </Router>
  );
};

export default Routes;
