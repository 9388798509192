import { useState, useEffect } from 'react';
import { useQuery, useMutation } from 'react-query';
import request from 'utils/request';
import { api } from 'globals/constants';
import { UserInfoContainer } from './auth';

export const useDownloadPaperPDF = () => {
  // `paperId` state is neccessary because, with `manual` fetching, `stampStatus` was always success, thus loader wasnt working
  // coulnt use isFetching either to check, cuz initially in `manual` its true, thus loader will always stay `On`
  const [paperId, setPaperId] = useState(null);
  const { ID } = UserInfoContainer.useContainer();

  // Get available PDFs array
  const { data: pdfListData, status: pdfListStatus, refetch: refetchPDFList } = useQuery(
    paperId && ['paperPdfStamp', paperId],
    async () => {
      // If paperId is not set return empty
      if (!paperId) return [];

      const { response } = await request({
        method: 'GET',
        endpoint: api.endpoints.paperPdfDownload({ userId: ID, paperId })
      });
      return response;
    },
    {
      refetchInterval: false,
      refetchOnWindowFocus: false
    }
  );

  // If paperId is changed, fetch the query
  useEffect(() => {
    if (paperId) {
      refetchPDFList();
    }
  }, [paperId, refetchPDFList]);

  // ========================
  // ========================
  // ========================

  // const [downloadablePDF, setPDFHash] = useState({
  //   paperId: null,
  //   pdfHash: null,
  //   fileExtension: 'pdf'
  // });

  // Stamp a single PDF hash and return the PDF url
  // const { data: pdfLinkData, status: pdfLinkStatus, refetch: refetchPDFLink } = useQuery(
  //   downloadablePDF.pdfHash && ['paperPdfData', downloadablePDF.pdfHash],
  //   async () => {
  //     if (!downloadablePDF.pdfHash) return [];

  //     const { response } = await request({
  //       method: 'GET',
  //       endpoint: `${api.endpoints.paperPdfDownload({
  //         userId: ID,
  //         paperId: downloadablePDF.paperId
  //       })}/${downloadablePDF.pdfHash}`
  //     });
  //     return response;
  //   },
  //   {
  //     refetchInterval: false,
  //     refetchOnWindowFocus: false
  //   }
  // );

  const [mutatePdfLink, { status: pdfLinkStatus, error: errorPDFLink }] = useMutation(
    async ({ pdfHash, pdfProductId }) => {
      if (!pdfHash) return [];

      const { response } = await request({
        method: 'GET',
        endpoint: `${api.endpoints.paperPdfDownload({
          userId: ID,
          paperId: pdfProductId
        })}/${pdfHash}`
      });
      return response;
    }
  );

  // // If pdfHash is changed, fetch the query
  // useEffect(() => {
  //   if (downloadablePDF.pdfHash !== null) {
  //     refetchPDFLink();
  //   }
  // }, [downloadablePDF.pdfHash]);

  return {
    getPaperPDFList: productId => setPaperId(productId),
    papersPDFList: pdfListData,
    paperPDFListStatus: pdfListStatus,
    //
    getPaperPDFLink: mutatePdfLink,
    // paperPDFLink: get(pdfLinkData, 'download_url', ''),
    // paperFileExtension: downloadablePDF.fileExtension,
    paperPDFLinkStatus: pdfLinkStatus,
    errorPDFLink
  };
};

export const useDownloadMarkSchemePDF = () => {
  const [markSchemeId, setMarkSchemeId] = useState(null);
  const { ID } = UserInfoContainer.useContainer();

  // Get available PDFs array
  const { data: pdfListData, status: pdfListStatus, refetch: refetchPDFList } = useQuery(
    markSchemeId && ['markSchemePdfStamp', markSchemeId],
    async () => {
      // If markSchemeId is not set return empty
      if (!markSchemeId) return [];

      const { response } = await request({
        method: 'GET',
        endpoint: api.endpoints.markSchemePdfDownload({ userId: ID, markSchemeId })
      });
      return response;
    },
    {
      refetchInterval: false,
      refetchOnWindowFocus: false
    }
  );

  // If markSchemeId is changed, fetch the query
  useEffect(() => {
    if (markSchemeId) {
      refetchPDFList();
    }
  }, [markSchemeId, refetchPDFList]);

  // ========================
  // ========================
  // ========================

  // const [downloadablePDF, setPDFHash] = useState({
  //   markSchemesId: null,
  //   pdfHash: null,
  //   fileExtension: 'pdf'
  // });

  // const { data: pdfLinkData, status: pdfLinkStatus, refetch: refetchPDFLink } = useQuery(
  //   downloadablePDF.pdfHash && ['markSchemePdfData', markSchemeId, downloadablePDF.pdfHash],
  //   async () => {
  //     if (!downloadablePDF.pdfHash) return [];

  //     const { response } = await request({
  //       method: 'GET',
  //       endpoint: `${api.endpoints.markSchemePdfDownload({
  //         userId: ID,
  //         markSchemeId: downloadablePDF.markSchemesId
  //       })}/${downloadablePDF.pdfHash}`
  //     });
  //     return response;
  //   },
  //   {
  //     refetchInterval: false,
  //     refetchOnWindowFocus: false
  //   }
  // );

  const [mutatePdfLink, { status: pdfLinkStatus, error: errorPDFLink }] = useMutation(
    async ({ pdfHash, pdfProductId }) => {
      if (!pdfHash) return [];

      const { response } = await request({
        method: 'GET',
        endpoint: `${api.endpoints.markSchemePdfDownload({
          userId: ID,
          markSchemeId: pdfProductId
        })}/${pdfHash}`
      });
      return response;
    }
  );

  // // If pdfHash is changed, fetch the query
  // useEffect(() => {
  //   if (downloadablePDF.pdfHash !== null) {
  //     refetchPDFLink();
  //   }
  // }, []);

  return {
    getMarkSchemePDFList: productId => setMarkSchemeId(productId),
    markSchemesPDFList: pdfListData,
    markSchemePDFListStatus: pdfListStatus,
    //
    getMarkSchemePDFLink: mutatePdfLink,
    // markSchemePDFLink: get(pdfLinkData, 'download_url', ''),
    markSchemePDFLinkStatus: pdfLinkStatus,
    errorPDFLink
  };
};

export const useDownloadWritingServicePDF = () => {
  // `writingServiceId` state is neccessary because, with `manual` fetching, `stampStatus` was always success, thus loader wasnt working
  // coulnt use isFetching either to check, cuz initially in `manual` its true, thus loader will always stay `On`
  const [writingServiceId, setWritingServiceId] = useState(null);
  const { ID } = UserInfoContainer.useContainer();

  // Get available PDFs array
  const { data: pdfListData, status: pdfListStatus, refetch: refetchPDFList } = useQuery(
    writingServiceId && ['writingServicePdfStamp', writingServiceId],
    async () => {
      // If writingServiceId is not set return empty
      if (!writingServiceId) return [];

      const { response } = await request({
        method: 'GET',
        endpoint: api.endpoints.writingServicePdfDownload({ userId: ID, writingServiceId })
      });
      return response;
    },
    {
      refetchInterval: false,
      refetchOnWindowFocus: false
    }
  );

  // If writingServiceId is changed, fetch the query
  useEffect(() => {
    if (writingServiceId) {
      refetchPDFList();
    }
  }, [writingServiceId, refetchPDFList]);

  // ========================
  // ========================
  // ========================

  // const [downloadablePDF, setPDFHash] = useState({
  //   paperId: null,
  //   pdfHash: null,
  //   fileExtension: 'pdf'
  // });

  // Stamp a single PDF hash and return the PDF url
  // const { data: pdfLinkData, status: pdfLinkStatus, refetch: refetchPDFLink } = useQuery(
  //   downloadablePDF.pdfHash && ['paperPdfData', downloadablePDF.pdfHash],
  //   async () => {
  //     if (!downloadablePDF.pdfHash) return [];

  //     const { response } = await request({
  //       method: 'GET',
  //       endpoint: `${api.endpoints.paperPdfDownload({
  //         userId: ID,
  //         paperId: downloadablePDF.paperId
  //       })}/${downloadablePDF.pdfHash}`
  //     });
  //     return response;
  //   },
  //   {
  //     refetchInterval: false,
  //     refetchOnWindowFocus: false
  //   }
  // );

  const [mutatePdfLink, { status: pdfLinkStatus, error: errorPDFLink }] = useMutation(
    async ({ pdfHash, pdfProductId }) => {
      if (!pdfHash) return [];

      const { response } = await request({
        method: 'GET',
        endpoint: `${api.endpoints.writingServicePdfDownload({
          userId: ID,
          writingServiceId: pdfProductId
        })}/${pdfHash}`
      });
      return response;
    }
  );

  // // If pdfHash is changed, fetch the query
  // useEffect(() => {
  //   if (downloadablePDF.pdfHash !== null) {
  //     refetchPDFLink();
  //   }
  // }, [downloadablePDF.pdfHash]);

  return {
    getWritingServicePDFList: productId => setWritingServiceId(productId),
    writingServicePDFList: pdfListData,
    writingServicePDFListStatus: pdfListStatus,
    //
    getWritingPDFLink: mutatePdfLink,
    // paperPDFLink: get(pdfLinkData, 'download_url', ''),
    // paperFileExtension: downloadablePDF.fileExtension,
    writingPDFLinkStatus: pdfLinkStatus,
    errorPDFLink
  };
};
