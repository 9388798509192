/* eslint-disable no-unused-vars */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';
import PropTypes from 'prop-types';
import A from 'components/htmlElements/A';
import Icon from 'components/Icon';
import { site } from 'globals/constants';

import { spacer } from '@styles/utilities';
import * as styles from './BlackNav.styles';

const BlackNav = React.forwardRef(
  (
    {
      checkoutUrl,
      itemsInCart,
      unreadNotifications,
      notificationToggle,
      chevron,
      profileBoxToggle
    },
    ref
  ) => {
    const { notificationRef, profileRef } = ref;

    return (
      <div className="black-nav-wrapper" css={styles.wrapper}>
        <div className="container">
          <div className="row">
            <div className="col-12 black-nav-col d-flex align-items-center justify-content-end">
              <ul css={styles.linksList} role="navigation">
                <li css={styles.linkItem} className="notification">
                  <A
                    as="span"
                    color="white"
                    ref={notificationRef}
                    onClick={notificationToggle}
                    css={styles.notificationCountWrapper(unreadNotifications)}
                  >
                    <span css={[styles.unreadNotificationCount, spacer.padBT5, spacer.padLR10]}>
                      {unreadNotifications}
                    </span>
                    <Icon
                      icon="bell-icon"
                      color="white"
                      size="1em"
                      css={[styles.notificationCountIcon, spacer.mrBT5, spacer.mrLR10]}
                    />
                  </A>
                </li>
                <li css={styles.linkItem}>
                  <A href={site.url} target="_blank" rel="noopener noreferrer" color="white">
                    Browse products
                  </A>
                </li>

                <li css={styles.linkItem}>
                  <A as="span" onClick={profileBoxToggle} ref={profileRef} color="white">
                    My Account
                    <Icon icon={chevron} css={[spacer.mrL5]} color="white" />
                  </A>
                </li>
                <li css={styles.linkItem}>
                  {/* <A
                    href={checkoutUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    css={styles.basketLink(itemsInCart)}
                  >
                    <Icon icon="basket" color="white" css={styles.basketIcon} />
                    <span>Basket</span>
                    <span
                      css={styles.basketItemCount(itemsInCart)}
                      className="basket-button-item-count"
                    >
                      {itemsInCart}
                    </span>
                  </A> */}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
);

BlackNav.propTypes = {
  checkoutUrl: PropTypes.string,
  itemsInCart: PropTypes.number,
  unreadNotifications: PropTypes.number
};

BlackNav.defaultProps = {
  checkoutUrl: '',
  itemsInCart: 0,
  unreadNotifications: 0
};

export default BlackNav;
