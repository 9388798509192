import { useMutation } from 'react-query';
import request from 'utils/request';
import { api } from 'globals/constants';
import { UserInfoContainer } from './auth';

export default () => {
  const { ID } = UserInfoContainer.useContainer();
  const [updatePassword, { data, status, error }] = useMutation(async ({ payload }) => {
    const { response } = await request({
      method: 'PUT',
      endpoint: api.endpoints.updatePassword({ userId: ID }),
      payload
    });
    return response;
  });
  return { updatePassword, data, status, error };
};
