/** @jsx jsx */
import { jsx } from '@emotion/core';
import PropTypes from 'prop-types';
import { Fragment, useEffect, useRef, useState } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import H3 from 'components/htmlElements/H3';
import CourseContent from 'components/CourseContent';
import useParseQueryString from 'hooks/useLocationQueryString';
import VideoPlayer from 'components/VideoPlayer';

import VideoPlayerStateContainer from 'hooks/useVideoPlayerState';
import { useNavigate } from 'components/Router';
import lessonInfo from 'utils/videoHelpers/lessonInfo';
import useFirstTimeQueryStringUpdate from 'hooks/useFirstTimeQueryStringUpdate';

const SolutionSinglePage = props => {
  const { courseData } = props;

  const {
    videoPlayerState,
    setCurrentLessonDetails,
    setVideoWrapperRef
  } = VideoPlayerStateContainer.useContainer();

  const queryStringData = useParseQueryString();

  // Update query string to resume previous lesson
  const { currentQueryParams } = useFirstTimeQueryStringUpdate(courseData, 'solution');
  const navigate = useNavigate();

  useEffect(() => {
    navigate(`?${currentQueryParams}`);
  }, [currentQueryParams, navigate]);

  const { lessonLink, lessonName, sectionName } = lessonInfo(
    courseData.sections,
    queryStringData.lessonId
  );

  // When query string updates, update the video's state as well
  useEffect(() => {
    setCurrentLessonDetails({
      courseId: courseData.id,
      sectionId: queryStringData.sectionId,
      lessonId: queryStringData.lessonId,
      // currentLessonTime: queryStringData.currentLessonTime,
      vimeoLink: lessonLink,
      lessonName,
      sectionName
    });
  }, [
    courseData.id,
    lessonLink,
    lessonName,
    queryStringData.lessonId,
    queryStringData.sectionId,
    sectionName,
    setCurrentLessonDetails
  ]);

  const videoSectionWrapperRef = useRef(null);
  useEffect(() => {
    // Push the current video info in URL
    setVideoWrapperRef(videoSectionWrapperRef);
  }, [setVideoWrapperRef]);

  // Preseve current open accordion state for content
  const [activeContentAccordion, setActiveContentAccordion] = useState(0);

  return (
    <Fragment>
      <div
        ref={videoSectionWrapperRef}
        className={`video-section-wrapper ${
          videoPlayerState.isExpanded ? 'video-section-wrapper-expanded' : ''
        } ${videoPlayerState.isFullscreen ? 'is-fullscreen' : ''} `}
      >
        <VideoPlayer courseData={courseData} />
      </div>

      <div className="course-contents-wrapper">
        <Tabs className="course-contents-tabs">
          <TabList className="course-contents-tablist container no-push-container">
            <Tab className="course-contents-tab">
              <div className="course-contents-text-wrapper">
                <H3 className="course-contents-text">Contents</H3>
              </div>
            </Tab>
          </TabList>

          <TabPanel className="course-contents-tabpanel">
            <div className="container no-push-container">
              <div className="row">
                <div className="col-xs-12">
                  <CourseContent
                    activeContentAccordion={activeContentAccordion}
                    setActiveContentAccordion={setActiveContentAccordion}
                    sectionsData={courseData.sections}
                  />
                </div>
              </div>
            </div>
          </TabPanel>
        </Tabs>
      </div>
    </Fragment>
  );
};

SolutionSinglePage.propTypes = {
  courseData: PropTypes.object.isRequired
};

export default SolutionSinglePage;
