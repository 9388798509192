/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Fragment, useEffect, useState } from 'react';
import { UnstyledLink } from 'components/Router';
import Icon from 'components/Icon';
import PropTypes from 'prop-types';
import useParseQueryString, { setQueryParams } from 'hooks/useLocationQueryString';
import VideoPlayerStateContainer, { usePlayVideo } from 'hooks/useVideoPlayerState';
import A from 'components/htmlElements/A';
import H6 from 'components/htmlElements/H6';

import { colors, txtColor } from '@styles/utilities';

const LessonBand = props => {
  const queryStringData = useParseQueryString();

  const togglePlayMode = usePlayVideo();

  const {
    videoPlayerState: { isVideoPlaying }
  } = VideoPlayerStateContainer.useContainer();

  const [activeBand, setActiveBand] = useState({
    icon: 'play',
    titleCssProp: []
  });
  useEffect(() => {
    if (
      parseInt(queryStringData.sectionId) === props.sectionId &&
      parseInt(queryStringData.lessonId) === props.lessonId &&
      isVideoPlaying
    ) {
      setActiveBand({
        icon: 'pause',
        titleCssProp: [txtColor.primaryLinkActive]
      });
      return;
    }

    if (props.lessonWatched) {
      setActiveBand({
        icon: 'circle-tick',
        titleCssProp: []
      });
      return;
    }

    setActiveBand({
      icon: 'play',
      titleCssProp: []
    });
  }, [
    isVideoPlaying,
    props.lessonId,
    props.lessonWatched,
    props.sectionId,
    props.title,
    queryStringData.lessonId,
    queryStringData.sectionId
  ]);

  const handleClick = () => {
    togglePlayMode(true);
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  return (
    <UnstyledLink
      to={`?${setQueryParams({
        sectionId: props.sectionId,
        lessonId: props.lessonId,
        currentLessonTime: 0
      })}`}
      className="lesson-band-wrapper"
      onClick={handleClick}
    >
      <p className="lesson-icon lesson-band-hoverchange">
        <Icon icon={activeBand.icon} color={colors.primaryAnchorDefaultColor} />
      </p>
      <div className="lesson-contents">
        <p className="lesson-title lesson-band-hoverchange" css={[...activeBand.titleCssProp]}>
          {props.title}
        </p>
        <H6 className="lesson-meta" color="blackTransparent54" book>
          {props.lessonDuration}
          {props.totalBookmarks !== undefined ? (
            <Fragment>
              {' | '}
              <Icon icon="bookmark" color={colors.blackTransparent54} /> {props.totalBookmarks}
            </Fragment>
          ) : (
            ''
          )}
        </H6>
      </div>
      <A as="p" book className="lesson-cta lesson-band-hoverchange">
        Watch
      </A>
    </UnstyledLink>
  );
};

LessonBand.propTypes = {
  lessonId: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  totalBookmarks: PropTypes.number,
  lessonDuration: PropTypes.string.isRequired,
  lessonWatched: PropTypes.number.isRequired,
  sectionId: PropTypes.number.isRequired
};

LessonBand.defaultProps = {
  totalBookmarks: undefined
};

export default LessonBand;
