/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import PropTypes from 'prop-types';
import { spacer } from '@styles/utilities';
import Button from 'components/htmlElements/Button';
import A from 'components/htmlElements/A';

const TourControls = ({ isFirstSlide, isLastSlide, gotoNextSlide, closeModal }) => {
  const getNextButtonText = () => {
    if (isFirstSlide) {
      return 'Take a 1 minute tour';
    }
    if (isLastSlide) {
      return 'Get started';
    }
    return 'Next';
  };

  return (
    <div
      css={css`
        text-align: center;
        ${spacer.padT20};
        ${spacer.padB40};
      `}
    >
      <Button primary css={spacer.mrB10} onClick={gotoNextSlide}>
        {getNextButtonText()}
      </Button>
      <br />
      <A
        href="#"
        onClick={e => {
          e.preventDefault();
          closeModal();
        }}
        css={css`
          ${spacer.mrB10};
          pointer-events: ${isFirstSlide ? 'initial' : 'none'};
          opacity: ${isFirstSlide ? 1 : 0};
        `}
      >
        SKIP FOR NOW
      </A>
    </div>
  );
};

TourControls.propTypes = {
  isFirstSlide: PropTypes.bool.isRequired,
  isLastSlide: PropTypes.bool.isRequired,
  gotoNextSlide: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired
};

export default TourControls;
