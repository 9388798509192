/** @jsx jsx */
import { jsx } from '@emotion/core';
import PropTypes from 'prop-types';
import Icon from 'components/Icon';
import { colors } from '@styles/utilities';
// import { prevArrowStyles, nextArrowStyles } from './Carousel.styles';
import { nextArrowStyles, prevArrowStyles } from './Carousel.styles';

// const AsButton = Button.withComponent(`${<Icon icon="chevron-left" />}`);

const PrevArrow = props => {
  const { className, style, onClick } = props;
  return (
    <div
      role="presentation"
      className={`${className} ${props.small ? 'small-arrow' : ''} ${
        props.vertical ? 'vertical-arrow' : ''
      }`}
      style={{ ...style }}
      onClick={onClick}
      css={prevArrowStyles}
    >
      <div className="arrow-wrapper">
        <Icon icon="chevron-left" size="1.6em" color={colors.primaryLinkHover} />
      </div>
    </div>
  );
};

PrevArrow.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  onClick: PropTypes.func,
  small: PropTypes.bool,
  vertical: PropTypes.bool
};
PrevArrow.defaultProps = {
  className: '',
  style: {},
  onClick: null,
  small: false,
  vertical: false
};

const NextArrow = props => {
  const { className, style, onClick, fill } = props;
  return (
    <div
      role="presentation"
      className={`${className} ${props.small ? 'small-arrow' : ''} ${
        props.vertical ? 'vertical-arrow' : ''
      }`}
      style={{ ...style }}
      onClick={onClick}
      css={nextArrowStyles}
    >
      <div className="arrow-wrapper">
        <Icon icon="chevron-right" size="1.6em" color={fill} />
      </div>
    </div>
  );
};

NextArrow.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  onClick: PropTypes.func,
  small: PropTypes.bool,
  vertical: PropTypes.bool,
  fill: PropTypes.string
};
NextArrow.defaultProps = {
  className: '',
  style: {},
  onClick: null,
  small: false,
  vertical: false,
  fill: colors.primaryLinkHover
};

export default { PrevArrow, NextArrow };
