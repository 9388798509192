import { useInfiniteQuery } from 'react-query';
import request from 'utils/request';
import { api, ROUTE_PATHS } from 'globals/constants';
import { createContainer } from 'unstated-next';

import useDeepCompareEffect from 'use-deep-compare-effect';
import { useState, useEffect } from 'react';
import { get } from 'lodash-es';
import dotPngToSvg from 'utils/dotPngToSvg';
import { UserInfoContainer } from './auth';

const useUserSolutions = () => {
  const { ID } = UserInfoContainer.useContainer();
  const [solutions, setSolutions] = useState([]);
  const {
    status,
    data,
    error,
    //  isFetching,
    isFetchingMore,
    fetchMore,
    canFetchMore
  } = useInfiniteQuery(
    'userSolutionsKey',
    async (key, page = 0) => {
      const { response } = await request({
        method: 'GET',
        endpoint: `${api.endpoints.userSolutions.findAll({
          userId: ID
        })}?page=${page}`
      });
      return response;
    },
    {
      refetchOnWindowFocus: false,
      getFetchMore: lastGroup => {
        return lastGroup.next_page_url ? lastGroup.current_page + 1 : false;
      }
    }
  );

  // Fetch all data upfront
  useEffect(() => {
    if (canFetchMore && !isFetchingMore) {
      fetchMore();
    }
  }, [canFetchMore, fetchMore, isFetchingMore]);

  // const data = [undefined];
  useDeepCompareEffect(() => {
    if (data) {
      const newSolutions = data
        .map(pageData => {
          return get(pageData, 'data', []).map(solution => ({
            id: solution.id,
            title: solution.name,
            type: 'video',
            imageURL: dotPngToSvg(solution.thumbnail || ''),
            imageAltText: solution.name,
            progress: (solution.watched_videos / solution.total_videos) * 100 || 0,
            link: `${ROUTE_PATHS.solutions}/${solution.id}`,
            isSolution: true
          }));
        })
        .flat(1);
      setSolutions(newSolutions);
    }
  }, [data]);

  return {
    solutions,
    status,
    error
    //  fetchMore,
    //  canFetchMore,
    //  isFetchingMore,
    //  isFetching
  };
};

const UserSolutionsContainer = createContainer(useUserSolutions);

export default UserSolutionsContainer;
