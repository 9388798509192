/** @jsx jsx */
import { useRef } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';

import getMonthFromTimestamp from 'utils/getMonthFromTimestamp';
import { css, jsx } from '@emotion/core';
import { mixins, misc, spacer } from '@styles/utilities';
import { notificationDot } from 'components/NotificationBox/NotificationBox.styles';

const NotificationItem = ({
  toggleNotificationRead,
  id,
  text,
  link,
  linkText,
  markedAsRead,
  createdAt
}) => {
  const month = `#${getMonthFromTimestamp(createdAt)}-${new Date(createdAt).getFullYear()}`;
  const notificationItem = useRef(null);
  return (
    <div
      key={id}
      className={`notification-main-row is-relative ${markedAsRead ? '' : 'new-notification'}`}
      data-notification-month={month}
    >
      <p className="title">{text} </p>
      <p css={spacer.mrT5}>
        <a
          href={link}
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => toggleNotificationRead({ notificationId: id, value: +!markedAsRead })}
        >
          {linkText}
        </a>
      </p>
      <p className="d-flex timestamp mr-bt-0">{dayjs(createdAt).format('D MMM')}</p>
      {!markedAsRead && (
        <div
          onClick={() => toggleNotificationRead({ notificationId: id, value: +!markedAsRead })}
          aria-label="Mark as read"
          title="Mark as read"
          role="button"
          tabIndex={0}
          onKeyDown={() => notificationItem.current && notificationItem.current.focus()}
          ref={notificationItem}
          css={css`
            ${notificationDot({ markedAsRead })}
            margin-top: 5px;
            ${mixins.respondTo(misc.viewport['c-vp'])} {
              right: 0px;
              top: 15px;
              top: 30px;
              margin-top: 0;
            }
          `}
        >
          Mark as Read
        </div>
      )}
    </div>
  );
};

NotificationItem.propTypes = {
  id: PropTypes.number.isRequired,
  text: PropTypes.string.isRequired,
  markedAsRead: PropTypes.number.isRequired,
  link: PropTypes.string.isRequired,
  linkText: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
  toggleNotificationRead: PropTypes.func.isRequired
};

NotificationItem.defaultProps = {};

export default NotificationItem;
