/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import Modal from 'components/Modal/Modal';
import H1 from 'components/htmlElements/H1';
import Button from 'components/htmlElements/Button';
import notice from 'images/notice.svg';
import H3 from 'components/htmlElements/H3';
import { spacer, colors } from '@styles/utilities';
import PropTypes from 'prop-types';
import A from 'components/htmlElements/A';
import { site } from 'globals/constants';

const AccountSharingNoticeModal = ({ isOpen, toggle }) => (
  <Modal isOpen={isOpen} toggle={toggle}>
    <div
      css={css`
        text-align: center;
      `}
    >
      <img css={spacer.mrB10} src={notice} alt="notice" width="100px" />
      <H1 css={spacer.mrB10}>You are logged in on another device.</H1>
      <H3 css={spacer.mrB10} book>
        Please log out of all other devices.
      </H3>
      <H3 css={spacer.mrB20} book>
        Not you?{' '}
        <A color={colors.lightblue} href={`${site.url}/contact/`} target="_blank">
          Contact Support
        </A>
      </H3>
      <Button onClick={toggle}>Close</Button>
    </div>
  </Modal>
);

AccountSharingNoticeModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired
};

export default AccountSharingNoticeModal;
