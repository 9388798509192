/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';
import PropTypes from 'prop-types';

import H3 from 'components/htmlElements/H3';
import { Link } from 'components/Router';
import { ROUTE_PATHS } from 'globals/constants';
import StyledNavLink from 'components/htmlElements/StyledNavLink';
import { useWelcomeTourModalContext } from 'components/WelcomeTourModal/WelcomeTourModalContext';

import { spacer } from '@styles/utilities';
import { useLogout } from 'api/auth';
import * as styles from './ProfileBox.styles';

const ProfileBox = React.forwardRef(({ position }, ref) => {
  const logout = useLogout();
  const { showWelcomeTourModal } = useWelcomeTourModalContext();

  return (
    <ul
      css={styles.profileBoxWrapper}
      className="profile-box-wrapper active"
      ref={ref}
      style={{ top: position.top, left: position.left }}
    >
      <li>
        <Link as={H3} css={spacer.mrBT0} to={ROUTE_PATHS.home}>
          Dashboard
        </Link>
      </li>
      <li>
        <Link as={H3} css={spacer.mrBT0} to={ROUTE_PATHS.profile} className="profile-box-link">
          Change Password
        </Link>
      </li>
      <li>
        <StyledNavLink
          onClick={() => showWelcomeTourModal()}
          as={H3}
          css={spacer.mrBT0}
          to={ROUTE_PATHS.home}
          className="profile-box-link welcome-tour-help-button"
        >
          Help
        </StyledNavLink>
      </li>
      <li>
        <StyledNavLink
          as="span"
          className="profile-box-link"
          onClick={e => {
            e.preventDefault();
            logout();
          }}
        >
          Logout
        </StyledNavLink>
      </li>
    </ul>
  );
});

ProfileBox.propTypes = {
  position: PropTypes.object.isRequired
};

export default ProfileBox;
