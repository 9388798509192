/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import PropTypes from 'prop-types';
import { colors, spacer } from '@styles/utilities';
import H5 from 'components/htmlElements/H5';
import H2 from 'components/htmlElements/H2';
import { slideContainerStyles } from './WelcomeTourModal.styles';

const Slide = ({ gifURL, title, subtitle }) => (
  <div css={slideContainerStyles}>
    <div
      css={css`
        border-bottom: 1px solid ${colors.black};
      `}
    >
      <img src={gifURL} width="100%" alt="gif" />
    </div>
    <div
      css={[
        spacer.padL80,
        spacer.padR80,
        spacer.padT30,
        spacer.padB10,
        css`
          margin-top: auto;
          margin-bottom: auto;
        `
      ]}
    >
      <H2>{title}</H2>
      <H5>{subtitle}</H5>
    </div>
  </div>
);

Slide.propTypes = {
  gifURL: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired
};

export default Slide;
