// Array.flat polyfill
import '../node_modules/core-js/es/array/flat';

import 'utils/logging';

import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { hot } from 'react-hot-loader/root';
import { Global } from '@emotion/core';

import './styles/app.scss';

import Navigation, { NavigationGlobalsContainer } from 'components/Navigation';
import Footer from 'components/Footer';
import Routes from 'globals/routes';
import { Location } from 'components/Router';
import { ROUTE_PATHS } from 'globals/constants';

import { ReactQueryDevtools } from 'react-query-devtools';
import { UserInfoContainer } from 'api/auth';

import UserRecommendedProductsContainer from 'api/userRecommendedProducts';
import Recommendations from 'components/Recommendations';
import WelcomeTour from 'components/WelcomeTourModal';
import WelcomeTourModalProvider from 'components/WelcomeTourModal/WelcomeTourModalContext';

import { globals } from '@styles';
import AudioPlayer from 'components/AudioPlayer/AudioPlayer';
import AudioPlayerProvider from 'components/AudioPlayer/AudioPlayerContext';

const App = () => {
  const user = UserInfoContainer.useContainer();

  const [userIDSet, isUserIDSet] = useState(false);
  useEffect(() => {
    if (user.ID) {
      isUserIDSet(true);
    } else {
      isUserIDSet(false);
    }
  }, [user.ID]);

  return (
    <Fragment>
      <Global styles={globals} />
      {user && user.token && user.accepted_terms ? <Navigation /> : ''}
      <Routes />
      {userIDSet && (
        <UserRecommendedProductsContainer.Provider>
          <WithRecommendationsSection userId={user.ID} />
        </UserRecommendedProductsContainer.Provider>
      )}
      <ReactQueryDevtools initialIsOpen={false} />
      <AudioPlayer />
      <Footer />
      {userIDSet && user.accepted_terms ? <WelcomeTour /> : ''}
    </Fragment>
  );
};

const WithUserContainer = () => (
  <UserInfoContainer.Provider>
    <NavigationGlobalsContainer.Provider>
      <WelcomeTourModalProvider>
        <AudioPlayerProvider>
          <App />
        </AudioPlayerProvider>
      </WelcomeTourModalProvider>
    </NavigationGlobalsContainer.Provider>
  </UserInfoContainer.Provider>
);

const WithRecommendationsSection = ({ userId }) => {
  const { recommendedProducts } = UserRecommendedProductsContainer.useContainer();
  return (
    <Location>
      {({ location }) => {
        if (
          [
            ROUTE_PATHS.home,
            ROUTE_PATHS.courses,
            ROUTE_PATHS.mocks,
            ROUTE_PATHS.markSchemes,
            ROUTE_PATHS.papers,
            ROUTE_PATHS.solutions
          ].includes(location.pathname)
        ) {
          return (
            <Fragment>
              {recommendedProducts.length > 0 && userId && (
                <Recommendations products={recommendedProducts} />
              )}
            </Fragment>
          );
        }
        return <Fragment />;
      }}
    </Location>
  );
};

WithRecommendationsSection.propTypes = {
  userId: PropTypes.number
};

WithRecommendationsSection.defaultProps = {
  userId: NaN
};

export default hot(WithUserContainer);
