/** @jsx jsx */
import { Fragment, useEffect, useMemo, useState } from 'react';
import { jsx } from '@emotion/core';
import ProductCard from 'components/ProductCard/ProductCard';
import SectionTitle from 'components/SectionTitle/SectionTitle';
import PropTypes from 'prop-types';
import FiltersRow, { useFiltersRow } from 'components/FiltersRow/FiltersRow';
// import { Waypoint } from 'react-waypoint';
import NoProducts from 'components/NoProducts/NoProducts';
import { eCommerceSiteLinks } from 'globals/constants';
import Modal from 'components/Modal/Modal';
import { PDFDownload } from 'components/Forms/Forms';
// import PageLoader from 'components/PageLoader/PageLoader';

import useHandlePDFProductClick from 'hooks/useHandlePDFProductClick';

import { spacer } from '@styles/utilities';
import SearchFilter from 'components/SearchFilter/SearchFilter';
import Button from 'components/htmlElements/Button';
import H3 from 'components/htmlElements/H3';
import { Row } from 'components/Grid/Grid';
import fuzzySearch from 'utils/fuzzySearch';

const PapersPage = ({
  papers
  // canFetchMore,
  // fetchMore,
  // isFetchingMore,
  // markSchemesCanFetchMore,
  // markSchemesFetchMore,
  // markSchemesIsFetchingMore
}) => {
  const { filters, activeFilter, onFilterClick } = useFiltersRow({ products: papers });
  const [searchFilteredPapers, setSearchFilteredPapers] = useState([]);
  const [hasLoaded, setHasLoaded] = useState(false);
  const filteredPapers = useMemo(
    () =>
      papers.filter(({ categories }) => {
        if (!activeFilter) return true;
        return categories.findIndex(el => el.slug === activeFilter) !== -1;
      }),
    [activeFilter, papers]
  );

  const {
    clickedItem,
    handleProductCardClick,
    papersPDFList,
    markSchemesPDFList,
    isOpen,
    toggle,
    closeModal
  } = useHandlePDFProductClick();

  useEffect(() => {
    setSearchFilteredPapers(filteredPapers);
    setHasLoaded(filteredPapers?.length > 0);
  }, [filteredPapers]);

  const searchHandler = e => {
    const searchQuery = e.target.value.toLowerCase();

    if (searchQuery.trim().length > 0) {
      const results = fuzzySearch({ query: searchQuery, items: filteredPapers });
      setSearchFilteredPapers(results.map(result => result.item));
    } else {
      setSearchFilteredPapers(filteredPapers);
    }
  };

  const resetSearch = () => {
    setSearchFilteredPapers(filteredPapers);
    document.getElementById('search-input').value = '';
  };

  return (
    <Fragment>
      <div className="container no-push-container" css={spacer.mrBT40}>
        <div className="row">
          <div className="col-12">
            <div css={spacer.padLR30}>
              {!papers.length ? (
                <NoProducts productName="Papers" linkToBuy={eCommerceSiteLinks.papers} />
              ) : (
                <Fragment>
                  <SectionTitle title="Your Papers" />
                  <div className="d-flex justify-content-between align-items-start flex-wrap">
                    <FiltersRow
                      activeFilter={activeFilter}
                      filters={filters}
                      onFilterClick={onFilterClick}
                    />

                    <SearchFilter searchHandler={searchHandler} />
                  </div>
                </Fragment>
              )}
            </div>
          </div>
        </div>
      </div>

      {papers?.filter(paper => paper).length > 0 && hasLoaded && (
        <div className="container no-push-container" css={spacer.mrBT40}>
          <Row>
            {searchFilteredPapers.length > 0 ? (
              searchFilteredPapers.map(paper => (
                <div className="col-6 col-md-4 col-xl-3" key={paper.id} css={[spacer.padB80]}>
                  <ProductCard
                    {...paper}
                    onClick={() => {
                      handleProductCardClick(paper);
                    }}
                  />
                </div>
              ))
            ) : (
              <div className="m-auto" css={spacer.padB30}>
                <H3 css={spacer.mrB20}>No results found.</H3>
                <Button onClick={resetSearch}>Clear Search</Button>
              </div>
            )}
          </Row>

          {/* <Waypoint
          bottomOffset="-40%"
          onEnter={() => {
            if (canFetchMore) {
              fetchMore();
            }
            if (markSchemesCanFetchMore) {
              markSchemesFetchMore();
            }
          }}
        />
        {isFetchingMore || markSchemesIsFetchingMore ? (
          <PageLoader height="100px" isRelative />
        ) : (
          ''
        )} */}
        </div>
      )}

      <Modal isOpen={isOpen} toggle={toggle}>
        <PDFDownload
          papersPDFList={papersPDFList}
          markSchemesPDFList={markSchemesPDFList}
          productId={clickedItem.id}
          isPaper={clickedItem.isPaper}
          isMarkSchemes={clickedItem.isMarkSchemes}
          closeModal={closeModal}
        />
      </Modal>
    </Fragment>
  );
};

PapersPage.propTypes = {
  papers: PropTypes.array.isRequired,
  fetchMore: PropTypes.func,
  // canFetchMore can be number or boolean, number if more pages are available, boolean `false` if no next page available
  canFetchMore: PropTypes.any,
  isFetchingMore: PropTypes.bool,

  markSchemesFetchMore: PropTypes.func,
  markSchemesCanFetchMore: PropTypes.any,
  markSchemesIsFetchingMore: PropTypes.bool
};
PapersPage.defaultProps = {
  fetchMore: () => {},
  canFetchMore: false,
  isFetchingMore: false,

  markSchemesFetchMore: () => {},
  markSchemesCanFetchMore: false,
  markSchemesIsFetchingMore: false
};

export default PapersPage;
