import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import PieChart from 'react-simple-pie-chart';
import Icon from 'components/Icon';

import { colors } from '@styles/utilities';

const CourseSectionHeading = props => {
  const progressPercentage = () => {
    return Math.abs(props.lessonsWatched / props.totalLessons) * 100;
  };

  return (
    <div className="section-heading-wrapper">
      {!props.hidePiechart ? (
        <div className="progress-piechart-wrapper">
          <div
            className={`progress-piechart ${
              progressPercentage() === 100 ? 'section-complete' : ''
            }`}
          >
            <PieChart
              slices={[
                {
                  color: colors['white-transparent'],
                  value: 100 - progressPercentage()
                },
                {
                  color: colors.primaryAnchorDefaultColor,
                  value: progressPercentage()
                }
              ]}
            />
          </div>
        </div>
      ) : null}
      <div className="section-contents">
        <h3 className="section-title">{props.title}</h3>
        <p className="section-meta">
          {props.lessonsWatched} out of {props.totalLessons} watched |{' '}
          {props.hideTotalDuration ? '' : `${props.totalLessonsDuration}`}
          {props.totalBookmarks ? (
            <Fragment>
              {' | '}
              <Icon icon="bookmark" color="rgba(0,0,0,0.3)" /> {props.totalBookmarks}
            </Fragment>
          ) : (
            ''
          )}
        </p>
      </div>
      <div className="chevron-wrapper">
        <span className="accordion-chevron" />
      </div>
    </div>
  );
};

CourseSectionHeading.propTypes = {
  lessonsWatched: PropTypes.number.isRequired,
  totalBookmarks: PropTypes.number,
  totalLessons: PropTypes.number.isRequired,
  totalLessonsDuration: PropTypes.string,
  title: PropTypes.string.isRequired,
  hideTotalDuration: PropTypes.bool,
  hidePiechart: PropTypes.bool
};

CourseSectionHeading.defaultProps = {
  hideTotalDuration: false,
  hidePiechart: false,
  totalLessonsDuration: '',
  totalBookmarks: undefined
};

export default CourseSectionHeading;
