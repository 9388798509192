import { useState, useEffect, useCallback } from 'react';
import { useMutation, useQuery } from 'react-query';
import request from 'utils/request';
import { createContainer } from 'unstated-next';

import { api } from 'globals/constants';
import store from 'store';
import { isEqual } from 'lodash-es';

import LogRocket from 'logrocket';

const useUserInfo = () => {
  const initialState = () => store.get('user') || {};
  const [userInfo, updateUserInfo] = useState(initialState);

  useEffect(() => {
    store.set('user', userInfo);
  }, [userInfo]);

  const setUserInfo = useCallback(
    updatedUserInfo => {
      if (!isEqual(userInfo, updatedUserInfo)) {
        updateUserInfo(updatedUserInfo);
      }
    },
    [userInfo]
  );

  return { ...userInfo, setUserInfo };
};

export const UserInfoContainer = createContainer(useUserInfo);

async function loginWP({ email, password }) {
  const formdata = new FormData();
  formdata.append('email', email);
  formdata.append('password', password);

  const requestOptions = {
    method: 'POST',
    body: formdata,
    credentials: 'include'
  };

  const fetchResp = await fetch(`${api.endpoints.loginWP}`, requestOptions)
    .then(response => response.text())
    .then(result => result)
    .catch(error_ => {
      throw new Error('error', error_);
    });

  return fetchResp;
}
const useLogin = () => {
  const { setUserInfo } = UserInfoContainer.useContainer();
  const [login, { data, error, status }] = useMutation(
    async ({ user, password }) => {
      const { response } = await request({
        method: 'POST',
        endpoint: api.endpoints.authenticateWithUserCredentials,
        payload: { user, password }
      });

      // Login to WP
      loginWP({ email: user, password });

      return response;
    },
    {
      onSuccess(res) {
        // console.info(res.errors);
        if (!res.errors) {
          setUserInfo({
            token: res.token,
            ...res.user
          });

          // Set Logrocket info
          LogRocket.identify(res.user.ID, {
            name: `${res.user.billing.items.first_name} ${res.user.billing.items.last_name}`,
            email: res.user.user_email
          });
        }
      },
      onError(err) {
        throw new Error(err);
      }
    }
  );
  return { login, data, error, status };
};

export const useValidateToken = () => {
  const { setUserInfo, token } = UserInfoContainer.useContainer();

  const { data, error, status, refetch } = useQuery(
    'validateToken',
    async () => {
      const { response } = await request({
        method: 'POST',
        endpoint: api.endpoints.authenticateToken
      });
      return response;
    },
    {
      manual: true,
      onSuccess(res) {
        if (!res.error) {
          setUserInfo({
            token,
            ...res
          });
        }
      }
    }
  );
  return { validateToken: refetch, data, error, status };
};

// async function logoutWP() {
//   await request({
//     method: 'POST',
//     endpoint: api.endpoints.logout
//   });
// }
export const useLogout = () => {
  const { setUserInfo } = UserInfoContainer.useContainer();
  const logout = useCallback(() => {
    // // Logout from WP
    // logoutWP();

    setUserInfo({});
  }, [setUserInfo]);
  return logout;
};

export const useForgottenPassword = () => {
  const [forgotPasswordMutation, { data, error, status }] = useMutation(
    async ({ email }) => {
      const { response } = await request({
        method: 'POST',
        endpoint: api.endpoints.requestResetPasswordEmail,
        payload: {
          email
        }
      });
      return response;
    },
    {
      onSuccess(res) {
        console.info(res);
      }
    }
  );
  return { forgotPasswordMutation, data, error, status };
};

export const useResetPassword = () => {
  const [resetPasswordMutation, { data, error, status }] = useMutation(
    async ({ key, login, pass_1, pass_2 }) => {
      const { response } = await request({
        method: 'POST',
        endpoint: api.endpoints.resetPasswordEmail,
        payload: {
          key,
          login,
          pass_1,
          pass_2
        }
      });
      return response;
    },
    {
      onSuccess(res) {
        console.info(res);
      }
    }
  );
  return { resetPasswordMutation, data, error, status };
};

export default useLogin;
