import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';
import * as Sentry from '@sentry/browser';
import { sentryDSN } from 'globals/constants';

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({ dsn: sentryDSN, environment: process.env.NODE_ENV });
  LogRocket.init('rhtblv/epp-dashboards');
  setupLogRocketReact(LogRocket);
}
