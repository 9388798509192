/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { UnstyledLink } from 'components/Router';
import Icon from 'components/Icon';
import { setQueryParams } from 'hooks/useLocationQueryString';
import fscreen from 'fscreen';
import VideoPlayerStateContainer from 'hooks/useVideoPlayerState';
import H4 from 'components/htmlElements/H4';

import { colors } from '@styles/utilities';

const VideoControls = props => {
  const [isFullscreenSupported] = useState(fscreen.fullscreenEnabled);
  const [isFullscreen, setFullscreen] = useState(false);

  const {
    videoPlayerState: { videoWrapperRef, isSidebarOpen },
    toggleSidebarVisibility
  } = VideoPlayerStateContainer.useContainer();

  const fullscreenClickEvent = event => {
    event.preventDefault();

    if (!isFullscreen) {
      // Enter fullscreen mode
      fscreen.requestFullscreen(videoWrapperRef.current);
      setFullscreen(true);
    } else {
      // Exit fullscreen mode
      fscreen.exitFullscreen();
      setFullscreen(false);
    }
  };

  useEffect(() => {
    fscreen.onfullscreenchange = () => {
      if (fscreen.fullscreenElement === null) {
        setFullscreen(false);
      } else {
        // Exit fullscreen if it's not our custom fullscreen
        if (fscreen.fullscreenElement !== videoWrapperRef.current) {
          fscreen.exitFullscreen();
        }
        setFullscreen(true);
      }
    };
  }, [videoWrapperRef]);

  return (
    <ul className="video-controls">
      <li>
        <H4
          as={UnstyledLink}
          color="primaryAnchorDefaultColor"
          book
          to={`?${setQueryParams({
            sectionId: props.prevLesson && props.prevLesson.sectionId,
            lessonId: props.prevLesson && props.prevLesson.id,
            currentLessonTime: 0
          })}`}
          className="video-control"
        >
          <Icon icon="prev" color={colors.primaryAnchorDefaultColor} size="0.8em" />
          <span className="video-control-text">Previous</span>
        </H4>
      </li>
      <li>
        <H4
          as={UnstyledLink}
          color="primaryAnchorDefaultColor"
          book
          to={`?${setQueryParams({
            sectionId: props.nextLesson && props.nextLesson.sectionId,
            lessonId: props.nextLesson && props.nextLesson.id,
            currentLessonTime: 0
          })}`}
          className="video-control"
        >
          <span className="video-control-text">Next</span>
          <Icon icon="next" color={colors.primaryAnchorDefaultColor} size="0.8em" />
        </H4>
      </li>
      {isFullscreenSupported && (
        <li>
          <H4
            as="a"
            color="primaryAnchorDefaultColor"
            book
            href="#"
            onClick={fullscreenClickEvent}
            className="video-control"
          >
            <Icon
              icon={`${isFullscreen ? 'collapse' : 'expand'}`}
              color={colors.primaryAnchorDefaultColor}
              size="0.8em"
            />
            <span className="video-control-text">{isFullscreen ? 'Exit' : 'Enter'} Fullscreen</span>
          </H4>
        </li>
      )}
      {!props.sidebarDisabled && (
        <li>
          <H4
            as="a"
            color="primaryAnchorDefaultColor"
            book
            href="#"
            onClick={() => toggleSidebarVisibility(!isSidebarOpen)}
            className="video-control"
          >
            <Icon icon="menu-toggle" color={colors.primaryAnchorDefaultColor} size="0.8em" />
            <span className="video-control-text">{isSidebarOpen ? 'Hide' : 'Show'} bookmarks</span>
          </H4>
        </li>
      )}
    </ul>
  );
};

VideoControls.propTypes = {
  prevLesson: PropTypes.object,
  nextLesson: PropTypes.object,
  sidebarDisabled: PropTypes.bool.isRequired
};

VideoControls.defaultProps = {
  prevLesson: null,
  nextLesson: null
};

export default VideoControls;
