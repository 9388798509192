import React from 'react';

import UserMocksContainer from 'api/userMocks';
import Layout from 'layouts/';
import PageLoader from 'components/PageLoader/PageLoader';
import { Redirect } from '@reach/router';
import { ROUTE_PATHS } from 'globals/constants';
import MocksPage from './page';

const MocksContainer = () => {
  const {
    mocks,
    error,
    status,
    canFetchMore,
    fetchMore,
    isFetchingMore
  } = UserMocksContainer.useContainer();
  if (error) {
    return <Redirect to={ROUTE_PATHS.notFound} />;
  }

  return (
    <Layout>
      {status !== 'success' && <PageLoader isFetching isRelative atPageHeight />}
      <MocksPage
        mocks={mocks}
        canFetchMore={canFetchMore}
        fetchMore={fetchMore}
        isFetchingMore={isFetchingMore}
      />
    </Layout>
  );
};

export default () => (
  <UserMocksContainer.Provider>
    <MocksContainer />
  </UserMocksContainer.Provider>
);
