/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import Layout from 'layouts';
import H1 from 'components/htmlElements/H1';
import H2 from 'components/htmlElements/H2';
import Button from 'components/htmlElements/Button';

import { spacer } from '@styles/utilities';
import notFoundImage from 'images/not-found.png';

const styles = {
  container: css`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  `,
  image: css`
    width: 100%;
    max-width: 250px;
    margin-bottom: 30px;
  `
};

export default () => (
  <Layout>
    <div css={[styles.container, spacer.padBT80]}>
      <div className="container">
        <div className="row">
          <div className="col-12 txt-center">
            <img src={notFoundImage} alt="notice" css={styles.image} />
            <H1>Oh No!</H1>
            <H2 as="p" css={spacer.mrB20}>
              The page you are looking <br />
              for has moved or doesn’t exist.
            </H2>
            <Button as="a" large href="/">
              Go Home
            </Button>
          </div>
        </div>
      </div>
    </div>
  </Layout>
);
