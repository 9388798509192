import { useState, useCallback } from 'react';
import { createContainer } from 'unstated-next';

const initialLessonDetails = {
  courseId: undefined,
  sectionId: undefined,
  lessonId: undefined,
  currentLessonTime: 0,
  vimeoLink: '',
  lessonName: '',
  sectionName: '',
  watched: undefined
};

const defaultState = {
  providerFor: '',
  sidebarDisabled: false,
  isExpanded: false,
  isSidebarOpen: false,
  isFullscreen: false,
  hideVideoOverlay: false,
  isVideoPlaying: false,
  currentLesson: initialLessonDetails,
  lessonTimeInfinity: 0,
  videoWrapperRef: null
};

const useVideoPlayerState = (initialState = {}) => {
  const mergedState = { ...defaultState, ...initialState };

  const [videoPlayerState, setVideoPlayerState] = useState(mergedState);

  const toggleExpansion = useCallback(
    isExpandedBool =>
      setVideoPlayerState(prevState => ({
        ...prevState,
        isExpanded: isExpandedBool
      })),
    []
  );

  const toggleSidebarVisibility = useCallback(
    isSidebarOpenBool =>
      setVideoPlayerState(prevState => ({
        ...prevState,
        isSidebarOpen: isSidebarOpenBool
      })),
    []
  );

  const toggleFullscreen = useCallback(
    isFullscreenBool =>
      setVideoPlayerState(prevState => ({
        ...prevState,
        isFullscreen: isFullscreenBool
      })),
    []
  );

  const toggleVideoOverlay = useCallback(
    hideVideoOverlayBool =>
      setVideoPlayerState(prevState => ({
        ...prevState,
        hideVideoOverlay: hideVideoOverlayBool
      })),
    []
  );

  const toggleVideoPlaying = useCallback(
    videoPlayingBool =>
      setVideoPlayerState(prevState => ({
        ...prevState,
        isVideoPlaying: videoPlayingBool
      })),
    []
  );

  const setCurrentLessonDetails = useCallback(
    currentLessonObject =>
      setVideoPlayerState(prevState => ({
        ...prevState,
        currentLesson: { ...prevState.currentLesson, ...currentLessonObject }
      })),
    []
  );

  const updateLessonTimeContinually = useCallback(
    lessonTimeInfinity =>
      setVideoPlayerState(prevState => ({
        ...prevState,
        lessonTimeInfinity
      })),
    []
  );

  const setVideoWrapperRef = useCallback(
    videoWrapperRef =>
      setVideoPlayerState(prevState => ({
        ...prevState,
        videoWrapperRef
      })),
    []
  );

  return {
    videoPlayerState,
    setVideoPlayerState,
    toggleExpansion,
    toggleSidebarVisibility,
    toggleFullscreen,
    toggleVideoPlaying,
    toggleVideoOverlay,
    setCurrentLessonDetails,
    updateLessonTimeContinually,
    setVideoWrapperRef
  };
};

const VideoPlayerStateContainer = createContainer(useVideoPlayerState);

// export const useSetCurrentLessonDetails = () => {
//   // const { videoPlayerState, setVideoPlayerState } = VideoPlayerStateContainer.useContainer();
//   const [currentLessonState, updateCurrentLessonState] = useState(initialLessonDetails);

//   const setCurrentLessonDetails = useCallback(
//     currentLessonObject =>
//       updateCurrentLessonState(prevLessonState => ({ ...prevLessonState, ...currentLessonObject })),
//     []
//   );

//   return { currentLessonState, setCurrentLessonDetails };
// };

export const usePlayVideo = () => {
  const {
    toggleExpansion,
    // toggleSidebarVisibility,
    toggleVideoOverlay,
    toggleVideoPlaying
  } = VideoPlayerStateContainer.useContainer();

  const togglePlayMode = useCallback(
    bool => {
      toggleExpansion(bool);
      // toggleSidebarVisibility(bool);
      toggleVideoOverlay(bool);
      toggleVideoPlaying(bool);
    },
    [toggleExpansion, toggleVideoOverlay, toggleVideoPlaying]
  );

  return togglePlayMode;
};

export default VideoPlayerStateContainer;
