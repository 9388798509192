import React from 'react';
import PageLoader from 'components/PageLoader/PageLoader';
import { Redirect } from 'components/Router';
import Layout from 'layouts';
import { ROUTE_PATHS } from 'globals/constants';
import UserCoursesContainer from 'api/userCourses';
// import { UserInfoContainer } from 'api/auth';
import CoursesPage from './page';

const CourseContainer = () => {
  const {
    courses,
    status,
    error,
    canFetchMore,
    fetchMore,
    isFetchingMore
  } = UserCoursesContainer.useContainer();

  if (error) {
    return <Redirect to={ROUTE_PATHS.notFound} />;
  }

  return (
    <Layout>
      {status !== 'success' && <PageLoader isFetching isRelative atPageHeight />}
      <CoursesPage
        courses={courses}
        canFetchMore={canFetchMore}
        fetchMore={fetchMore}
        isFetchingMore={isFetchingMore}
      />
    </Layout>
  );
};
export default () => (
  <UserCoursesContainer.Provider>
    <CourseContainer />
  </UserCoursesContainer.Provider>
);
