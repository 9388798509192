import React from 'react';

import UserCoursesContainer from 'api/userCourses';
// import UserMocksContainer from 'api/userMocks';
// import useUserLatestOrder from 'api/userOrders';
// import usePromotions from 'api/promotions';
import UserMarkSchemesContainer from 'api/userMarkSchemes';
import UserPapersContainer from 'api/userPapers';
import Layout from 'layouts';
import UserSolutionsContainer from 'api/userSolutions';
import HomePage from './page';

const HomeContainer = () => {
  const papersData = UserPapersContainer.useContainer();
  const solutionsData = UserSolutionsContainer.useContainer();
  const markSchemesData = UserMarkSchemesContainer.useContainer();
  // const promotionsData = usePromotions();
  // const mocksData = UserMocksContainer.useContainer();
  const coursesData = UserCoursesContainer.useContainer();
  // const userLatestOrder = useUserLatestOrder();

  return (
    <Layout>
      <HomePage
        papersData={papersData}
        markSchemesData={markSchemesData}
        solutionsData={solutionsData}
        // mocksData={mocksData}
        coursesData={coursesData}
        // latestOrderData={userLatestOrder}
        // promotionsData={promotionsData}
      />
    </Layout>
  );
};
export default () => (
  <UserPapersContainer.Provider>
    <UserSolutionsContainer.Provider>
      <UserMarkSchemesContainer.Provider>
        <UserCoursesContainer.Provider>
          <HomeContainer />
        </UserCoursesContainer.Provider>
      </UserMarkSchemesContainer.Provider>
    </UserSolutionsContainer.Provider>
  </UserPapersContainer.Provider>
);
