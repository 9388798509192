import misc from '@styles/tokens/misc';
import hexToRgba from 'hex-to-rgba';

export const respondTo = viewport => `
  @media screen and (min-width: ${viewport})
`;

export const respondToMax = viewport => `
  @media screen and (max-width: ${parseInt(viewport) - 1}px)
`;

export const respondToMinMax = (viewportMin, viewportMax) => `
  @media screen and (min-width: ${viewportMin}px) and (max-width: ${parseInt(viewportMax) - 1}px)
`;

export const improveAntiAlias = () => `box-shadow: 0 0 1px rgba(0, 0, 0, 0);`;

export const fontSmooth = () => `
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -moz-osx-font-smoothing: grayscale;
`;

export const fontHacks = () => `
  ${improveAntiAlias()}
  ${fontSmooth()}
`;

export const transition = ({
  duration = misc.transitions.duration,
  properties = misc.transitions.properties,
  easing = misc.transitions.easing,
  delay = misc.transitions.delay
} = {}) => `transition: ${duration} ${properties} ${easing} ${delay};`;

export const linkActiveStyles = () => `&:hover, &:focus, &:active`;

export const horizontalScrollingFlex = ({
  justifyContent = 'flex-start',
  minWidth = '45%'
} = {}) => `
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  justify-content: ${justifyContent};
  > * {
    min-width: ${minWidth};
  }
`;

export const toRgba = (hexColor, opacity) => hexToRgba(hexColor, opacity);

export const vCenter = (width = '100%', alignment = 'middle') => `
  display: table;
  width: ${width};
  > * {
    display: table-cell;
    vertical-align: ${alignment};
    float: none;
  }
`;

export const horizontalScrolling = ({ childElement, width = '45%', minWidth = '0' }) => `
  overflow-x: auto;
  white-space: nowrap;
  overflow-y: hidden;
  ${childElement} {
    white-space: normal;
    float: none;
    display: inline-block;
    vertical-align: top;
    width: ${width};
    min-width: ${minWidth};
  }
`;
