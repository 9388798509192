import { css } from '@emotion/core';
import { spacer, colors, mixins, misc } from '@styles/utilities';

export default {
  imgCtr: css`
    width: 100%;
    ${mixins.respondTo(misc.viewport['d-vp'])} {
      transform-origin: left top;
      position: absolute;
      left: 0;
      width: 100%;
      transform: rotate(90deg) translateY(-60%);
    }
  `,

  img: css`
    position: relative;
    transform: translateX(-50%);
    width: 140%;
    left: 50%;
    ${mixins.respondTo(misc.viewport['d-vp'])} {
      width: 120%;
      left: auto;
      transform: translateX(0);
    }
  `,
  formTitle: css`
    border-bottom: 1px solid ${colors.blackTransparent12};
    ${spacer.mrB40}
  `
};
