import React, { useContext, createContext, useCallback, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useModal } from 'components/Modal';
import { UserInfoContainer } from 'api/auth';
import useWelcomeTourAPI from 'api/welcomeTour';

const WelcomeTourModalContext = createContext();

export const useWelcomeTourModalContext = () => useContext(WelcomeTourModalContext);

const WelcomeTourModalProvider = ({ children }) => {
  const { watched_welcome_tour: watchedWelcomeTour } = UserInfoContainer.useContainer();
  const { welcomeTourWatched } = useWelcomeTourAPI();
  // const { watched_introduction } = user.user;

  const { isOpen, openModal, closeModal } = useModal(false);

  useEffect(() => {
    if (watchedWelcomeTour !== undefined && watchedWelcomeTour === 0) {
      openModal();
    }
  }, [openModal, watchedWelcomeTour]);

  const closeWelcomeTourModal = useCallback(async () => {
    closeModal();
    if (!watchedWelcomeTour) {
      // Call API here
      await welcomeTourWatched();
    }
  }, [watchedWelcomeTour, closeModal, welcomeTourWatched]);
  const showWelcomeTourModal = useCallback(openModal, []);

  const value = useMemo(() => ({ showWelcomeTourModal, closeWelcomeTourModal, isOpen }), [
    showWelcomeTourModal,
    closeWelcomeTourModal,
    isOpen
  ]);

  return (
    <WelcomeTourModalContext.Provider value={value}>{children}</WelcomeTourModalContext.Provider>
  );
};
WelcomeTourModalProvider.propTypes = {
  children: PropTypes.any.isRequired
};
export default WelcomeTourModalProvider;
