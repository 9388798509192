import React from 'react';

import Layout from 'layouts';
import Profile from 'components/Profile/Profile';

export default () => {
  return (
    <Layout>
      <Profile />
    </Layout>
  );
};
