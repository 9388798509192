/** @jsx jsx */
import React from 'react';
import { jsx } from '@emotion/core';
import { ROUTE_PATHS, site } from 'globals/constants';

import { useLogout } from 'api/auth';
import { useWelcomeTourModalContext } from 'components/WelcomeTourModal/WelcomeTourModalContext';

import H2 from 'components/htmlElements/H2';
import StyledNavLink from 'components/htmlElements/StyledNavLink';

// Styling
import { mobileNavWrapper, mobileLinksList } from './Navigation.styles';

import NavLink from './NavLink';

const MobileNavOverlay = React.forwardRef((props, ref) => {
  const logout = useLogout();
  const { showWelcomeTourModal } = useWelcomeTourModalContext();

  return (
    <div ref={ref} css={mobileNavWrapper}>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <ul css={mobileLinksList}>
              <li>
                <H2 as={StyledNavLink} className="mobile-nav-link" href={site.url} target="_blank">
                  Browse Products
                </H2>
              </li>
              <li>
                <H2 as={NavLink} className="mobile-nav-link" to={ROUTE_PATHS.home}>
                  Dashboard
                </H2>
              </li>
              <li>
                <H2 as={NavLink} className="mobile-nav-link" to={ROUTE_PATHS.papers}>
                  Papers
                </H2>
              </li>
              <li>
                <H2 as={NavLink} className="mobile-nav-link" to={ROUTE_PATHS.solutions}>
                  Solutions
                </H2>
              </li>
              <li>
                <H2 as={NavLink} className="mobile-nav-link" to={ROUTE_PATHS.courses}>
                  Masterclasses
                </H2>
              </li>
              <li>
                <H2 as={NavLink} className="mobile-nav-link" to={ROUTE_PATHS.mocks}>
                  Mock exams
                </H2>
              </li>
              <li>
                <H2 as={NavLink} className="mobile-nav-link" to={ROUTE_PATHS.writingService}>
                  Writing
                </H2>
              </li>
              <li>
                <H2 as={NavLink} className="mobile-nav-link" to={ROUTE_PATHS.profile}>
                  Change Password
                </H2>
              </li>
              <li>
                <H2 onClick={showWelcomeTourModal} className="mobile-nav-link">
                  Help
                </H2>
              </li>
              <li>
                <H2 onClick={logout} className="mobile-nav-link">
                  Logout
                </H2>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
});

export default MobileNavOverlay;
