import { css } from '@emotion/core';
import styled from '@emotion/styled';
// import facepaint from 'facepaint';
import { typography, colors, misc } from '@styles/utilities';

// const breakPoints = facepaint([mixins.respondTo(misc.viewport['d-vp'])]);

export const styledP = css`
  font-style: normal;
  margin: ${misc.defaultTextMargin};
  letter-spacing: normal;
`;

const P = styled.p`
  ${styledP}
  font-family: ${typography.fonts.gothamRounded.fontFamily};
  font-weight: ${typography.fonts.gothamRounded.fontWeight.book};
  line-height: 120%;
  color: ${props => (props.color ? colors[props.color] : colors.black)};
  font-size: ${typography.baseSizes.eta};
  strong {
    font-weight: 500
  }
  ${'' /* ${breakPoints({
    fontSize: ['12px', typography.baseSizes.eta]
  })} */}
`;

export default P;
