/* eslint-disable import/prefer-default-export */
import { css } from '@emotion/core';

import { colors, misc } from '@styles/utilities';

export const audioBox = css`
  background-color: ${colors.white};
  border-radius: ${misc.largeBorderRadius};
  border: 1px solid ${colors.darkblue};
  box-shadow: 0 0px 2px 0 rgba(0, 0, 0, 0.3);
  position: fixed;
  bottom: 30px;
  left: 50%;
  transform: translate(-50%, 0);
  width: auto;
`;

export const inner = css`
  flex: 1;
  justify-content: space-between;
`;
