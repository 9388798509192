/** @jsx jsx */
import { useInfiniteQuery } from 'react-query';
import request from 'utils/request';
import { api } from 'globals/constants';
import { createContainer } from 'unstated-next';

import getProductFields from 'utils/getProductFields';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { useState, useEffect } from 'react';
import { UserInfoContainer } from './auth';

const useUserPapers = () => {
  const { ID } = UserInfoContainer.useContainer();
  const [papers, setPapers] = useState([]);
  const {
    status,
    data,
    error,
    // isFetching,
    isFetchingMore,
    fetchMore,
    canFetchMore
  } = useInfiniteQuery(
    'userPapersKey',
    async (key, page = 0) => {
      const { response } = await request({
        method: 'GET',
        endpoint: `${api.endpoints.userPapers.findAll({
          userId: ID
        })}?page=${page}`
      });
      return response;
    },
    {
      refetchOnWindowFocus: false,
      getFetchMore: lastGroup => {
        return lastGroup.next_page_url ? lastGroup.current_page + 1 : false;
      }
    }
  );

  // Fetch all data upfront
  useEffect(() => {
    if (canFetchMore && !isFetchingMore) {
      fetchMore();
    }
  }, [canFetchMore, fetchMore, isFetchingMore]);

  useDeepCompareEffect(() => {
    if (data && data.data !== 'undefined') {
      const newPapers = data
        .map(pageData => {
          if (!pageData.data) {
            return false;
          }

          return pageData.data.map(getProductFields);
        })
        .flat(1);
      setPapers(newPapers);
    }
  }, [data, data.data]);

  return {
    papers,
    status,
    error
    //  fetchMore, canFetchMore, isFetchingMore, isFetching
  };
};

const UserPapersContainer = createContainer(useUserPapers);

export default UserPapersContainer;
