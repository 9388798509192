/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import PropTypes from 'prop-types';
import { spacer, mixins } from '@styles/utilities';
import Input from 'components/htmlElements/Fields/Input';
import { InlineFields } from 'components/htmlElements/Fields/Fields';
// import { get } from 'lodash-es';
import H1 from 'components/htmlElements/H1';
import ProfileHero from 'images/placeholders/placeholder_profile-hero.svg';
import { useFormik } from 'formik';
// import { UserInfoContainer } from 'api/auth';
import Button from 'components/htmlElements/Button';
// import useUpdateUserDetails from 'api/updateUserDetails';
import useUpdatePassword from 'api/updatePassword';
import { useState, useEffect } from 'react';
import H3 from 'components/htmlElements/H3';
// import Select from 'components/Select/Select';
// import countries from 'globals/countries';
import styles from './Profile.styles';
import { passwordFormValidationSchema } from './validationSchema';

const FieldWithLabel = ({ id, label, error, ...rest }) => (
  <div className="input-field-wrapper">
    <Input id={id} {...rest} />

    {error && (
      <label htmlFor={id} className="error">
        {error}
      </label>
    )}
    <label className="placeholder" htmlFor={id}>
      {label}
    </label>
  </div>
);

const Profile = () => {
  // const userInfo = UserInfoContainer.useContainer();
  // const { updateUserDetails } = useUpdateUserDetails();
  const { updatePassword } = useUpdatePassword();
  const [message, setMessage] = useState('');
  // const countryOptions = useMemo(
  //   () =>
  //     Object.entries(countries).map(([key, value]) => ({
  //       value: key,
  //       label: value
  //     })),
  //   []
  // );

  // const billingItems = get(userInfo, 'billing.items', {});
  // const {
  //   handleSubmit: handleBillingFormSubmit,
  //   submitForm: submitBillingForm,
  //   handleChange: handleBillingFormChange,
  //   values: billingFormValues,
  //   errors: billingFormErrors,
  //   touched: billingFormTouched,
  //   setFieldTouched: setBillinFormFieldTouched,
  //   dirty: billingFormDirty,
  //   isValid: isBillingFormValid,
  //   isSubmitting: isBillingFormSubmitting,
  //   setFieldValue
  // } = useFormik({
  //   validateOnBlur: true,
  //   validationSchema: billingDetailsFormValidationSchema,
  //   initialValues: {
  //     first_name: billingItems.first_name || '',
  //     last_name: billingItems.last_name || '',
  //     user_email: userInfo.user_email || billingItems.email || '',
  //     billing_phone: billingItems.phone || '',
  //     billing_address_1: billingItems.address_1 || '',
  //     billing_city: billingItems.city || '',
  //     billing_postcode: billingItems.postcode || '',
  //     billing_country: { value: billingItems.country, label: countries[billingItems.country] }
  //   },
  //   onSubmit: async (formData, actions) => {
  //     actions.setSubmitting(true);

  //     const response = await updateUserDetails({
  //       payload: { ...formData, billing_country: formData.billing_country.value }
  //     });

  //     // this wont be needed once Auth error handling is added
  //     if (response.error) {
  //       setMessage(response.error);
  //     } else if (response.errors) {
  //       actions.setErrors(response.errors);
  //     } else {
  //       setMessage('Details updated.');
  //     }

  //     actions.setSubmitting(false);
  //   }
  // });

  const {
    handleSubmit: handlePasswordFormSubmit,
    submitForm: submitPasswordForm,
    handleChange: handlePasswordFormChange,
    values: passwordFormValues,
    errors: passwordFormErrors,
    touched: passwordFormTouched,
    setFieldTouched: setPasswordFormFieldTouched,
    dirty: passwordFormDirty,
    isSubmitting: isPasswordFormSubmitting,
    isValid: isPasswordFormValid
  } = useFormik({
    validateOnBlur: true,
    validationSchema: passwordFormValidationSchema,
    initialValues: {
      current_password: '',
      password: '',
      password_confirmation: ''
    },
    onSubmit: async (formData, actions) => {
      actions.setSubmitting(true);
      const response = await updatePassword({
        payload: formData
      });

      if (response.error) {
        setMessage(response.error);
      } else if (response.errors) {
        actions.setErrors(response.errors);
      } else {
        setMessage('Details updated.');
      }

      actions.setSubmitting(false);
    }
  });

  // const setBillingFieldTouched = e => setBillinFormFieldTouched(e.target.id, true);
  // const getBillinFormError = field => billingFormTouched[field] && billingFormErrors[field];
  const setPasswordFieldTouched = e => setPasswordFormFieldTouched(e.target.id, true);
  const getPasswordFormError = field => passwordFormTouched[field] && passwordFormErrors[field];

  const isSubmitDisabled = () => {
    // if no form is changed, then disable button
    // if any form is changed and is valid enable button
    // if (isBillingFormValid && billingFormDirty) return false;
    if (isPasswordFormValid && passwordFormDirty) return false;
    return true;
  };
  // console.info({ values, errors, touched });
  useEffect(() => {
    if (message) {
      setTimeout(() => {
        setMessage('');
      }, 10000);
    }
  }, [message]);
  return (
    <section className="h-100 is-overflow-hidden bg-white">
      <div className="container is-relative">
        <div className="row">
          <div className="col-12 order-2" css={styles.imgCtr}>
            <img src={ProfileHero} alt="" css={styles.img} />
          </div>
          <div
            className="col-sm-12 col-md-8 offset-md-4 order-1"
            css={[spacer.mrT80, spacer.mrB120]}
          >
            {/* ----------- Billing Details ------- */}
            {/* <form onSubmit={handleBillingFormSubmit}>
              <H1 css={styles.formTitle}>Billing Details</H1>
              <InlineFields noMargin>
                <FieldWithLabel
                  error={getBillinFormError('first_name')}
                  onBlur={setBillingFieldTouched}
                  value={billingFormValues.first_name}
                  onChange={handleBillingFormChange}
                  label="First Name"
                  id="first_name"
                />
                <FieldWithLabel
                  error={getBillinFormError('last_name')}
                  onBlur={setBillingFieldTouched}
                  value={billingFormValues.last_name}
                  onChange={handleBillingFormChange}
                  label="Last Name"
                  id="last_name"
                />
              </InlineFields>
              <InlineFields noMargin>
                <FieldWithLabel
                  error={getBillinFormError('user_email')}
                  onBlur={setBillingFieldTouched}
                  value={billingFormValues.user_email}
                  onChange={handleBillingFormChange}
                  label="Email Address"
                  id="user_email"
                  readOnly
                  disabled
                />
                <FieldWithLabel
                  error={getBillinFormError('billing_phone')}
                  onBlur={setBillingFieldTouched}
                  value={billingFormValues.billing_phone}
                  onChange={handleBillingFormChange}
                  label="Phone"
                  id="billing_phone"
                />
              </InlineFields>
              <InlineFields noMargin>
                <Select
                  error={getBillinFormError('billing_country')}
                  options={countryOptions}
                  value={billingFormValues.billing_country}
                  onChange={data => setFieldValue('billing_country', data)}
                  label="Country"
                  id="billing_country"
                />
                <FieldWithLabel
                  error={getBillinFormError('billing_address_1')}
                  onBlur={setBillingFieldTouched}
                  value={billingFormValues.billing_address_1}
                  onChange={handleBillingFormChange}
                  label="Address"
                  id="billing_address_1"
                />
              </InlineFields>
              <InlineFields noMargin>
                <FieldWithLabel
                  error={getBillinFormError('billing_city')}
                  onBlur={setBillingFieldTouched}
                  value={billingFormValues.billing_city}
                  onChange={handleBillingFormChange}
                  label="Town/City"
                  id="billing_city"
                />
                <FieldWithLabel
                  error={getBillinFormError('billing_postcode')}
                  onBlur={setBillingFieldTouched}
                  value={billingFormValues.billing_postcode}
                  onChange={handleBillingFormChange}
                  label="Postcode"
                  id="billing_postcode"
                />
              </InlineFields>
            </form> */}

            {/* ---------- Password form ------- */}
            <form onSubmit={handlePasswordFormSubmit}>
              <H1 css={[styles.formTitle]}>Change Password</H1>
              <InlineFields noMargin>
                <FieldWithLabel
                  type="password"
                  error={getPasswordFormError('current_password')}
                  onBlur={setPasswordFieldTouched}
                  value={passwordFormValues.current_password}
                  onChange={handlePasswordFormChange}
                  label="Current Password"
                  id="current_password"
                />
              </InlineFields>
              <InlineFields noMargin>
                <FieldWithLabel
                  type="password"
                  error={getPasswordFormError('password')}
                  onBlur={setPasswordFieldTouched}
                  value={passwordFormValues.password}
                  onChange={handlePasswordFormChange}
                  label="New Password"
                  id="password"
                />
              </InlineFields>
              <InlineFields noMargin>
                <FieldWithLabel
                  type="password"
                  error={getPasswordFormError('password_confirmation')}
                  onBlur={setPasswordFieldTouched}
                  value={passwordFormValues.password_confirmation}
                  onChange={handlePasswordFormChange}
                  label="Confirm new Password"
                  id="password_confirmation"
                />
              </InlineFields>
            </form>

            <div className="text-right">
              <Button
                // loading={isPasswordFormSubmitting || isBillingFormSubmitting}
                loading={isPasswordFormSubmitting}
                disabled={isSubmitDisabled()}
                onClick={() => {
                  // if (billingFormDirty && isBillingFormValid) {
                  //   submitBillingForm();
                  // }
                  if (passwordFormDirty && isPasswordFormValid) {
                    submitPasswordForm();
                  }
                }}
              >
                Save Details
              </Button>
              <H3
                css={css`
                  transform: ${message ? `scaleY(1)` : `scaleY(0)`};
                  transform-origin: top;
                  ${mixins.transition()};
                `}
              >
                {message}
              </H3>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

FieldWithLabel.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  error: PropTypes.string
};

FieldWithLabel.defaultProps = {
  error: ''
};

Profile.propTypes = {};

export default Profile;
