import styled from '@emotion/styled';
import isPropValid from '@emotion/is-prop-valid';
import styledMap from 'styled-map';
import { typography, colors, mixins } from '@styles/utilities';

const A = styled('a', {
  shouldForwardProp: prop => isPropValid(prop)
})`
  font-family: ${typography.fonts.gothamRounded.fontFamily};
  font-weight: ${styledMap`
    insideA: inherit;
    book:  ${typography.fonts.gothamRounded.fontWeight.book};
    default: ${typography.fonts.gothamRounded.fontWeight.medium};
  `};
  line-height: inherit;
  margin: 0;
  padding: 0;
  outline: none;
  cursor: pointer;
  text-decoration: ${styledMap`
    underline: underline;
    default: none;
  `};
  background-color: ${styledMap`
    noBg: transparent;
    default: unset;
  `};
  ${mixins.transition()}
  ${mixins.linkActiveStyles()} {
    outline: none;
  }

  color: ${props => (props.color ? colors[props.color] : colors.primaryAnchorDefaultColor)};
  fill: ${styledMap`
    highlightSvg: ${props =>
      props.color ? colors[props.color] : colors.primaryAnchorDefaultColor};
    default: '';
  `};

  &:hover {
    color: ${colors.primaryLinkHover};
    text-decoration: none;
    fill: ${styledMap`
      highlightSvg: ${colors.primaryLinkHover};
      default: '';
    `};
  }
  &[active='true'],
  &:active {
    color: ${colors.primaryLinkActive};
    fill: ${styledMap`
      highlightSvg: ${colors.primaryLinkActive};
      default: '';
    `};
  }
`;

export default A;
