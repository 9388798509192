/** @jsx jsx */
import { useQuery } from 'react-query';
import request from 'utils/request';
import { api } from 'globals/constants';
import { createContainer } from 'unstated-next';
import getProductFields from 'utils/getProductFields';
import { useState } from 'react';
import { useDeepCompareEffectNoCheck } from 'use-deep-compare-effect';
import { UserInfoContainer } from './auth';

const useRecommendedProducts = () => {
  const { ID } = UserInfoContainer.useContainer();

  const [recommendedProducts, setRecommendedProducts] = useState([]);
  const { data, error, status } = useQuery(
    'userRecommendedProductsKey',
    async () => {
      const { response } = await request({
        method: 'GET',
        endpoint: api.endpoints.userRecommendedProducts.findAll({ userId: ID })
      });
      return response;
    },
    {
      refetchOnWindowFocus: false
    }
  );

  useDeepCompareEffectNoCheck(() => {
    if (data && !data.errors) {
      const newRecommendedProducts = (data || []).map(({ category, products }) => ({
        category,
        products: products.map(product => ({
          ...getProductFields(product),
          isPurchased: false
        }))
      }));
      setRecommendedProducts(newRecommendedProducts);
    }
  }, [data]);
  return { recommendedProducts, error, status };
};

const UserRecommendedProductsContainer = createContainer(useRecommendedProducts);

export default UserRecommendedProductsContainer;
