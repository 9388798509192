/** @jsx jsx */
import React from 'react';
import { jsx, css } from '@emotion/core';
import * as Sentry from '@sentry/browser';
import LogRocket from 'logrocket';

// Components
import { Container, Row, Col } from 'components/Grid';
import H2 from 'components/htmlElements/H2';
import P from 'components/htmlElements/P';
import A from 'components/htmlElements/A';
import Button from 'components/htmlElements/Button';
import store from 'store';

import { bgColor, spacer } from '@styles/utilities';

const errorBoundriesStyles = css`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 900;
  top: 0;
  left: 0;
  .full-height {
    height: 100%;
  }
`;

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: null, eventId: null, user: store.get('user') || {} };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    LogRocket.getSessionURL(sessionURL => {
      Sentry.withScope(scope => {
        scope.setExtras({
          sessionURL
        });
        scope.setExtras(errorInfo);
        if (this.state.user.ID) {
          scope.setExtras({
            name: `${this.state.user.billing.items.first_name} ${this.state.user.billing.items.last_name}`,
            id: this.state.user.ID
          });
          scope.setUser({ email: this.state.user.user_email });
        }

        const eventId = Sentry.captureException(error);
        this.setState({ eventId });
      });
    });
  }

  render() {
    if (this.state.hasError) {
      return (
        <div css={[bgColor.white, errorBoundriesStyles]}>
          <Container className="full-height">
            <Row
              align="center"
              style={{ display: 'flex', alignItems: 'center' }}
              className="text-center full-height"
            >
              <Col>
                <H2 css={spacer.mrB20}>Something went wrong!</H2>
                <Button
                  onClick={() => {
                    window.location.reload();
                  }}
                >
                  Reload
                </Button>
                {this.state.eventId && (
                  <P small>
                    <A
                      insideA
                      href="#"
                      onClick={event => {
                        event.preventDefault();
                        Sentry.showReportDialog({
                          user: {
                            name: `${
                              this.state.user.ID
                                ? `${this.state.user.billing.items.first_name} ${this.state.user.billing.items.last_name}`
                                : ''
                            }`,
                            email: this.state.user.ID ? this.state.user.user_email : ''
                          },
                          eventId: this.state.eventId
                        });
                      }}
                    >
                      Report Issue
                    </A>
                  </P>
                )}
              </Col>
            </Row>
          </Container>
        </div>
      );
    }

    return this.props.children;
  }
}
