/* eslint-disable no-unused-vars */
/** @jsx jsx */
import { jsx, css, keyframes, Global } from '@emotion/core';
import { Fragment } from 'react';
import PropTypes from 'prop-types';
import Loader from 'components/Loader';
import NProgress from 'nprogress';

// Styles
import { misc, colors } from '@styles/utilities';

const spWaveAf = keyframes`
	from { transform: scale(0.5,0.5); opacity: 0; }
  to { transform: scale(1,1); opacity: 1; }
  `;

const spWaveBe = keyframes`
	from { -webkit-transform: scale(1,1); opacity: 1; }
	to { -webkit-transform: scale(1.5,1.5); opacity: 0; }
`;

const waveStyles = css`
  border-radius: 50%;
  position: relative;
  opacity: 1;
  width: 3rem;
  height: 3rem;
  clear: both;
  margin: 20px auto;
  &:before,
  &:after {
    content: '';
    border: 1px solid #004f8d;
    border-radius: 50%;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0px;
  }
  &:before {
    transform: scale(1, 1);
    opacity: 1;
    animation: ${spWaveBe} 0.6s infinite linear;
  }
  &:after {
    transform: scale(0, 0);
    opacity: 0;
    animation: ${spWaveAf} 0.6s infinite linear;
  }
`;

const wrapperStyles = css`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${colors.skyBlue};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const getPageHeight = () => {
  if (typeof window !== 'undefined') {
    return `${window.innerHeight}px`;
  }
  return '';
};

const PageLoader = ({ isFetching, height, isRelative, atPageHeight, bgColor }) => {
  if (isFetching) {
    NProgress.inc();
  } else {
    NProgress.done();
  }

  return (
    <Fragment>
      {atPageHeight && (
        <Global
          styles={css`
            html,
            body {
              overflow: hidden;
            }
          `}
        />
      )}
      <Loader />
      <div
        className={isRelative && 'is-relative'}
        style={{ height: atPageHeight ? getPageHeight() : height }}
      >
        <div
          css={wrapperStyles}
          style={{
            height: '100%',
            zIndex: !isRelative ? misc.modalZindex * 10 : 'auto',
            backgroundColor: bgColor || ''
          }}
        >
          <div css={waveStyles} />
        </div>
      </div>
    </Fragment>
  );
};

PageLoader.propTypes = {
  isFetching: PropTypes.bool,
  atPageHeight: PropTypes.bool,
  isRelative: PropTypes.bool,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  bgColor: PropTypes.string
};

PageLoader.defaultProps = {
  isFetching: false,
  atPageHeight: false,
  isRelative: false,
  height: '',
  bgColor: ''
};

export default PageLoader;
