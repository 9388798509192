import React from 'react';
import PropTypes from 'prop-types';

import NotificationItem from './NotificationItem';

const NotificationList = ({ groupedNotifications, toggleNotificationRead, ...props }) => (
  <div className="notifications-main-list card-space">
    {Object.entries(groupedNotifications).map(([month, notifications]) => {
      return (
        <React.Fragment key={month}>
          <div {...props} id={month}>
            {notifications.map(notification => (
              <NotificationItem
                toggleNotificationRead={toggleNotificationRead}
                key={notification.id}
                {...notification}
              />
            ))}
          </div>
        </React.Fragment>
      );
    })}
  </div>
);

NotificationList.propTypes = {
  /**
   * Shape of Object -
   * {Month:[NotificationsForThatMonth]}
   */
  groupedNotifications: PropTypes.object.isRequired,
  toggleNotificationRead: PropTypes.func
};

NotificationList.defaultProps = {
  toggleNotificationRead: () => {}
};

export default NotificationList;
