const lessonInfo = (sections, videoId) => {
  const parsedVideoId = parseInt(videoId);

  const filteredSection = sections.filter(({ videos }) => {
    return videos.some(video => video.id === parsedVideoId);
  });

  // If there are no results bail out
  if (!filteredSection.length) {
    return '';
  }

  const filteredVideo = filteredSection[0].videos.filter(video => video.id === parsedVideoId);

  return {
    lessonId: filteredVideo[0].id,
    lessonLink: filteredVideo[0].link,
    lessonName: filteredVideo[0].name,
    lessonWatchedStatus: filteredVideo[0].watched,
    sectionName: filteredSection[0].name,
    sectionId: filteredSection[0].id
  };
};

export default lessonInfo;
