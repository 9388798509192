/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
// import Clipboard from 'clipboard';

import {
  useDownloadPaperPDF,
  useDownloadMarkSchemePDF,
  useDownloadWritingServicePDF
} from 'api/downloadPDF';
import { useAudioPlayerContext } from 'components/AudioPlayer/AudioPlayerContext';
import { UserInfoContainer } from 'api/auth';

import H2 from 'components/htmlElements/H2';
import H4 from 'components/htmlElements/H4';
import A from 'components/htmlElements/A';
import Input from 'components/htmlElements/Fields/Input';
import Button from 'components/htmlElements/Button';
// import Icon from 'components/Icon';

import PageLoader from 'components/PageLoader/PageLoader';
import { get } from 'lodash-es';
import { apiErrorCodes, site } from 'globals/constants';

import { spacer } from '@styles/utilities';

const getLinkText = fileExtension => {
  switch (fileExtension) {
    case 'mp3':
      return 'Download Audio';
    default:
      return 'Download PDF';
  }
};

const PDFDownload = ({
  productId,
  isPaper,
  isMarkSchemes,
  isWritingService,
  papersPDFList,
  markSchemesPDFList,
  closeModal
}) => {
  const { email } = UserInfoContainer.useContainer();
  const { setAudioPlayer } = useAudioPlayerContext();

  // const [copied, setCopied] = useState(false);
  // const handleCopy = e => {
  //   e.preventDefault();
  //   if (copied) return;

  //   setCopied(true);
  //   setTimeout(() => {
  //     setCopied(false);
  //   }, 1500);
  // };

  // useEffect(() => {
  //   const embedCopy = new Clipboard('.copy-action');
  //   embedCopy.on('error', () => {
  //     console.error('Sorry! Cannot copy the link');
  //   });
  // }, []);

  const setCurrentAudio = (name, file) => {
    setAudioPlayer(null);
    setAudioPlayer({
      show: true,
      name,
      file
    });
    closeModal();
  };

  const [pdfList, updatePdfList] = useState([]);
  useEffect(() => {
    if (isPaper) {
      updatePdfList(papersPDFList);
    } else if (isMarkSchemes) {
      updatePdfList(markSchemesPDFList);
    } else if (isWritingService) {
      updatePdfList(markSchemesPDFList);
    }
  }, [isPaper, markSchemesPDFList, papersPDFList, isWritingService, isMarkSchemes]);

  const { getPaperPDFLink, paperPDFLinkStatus } = useDownloadPaperPDF();
  const { getMarkSchemePDFLink, markSchemePDFLinkStatus } = useDownloadMarkSchemePDF();
  const { getWritingPDFLink, writingPDFLinkStatus } = useDownloadWritingServicePDF();

  // Generate PDFs on mount
  const [stampedPDFs, updatedStampedPDFs] = useState({});
  const [allPDFsGenerated, toggleAllPDFsGenerated] = useState(false);
  useEffect(() => {
    async function getPDFs() {
      if (pdfList.length) {
        // @ref: https://stackoverflow.com/a/37576787/2623613
        // eslint-disable-next-line no-restricted-syntax
        for (const pdfItem of pdfList) {
          const { id, markSchemeTestFile } = pdfItem;
          let pdfLinkData;

          if (isPaper) {
            // eslint-disable-next-line no-await-in-loop
            pdfLinkData = await getPaperPDFLink({ pdfHash: id, pdfProductId: productId });

            const downloadUrl = get(pdfLinkData, 'download_url', '');
            updatedStampedPDFs(prevState => ({
              ...prevState,
              [id]: downloadUrl
                ? get(pdfLinkData, 'download_url', '')
                : // : pdfLinkData.error_type
                  get(pdfLinkData, 'error_type', '')
            }));
          }
          if (isMarkSchemes) {
            if (markSchemeTestFile) {
              updatedStampedPDFs(prevState => ({
                ...prevState,
                [id]: markSchemeTestFile
              }));
            } else {
              // eslint-disable-next-line no-await-in-loop
              pdfLinkData = await getMarkSchemePDFLink({ pdfHash: id, pdfProductId: productId });

              const downloadUrl = get(pdfLinkData, 'download_url', '');
              updatedStampedPDFs(prevState => ({
                ...prevState,
                [id]: downloadUrl
                  ? get(pdfLinkData, 'download_url', '')
                  : // : pdfLinkData.error_type
                    get(pdfLinkData, 'error_type', '')
              }));
            }
          }

          if (isWritingService) {
            // eslint-disable-next-line no-await-in-loop
            pdfLinkData = await getWritingPDFLink({ pdfHash: id, pdfProductId: productId });

            const downloadUrl = get(pdfLinkData, 'download_url', '');
            updatedStampedPDFs(prevState => ({
              ...prevState,
              [id]: downloadUrl
                ? get(pdfLinkData, 'download_url', '')
                : // : pdfLinkData.error_type
                  get(pdfLinkData, 'error_type', '')
            }));
          }
        }

        // Old approach, was working with Node 10 and when it was on Firebase hosting
        // @ref: https://stackoverflow.com/a/37576787/2623613
        // await Promise.all(
        //   pdfList.map(async ({ id, markSchemeTestFile }) => {
        //     let pdfLinkData;

        //     if (isPaper) {
        //       console.info('id is, ', id);
        //       pdfLinkData = await getPaperPDFLink({ pdfHash: id, pdfProductId: productId });
        //       // console.info(pdfLinkData, id);

        //       const downloadUrl = get(pdfLinkData, 'download_url', '');
        //       updatedStampedPDFs(prevState => ({
        //         ...prevState,
        //         [id]: downloadUrl
        //           ? get(pdfLinkData, 'download_url', '')
        //           : // : pdfLinkData.error_type
        //             get(pdfLinkData, 'error_type', '')
        //       }));
        //     }
        //     // if (isMarkSchemes) {
        //     //   if (markSchemeTestFile) {
        //     //     updatedStampedPDFs(prevState => ({
        //     //       ...prevState,
        //     //       [id]: markSchemeTestFile
        //     //     }));
        //     //   } else {
        //     //     pdfLinkData = await getMarkSchemePDFLink({ pdfHash: id, pdfProductId: productId });

        //     //     const downloadUrl = get(pdfLinkData, 'download_url', '');
        //     //     updatedStampedPDFs(prevState => ({
        //     //       ...prevState,
        //     //       [id]: downloadUrl
        //     //         ? get(pdfLinkData, 'download_url', '')
        //     //         : // : pdfLinkData.error_type
        //     //           get(pdfLinkData, 'error_type', '')
        //     //     }));
        //     //   }
        //     // }
        //   })
        // );

        toggleAllPDFsGenerated(true);
      }
    }

    getPDFs();
  }, [
    getMarkSchemePDFLink,
    getPaperPDFLink,
    isMarkSchemes,
    isPaper,
    pdfList,
    productId,
    getWritingPDFLink,
    isWritingService
  ]);

  return (
    <Fragment>
      {pdfList.length && allPDFsGenerated ? (
        <Fragment>
          <div className="pdf-download-icon txt-center">
            <img src={require('images/download-in-process.svg').default} alt="" />
          </div>
          <section className="clearfix product-pdf-wrapper">
            <H2 className="txt-center" css={spacer.mrB15}>
              The passcode to access the PDF is your email address:
            </H2>
            <div className="input-field-wrapper no-space">
              <H4 className="product-pdf-password-div">
                {/* {Clipboard.isSupported() && ( */}
                <Fragment>
                  <Input
                    type="text"
                    name="product-pdf-password-input"
                    id="product-pdf-password-input"
                    defaultValue={email}
                    readOnly
                  />
                  {/* <A
                      href="#"
                      className="copy-action reverse-a"
                      data-clipboard-target="#product-pdf-password-input"
                      data-copy-text="Copy"
                      data-copied-text="Copied"
                      onClick={handleCopy}
                    >
                      <Icon
                        icon="copy"
                        size="1em"
                        color={colors.primaryAnchorDefaultColor}
                        css={spacer.mrR5}
                      />
                      <span>{copied ? 'Passcode Copied' : 'Copy Passcode'}</span>
                    </A> */}
                </Fragment>
                {/* )} */}
              </H4>
            </div>

            {pdfList.length === 1 ? (
              <Fragment>
                {stampedPDFs[Object.keys(stampedPDFs)[0]] === apiErrorCodes.download_expired ? (
                  <p className="txt-center mr-t-20">
                    This download has expired. Please{' '}
                    <a href={`${site.url}/contact/`} target="_blank" rel="noopener noreferrer">
                      contact us
                    </a>
                    or
                    <a
                      href={`${site.url}?page_id=${productId}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      buy again
                    </a>
                    .
                  </p>
                ) : (
                  <div className="txt-center mr-t-20">
                    <Button
                      as="a"
                      target="_blank"
                      href={stampedPDFs[Object.keys(stampedPDFs)[0]]}
                      loading={
                        paperPDFLinkStatus === 'loading' ||
                        markSchemePDFLinkStatus === 'loading' ||
                        writingPDFLinkStatus === 'loading'
                      }
                    >
                      {getLinkText(pdfList[0].extension)}
                    </Button>
                  </div>
                )}
              </Fragment>
            ) : (
              <div className="mr-t-40">
                {/* If there are multiple links use the below markup */}
                <ul className="multiple-download-links is-relative">
                  {pdfList.map(({ id, name, extension }) => (
                    <li key={id}>
                      <p className="filename">{name}</p>
                      {stampedPDFs[id] !== apiErrorCodes.download_expired ? (
                        <div className="download-link d-flex align-items-center">
                          <A href={stampedPDFs[id]} target="_blank">
                            {getLinkText(extension)}
                          </A>
                          {extension === 'mp3' ? (
                            <A
                              css={spacer.mrL10}
                              href="#"
                              onClick={e => {
                                e.preventDefault();
                                setCurrentAudio(name, stampedPDFs[id]);
                              }}
                            >
                              Play Audio
                            </A>
                          ) : null}
                        </div>
                      ) : (
                        ''
                      )}
                    </li>
                  ))}
                  {paperPDFLinkStatus === 'loading' ||
                  markSchemePDFLinkStatus === 'loading' ||
                  writingPDFLinkStatus === 'loading' ? (
                    <PageLoader bgColor="rgba(255,255,255, 0.3)" />
                  ) : (
                    ''
                  )}
                </ul>

                {Object.keys(stampedPDFs).some(
                  item => stampedPDFs[item] === apiErrorCodes.download_expired
                ) ? (
                  <p className="txt-center mr-t-30">
                    Some downloads have expired. Please{' '}
                    <a href={`${site.url}/contact/`} target="_blank" rel="noopener noreferrer">
                      contact us
                    </a>
                    or
                    <a
                      href={`${site.url}?page_id=${productId}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      buy again
                    </a>
                    .
                  </p>
                ) : (
                  ''
                )}
              </div>
            )}
          </section>
        </Fragment>
      ) : (
        <PageLoader height={400} isRelative bgColor="#ffffff" />
      )}
    </Fragment>
  );
};

PDFDownload.propTypes = {
  productId: PropTypes.number,
  // pdfList: PropTypes.array,
  papersPDFList: PropTypes.array,
  markSchemesPDFList: PropTypes.array,
  isPaper: PropTypes.bool,
  isMarkSchemes: PropTypes.bool,
  isWritingService: PropTypes.bool,
  closeModal: PropTypes.func
};

PDFDownload.defaultProps = {
  productId: undefined,
  // pdfList: [],
  papersPDFList: [],
  markSchemesPDFList: [],
  isPaper: undefined,
  isMarkSchemes: undefined,
  isWritingService: undefined,
  closeModal: null
};

export default PDFDownload;
