/* eslint-disable import/prefer-default-export */
import { css } from '@emotion/core';

import { colors, mixins, misc } from '@styles/utilities';

const layoutChangeBreakpoint = mixins.respondTo(misc.layoutChangeBreakpoint());

export const HAMBURGER_OPEN_CLASS = 'hamburger-active';

export const primaryNavFaux = css`
  height: 55px;
  ${mixins.respondTo(misc.layoutChangeBreakpoint())} {
    height: 88px;
  }
`;

export const navigationStyles = css`
  /* ::Navigation */
  /* ------------------------------------------------------------ */

  top: 0;
  width: 100%;
  ${mixins.transition()};
  border-bottom: ${misc.border.default};
  position: fixed;
  background-color: ${colors.white};
  z-index: ${misc.navZindex};

  .main-nav-container {
    padding-top: 10px;
    padding-bottom: 10px;
    ${mixins.respondTo(misc.layoutChangeBreakpoint())} {
      padding-top: 15px;
      padding-bottom: 15px;
    }
  }

  .nav-down {
    transform: none;
  }
  .nav-up {
    transform: translateY(-100%);
  }

  .logo {
    z-index: ${parseInt(misc.navZindex) + 1};
    img,
    svg {
      width: 90px;
      height: 42.5px;
    }
    ${mixins.respondTo('e-vp')} {
      img,
      svg {
        width: 110px;
        height: 52px;
      }
    }
  }

  .hamburger-active {
    overflow: hidden;
    height: 100%;
    width: 100%;
  }

  .nav-col {
    position: inherit;
  }
  .nav-band {
    width: 100%;
    position: inherit;
    display: flex;
    justify-content: space-between;
    .nav-right {
      text-align: right;
      flex: 1 0 auto;
    }
    html.no-flexbox & {
      ${mixins.vCenter()};
      .nav-right {
        width: 100%;
      }
    }
  }

  .nav-right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .main-nav {
      flex: 1 0 auto;
    }
    ${mixins.respondTo(misc.layoutChangeBreakpoint())} {
      justify-content: space-between;
    }
    html.no-flexbox & {
      ${'' /* @extend %clearfix; */}
      ${mixins.respondTo(misc.layoutChangeBreakpoint())} {
        .main-nav {
          float: left;
        }
        .secondary-nav {
          float: right;
        }
      }
    }
  }
  .main-nav {
    position: fixed;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 99;
    background: ${colors.white};
    text-align: center;
    ${mixins.transition()};
    border-top: ${misc.border.default};
    ${'' /* @extend %clearfix; */}
    .nav-item {
      padding: 10px 0;
    }
    ${mixins.respondTo(misc.layoutChangeBreakpoint())} {
      .nav-item {
        width: auto;
        float: none;
        display: table-cell;
      }
      position: relative;
      text-align: inherit;
      border-top: 0;
      top: 0;
    }
  }

  .secondary-nav {
    margin-left: 10px;
  }
  .secondary-nav-inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .nav-item:not(:last-child) {
      padding-right: 10px;
    }
    .nav-btn {
      vertical-align: middle;
    }
    .notif-btn-icon,
    .cart-btn-icon {
      vertical-align: middle;
    }
    html.no-flexbox & {
      ${mixins.vCenter()};
      .nav-item {
        width: 100%;
        white-space: nowrap;
      }
    }
  }

  .nav-links-container {
    margin: 0;
    list-style-type: none;
    ${mixins.respondTo(misc.layoutChangeBreakpoint())} {
      display: inline-block;
      vertical-align: middle;
      .nav-item:not(:last-child) {
        padding-right: 20px;
      }
    }
    ${mixins.respondTo(misc.viewport['f-vp'])} {
      .nav-item:not(:last-child) {
        padding-right: 30px;
      }
    }
  }

  .nav-link {
    &.active {
      color: ${colors.primaryLinkHover};
    }
  }

  .main-nav {
    ${mixins.respondToMax(misc.layoutChangeBreakpoint())} {
      display: inline-block;
      width: auto;
    }
  }

  .main-nav-inner {
    width: 100%;
    opacity: 1;
    visibility: visible;
    ${mixins.transition()};
    ${mixins.respondTo(misc.viewport['c-vp'])} {
      display: flex;
      justify-content: flex-end;
    }
    ${mixins.respondToMax(misc.layoutChangeBreakpoint())} {
      justify-content: space-between;
      padding-left: 15px;
      width: auto;
      margin-bottom: 0;
    }
    ${mixins.respondTo(misc.viewport['f-vp'])} {
      padding-left: 30px;
    }
    ${mixins.respondToMax(misc.viewport['c-vp'])} {
      ${mixins.horizontalScrolling({ childElement: '.nav-item', width: '21%' })};
      .nav-item:last-child {
        margin-right: 15px;
        margin-left: 15px;
      }
    }
    ${mixins.respondToMax(misc.viewport['b-vp'])} {
      .nav-item {
        width: 29%;
      }
    }
    html.no-flexbox & {
      ${mixins.respondToMinMax(misc.viewport['c-vp'], misc.layoutChangeBreakpoint())} {
        ${mixins.vCenter()};
      }
    }
  }

  .nav-btn {
    color: ${colors.darkblue};
    border: 1px solid ${colors.darkblue};
    ${mixins.vCenter()};
    border-radius: ${misc.mediumBorderRadius};
    svg {
      fill: ${colors.darkblue};
    }
    .btn-box {
      padding: 7px 8px;
      ${mixins.transition({ properties: 'border-right-color' })};
      &:first-of-type {
        border-right: 1px solid ${colors.darkblue};
      }
    }
    &:hover,
    &.active {
      border-color: ${colors.black};
      color: ${colors.black};
      .btn-box:first-of-type {
        border-right-color: ${colors.black};
      }
      svg {
        fill: ${colors.black};
      }
    }
  }

  .profile-box-action {
    display: block;
    max-width: 140px;
    position: relative;
    padding-right: 25px;
    &.invert-dropdown-chevron {
      color: ${colors.primaryLinkHover};
      .dropdown-chevron:before,
      .dropdown-chevron:after {
        background-color: ${colors.primaryLinkHover};
      }
    }
  }
`;

// Hamburger
export const navGlobalStyles = css`
  ${`.${HAMBURGER_OPEN_CLASS}`} {
    overflow: hidden;
    height: 100%;
    width: 100%;
    background-color: ${colors.white};
  }
`;

export const hamburger = css`
  display: inline-block;
  cursor: pointer;
  position: relative;
  vertical-align: middle;
  padding: 9px;
  border: 1px solid ${colors.darkblue};
  border-radius: ${misc.mediumBorderRadius};
  z-index: ${misc.navZindex};
  .bar {
    width: 18px;
    height: 2px;
    background-color: ${colors.darkblue};
    ${mixins.transition()}
    &:nth-of-type(2) {
      margin: 3px 0;
    }
    .hamburger-active & {
      &:nth-of-type(1) {
        transform: translateY(5px) rotate(-45deg);
      }
      &:nth-of-type(2) {
        opacity: 0;
      }
      &:nth-of-type(3) {
        transform: translateY(-5px) rotate(45deg);
      }
    }
  }
  ${layoutChangeBreakpoint} {
    display: none;
  }
`;

// Mobile nav
export const mobileNavWrapper = css`
  position: fixed;
  left: 0;
  top: 66px;
  bottom: 0;
  right: 0;
  z-index: ${misc.navZindex + 2};
  background-color: ${colors.white};
  overflow-y: auto;
  overflow-x: hidden;
`;

export const mobileLinksList = css`
  padding-bottom: 20px;

  .mobile-nav-link {
    display: block;
    margin: 0;
    padding-bottom: 20px;
    padding-top: 20px;
    border-bottom: 1px solid ${colors.blackTransparent12};
  }
`;
