import styled from '@emotion/styled';
import facepaint from 'facepaint';
import { typography, colors, mixins, misc } from '@styles/utilities';

const breakPoints = facepaint([
  mixins.respondTo(misc.viewport['c-vp']),
  mixins.respondTo(misc.viewport['e-vp'])
]);

const H1 = styled.h1`
  font-family: ${typography.fonts.gothamRounded.fontFamily};
  font-weight: ${typography.fonts.gothamRounded.fontWeight.medium};
  line-height: 120%;
  font-style: normal;
  margin: ${misc.defaultTextMargin};
  color: ${props => (props.color ? colors[props.color] : colors.black)};
  ${breakPoints({
    fontSize: ['18px', '30px', typography.baseSizes.alpha]
  })};
`;

export default H1;
