/* eslint-disable import/prefer-default-export */
import { css } from '@emotion/core';
import { breakpoints } from '@styles/utilities';

export const searchInputWrapper = css`
  ${breakpoints({
    minWidth: ['100%', '', '', '30%', '']
  })};

  #search-input {
    margin: 0px;
  }
`;
