/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Fragment, useState } from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { useForgottenPassword } from 'api/auth';

import Button from 'components/htmlElements/Button';
import H2 from 'components/htmlElements/H2';
import H4 from 'components/htmlElements/H4';

// Styles
import { spacer } from '@styles/utilities';

const Schema = Yup.object().shape({
  email: Yup.string()
    .required()
    .email()
});

const ForgotPassword = () => {
  const [formSubmitted, updateFormSubmission] = useState({ success: false, message: '' });
  const { forgotPasswordMutation, status } = useForgottenPassword();

  const handleSubmit = async (values, formikBag) => {
    formikBag.setSubmitting(true);
    const response = await forgotPasswordMutation(values);
    formikBag.setSubmitting(false);

    // eslint-disable-next-line no-console
    console.log(response);

    if (response.error) {
      formikBag.setErrors({ email: response.error });
    } else {
      updateFormSubmission({
        success: true,
        message: response.message
      });
    }
  };

  return (
    <Fragment>
      {!formSubmitted.success ? (
        <Fragment>
          <H2>FORGOTTEN PASSWORD?</H2>
          <H4 book css={spacer.mrB20}>
            Enter your email address to reset your password.
          </H4>
          <Formik
            className="set-password-modal-form clearfix"
            onSubmit={handleSubmit}
            validationSchema={Schema}
            initialValues={{ email: '' }}
          >
            {({ errors, touched, isSubmitting }) => (
              <Form className="forgot-password-modal-form clearfix">
                <div className="input-field-wrapper">
                  <Field type="email" name="email" id="forgot-pass-email" />
                  {errors.email && touched.email && (
                    <label id="forgot-pass-email-error" className="error">
                      {errors.email}
                    </label>
                  )}
                  <label className="placeholder" htmlFor="forgot-pass-email">
                    Email Address
                  </label>
                </div>
                <div className="txt-right mr-t-20">
                  <Button disabled={isSubmitting} type="submit" loading={status === 'loading'}>
                    Reset Password
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </Fragment>
      ) : (
        <H2 css={spacer.mrBT30}>{formSubmitted.message}</H2>
      )}
    </Fragment>
  );
};

export default ForgotPassword;
