/* eslint-disable no-unused-vars */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Fragment } from 'react';
import { spacer } from '@styles/utilities';
import ProductCard from 'components/ProductCard/ProductCard';
import SectionTitle from 'components/SectionTitle/SectionTitle';
import PropTypes from 'prop-types';
import { ROUTE_PATHS, externalSites } from 'globals/constants';
// import PromoCard from 'components/PromoCard/PromoCard';
import Modal from 'components/Modal/Modal';
import { PDFDownload } from 'components/Forms/Forms';
import MockModal from 'components/MockModal/MockModal';
import PageLoader from 'components/PageLoader/PageLoader';
import useHandlePDFProductClick from 'hooks/useHandlePDFProductClick';
import A from 'components/htmlElements/A';
import Button from 'components/htmlElements/Button';
import { Link } from 'components/Router/Router';
import P from 'components/htmlElements/P';

const SectionLoader = ({ sectionTitleText }) => {
  return (
    <div className="container no-push-container">
      <div className="row">
        <div className="col-12">
          <div css={[spacer.mrB80, spacer.padLR30]}>
            <SectionTitle title={sectionTitleText} />
            <PageLoader isFetching isRelative height={340} />
          </div>
        </div>
      </div>
    </div>
  );
};

const HomeProductsSection = ({ products, seeAllRoute, sectionTitleText }) => {
  const {
    clickedItem,
    handleProductCardClick,
    papersPDFList,
    markSchemesPDFList,
    isOpen,
    toggle,
    closeModal
  } = useHandlePDFProductClick();

  return (
    <Fragment>
      <div className="container no-push-container" css={[spacer.mrB40]}>
        <div className="row">
          <div className="col-12">
            <div css={spacer.padLR30}>
              <SectionTitle
                title={sectionTitleText}
                noBorder
                // seeAll={seeAllRoute && products.length > 4 ? seeAllRoute : ''}
              />
            </div>
          </div>
        </div>
        <div className="row">
          {products.slice(0, 4).map(product => {
            return (
              <div className="col-6 col-md-4 col-xl-3" key={product.id} css={[spacer.mrB40]}>
                <ProductCard
                  {...product}
                  onClick={() => {
                    handleProductCardClick(product);
                  }}
                />
              </div>
            );
          })}
        </div>

        <div className="row">
          <div className="col-12 txt-center">
            <div css={spacer.padLR30}>
              <div className="br-b pad-b-40">
                <Button as={Link} to={seeAllRoute}>
                  See all {sectionTitleText}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {clickedItem.isMock || clickedItem.isPaper || clickedItem.isMarkSchemes ? (
        <Modal isOpen={isOpen} toggle={toggle}>
          {clickedItem.isMock ? (
            <MockModal mockData={clickedItem} />
          ) : (
            <PDFDownload
              papersPDFList={papersPDFList}
              markSchemesPDFList={markSchemesPDFList}
              productId={clickedItem.id}
              isPaper={clickedItem.isPaper}
              isMarkSchemes={clickedItem.isMarkSchemes}
              closeModal={closeModal}
            />
          )}
        </Modal>
      ) : (
        ''
      )}
    </Fragment>
  );
};

const HomePage = ({
  papersData,
  solutionsData,
  markSchemesData,
  coursesData
  // mocksData,
  // promotionsData,
  // latestOrderData
}) => {
  return (
    <Fragment>
      <div css={[spacer.mrT40]}>
        {/* {latestOrderData.status !== 'success' ? (
          <SectionLoader sectionTitleText="Recent Purchases" />
        ) : (
          <Fragment>
            <SectionWithCarousel
              carouselItems={latestOrderData.latestOrder}
              title="Recent Purchases"
              downloadableProducts
            />
          </Fragment>
        )} */}
      </div>

      {papersData.status !== 'success' ? (
        <SectionLoader sectionTitleText="Papers" />
      ) : papersData.papers.length ? (
        <HomeProductsSection
          sectionTitleText="Papers"
          products={[...papersData.papers, ...markSchemesData.markSchemes]}
          seeAllRoute={ROUTE_PATHS.papers}
        />
      ) : (
        ''
      )}

      {solutionsData.status !== 'success' ? (
        <SectionLoader sectionTitleText="Solutions" />
      ) : solutionsData.solutions.length ? (
        <HomeProductsSection
          sectionTitleText="Solutions"
          products={solutionsData.solutions}
          seeAllRoute={ROUTE_PATHS.solutions}
        />
      ) : (
        ''
      )}

      {/* {mocksData.status !== 'success' ? (
        <SectionLoader sectionTitleText="Mock Exams" />
      ) : mocksData.mocks.length ? (
        <HomeProductsSection
          sectionTitleText="Mock Exams"
          products={mocksData.mocks}
          seeAllRoute={ROUTE_PATHS.mocks}
        />
      ) : (
        ''
      )} */}

      {/* {markSchemesData.status !== 'success' ? (
        <SectionLoader sectionTitleText="Mark Schemes" />
      ) : markSchemesData.markSchemes.length ? (
        <HomeProductsSection
          sectionTitleText="Mark Schemes"
          products={markSchemesData.markSchemes}
          seeAllRoute={ROUTE_PATHS.mocks}
        />
      ) : (
        ''
      )} */}

      {coursesData.status !== 'success' ? (
        <SectionLoader sectionTitleText="Masterclasses" />
      ) : coursesData.courses.length ? (
        <HomeProductsSection
          products={coursesData.courses}
          sectionTitleText="Masterclasses"
          seeAllRoute={ROUTE_PATHS.courses}
        />
      ) : (
        ''
      )}

      {/* Promotion units */}
      {/* <div className="epp-promo-cards-wrapper" css={spacer.mrB80}>
        {promotionsData.status !== 'success' ? (
          <PageLoader isFetching isRelative height={300} />
        ) : (
          <div className="container no-push-container">
            <div className="row ads-card-row">
              {promotionsData.promotions.map(promotion => (
                <div className="col-md-4 ads-card-col" key={promotion.id}>
                  <PromoCard {...promotion} />
                </div>
              ))}
            </div>
          </div>
        )}
      </div> */}

      <div className="container no-push-container">
        <div className="row justify-content-center">
          <div className="col-lg-6" css={spacer.mrB40}>
            <A href={externalSites.pretestPlus} target="_blank" className="external-site-banner">
              <img
                src={require('images/banners/pretestplus-banner.png').default}
                alt="Pretest Plus"
              />
            </A>
          </div>
          <div className="col-lg-6" css={spacer.mrB80}>
            <A href={externalSites.dailyQuestPlus} target="_blank" className="external-site-banner">
              <img
                src={require('images/banners/dailyquestplus-banner.png').default}
                alt="Daily Quest Plus"
              />
            </A>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

HomeProductsSection.propTypes = {
  products: PropTypes.array.isRequired,
  seeAllRoute: PropTypes.string,
  sectionTitleText: PropTypes.string
};
HomeProductsSection.defaultProps = {
  seeAllRoute: undefined,
  sectionTitleText: ''
};

HomePage.propTypes = {
  papersData: PropTypes.shape({
    status: PropTypes.string.isRequired,
    papers: PropTypes.array
  }),
  solutionsData: PropTypes.shape({
    status: PropTypes.string.isRequired,
    solutions: PropTypes.array
  }),
  mocksData: PropTypes.shape({
    status: PropTypes.string.isRequired,
    mocks: PropTypes.array
  }),
  markSchemesData: PropTypes.shape({
    status: PropTypes.string.isRequired,
    markSchemes: PropTypes.array
  }),
  coursesData: PropTypes.shape({
    status: PropTypes.string.isRequired,
    courses: PropTypes.array
  }),
  promotionsData: PropTypes.shape({
    status: PropTypes.string.isRequired,
    promotions: PropTypes.array
  }),
  latestOrderData: PropTypes.shape({
    status: PropTypes.string.isRequired,
    latestOrder: PropTypes.array
  })
};

SectionLoader.propTypes = {
  sectionTitleText: PropTypes.string.isRequired
};

HomePage.defaultProps = {
  papersData: { status: 'success', papers: [] },
  solutionsData: { status: 'success', solutions: [] },
  mocksData: { status: 'success', mocks: [] },
  markSchemesData: { status: 'success', markSchemes: [] },
  coursesData: { status: 'success', courses: [] },
  promotionsData: { status: 'success', promotions: [] },
  latestOrderData: { status: 'success', latestOrder: [] }
};

export default HomePage;
