import { useState, useEffect } from 'react';

import {
  useDownloadPaperPDF,
  useDownloadMarkSchemePDF,
  useDownloadWritingServicePDF
} from 'api/downloadPDF';
import { useModal } from 'components/Modal/Modal';

const useHandlePDFProductClick = (initialState = {}) => {
  const { isOpen, toggle, closeModal } = useModal(false);
  const { getPaperPDFList, papersPDFList } = useDownloadPaperPDF();
  const { getMarkSchemePDFList, markSchemesPDFList } = useDownloadMarkSchemePDF();
  const { getWritingServicePDFList, writingServicePDFList } = useDownloadWritingServicePDF();

  const [clickedItem, setClickedItem] = useState(initialState);

  const handleProductCardClick = ({
    isPaper,
    isMock,
    isMarkSchemes,
    isWritingService,
    ...product
  }) => {
    if (isMock || isMarkSchemes || isPaper || isWritingService) {
      setClickedItem({ ...product, isMock, isPaper, isMarkSchemes, isWritingService });
      toggle();
    }

    if (isPaper) {
      getPaperPDFList(product.id);
    } else if (isMarkSchemes) {
      getMarkSchemePDFList(product.id);
    } else if (isWritingService) {
      getWritingServicePDFList(product.id);
    }
  };

  const [markSchemesPDFListWithTestFile, updateMarkSchemesPDFListWithTestFile] = useState([]);

  // For Mark Schemes append the actual test paper which is publicly available
  useEffect(() => {
    if (markSchemesPDFList) {
      updateMarkSchemesPDFListWithTestFile([
        {
          id: 'markSchemeTestFileId',
          name: 'Test Paper',
          markSchemeTestFile: clickedItem.test_file,
          extension: 'pdf'
        },
        ...markSchemesPDFList
      ]);
    }
  }, [clickedItem.test_file, markSchemesPDFList]);

  return {
    clickedItem,
    handleProductCardClick,
    papersPDFList,
    markSchemesPDFList: markSchemesPDFListWithTestFile,
    writingServicePDFList,
    isOpen,
    toggle,
    closeModal
  };
};

export default useHandlePDFProductClick;
