/** @jsx jsx */
import { jsx } from '@emotion/core';
import H1 from 'components/htmlElements/H1';
import H3 from 'components/htmlElements/H3';
import H2 from 'components/htmlElements/H2';
import H4 from 'components/htmlElements/H4';
import PropTypes from 'prop-types';
import parse from 'html-react-parser';

import { spacer } from '@styles/utilities';

const MockModal = ({ mockData }) => {
  return (
    <div>
      <H1>{mockData.title}</H1>

      <H2 css={spacer.mrT20}>Venue Details</H2>
      {mockData?.postExcerpt && (
        <H4 as="p" book>
          {parse(mockData.postExcerpt.split('\n\r').join('<br/>'))}
        </H4>
      )}

      <H2 css={spacer.mrT20}>Booking Details</H2>
      {mockData.userMeta.map(({ name, value }) => (
        <div key={name}>
          <H3 css={name !== 'Full Name' ? spacer.mrB20 : ''}>
            <H3 book as="span">
              {name}
            </H3>
            : {value}
          </H3>
        </div>
      ))}
    </div>
  );
};

MockModal.propTypes = {
  mockData: PropTypes.object.isRequired
};

export default MockModal;
