import React, { useState, useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import Player from '@vimeo/player';
import { useInterval } from 'react-use';

import secondsToHms from 'utils/secondsToHms';
import VideoPlayerStateContainer from 'hooks/useVideoPlayerState';
import useParseQueryString from 'hooks/useLocationQueryString';

// import { useUserCourseVideo } from 'api/userCourse';
import CommonVideoApiContainer from 'hooks/useCommonVideoApi';

import { colors } from '@styles/utilities';

function getVimeoID(url) {
  return url.match(
    /(?:www\.|player\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/(?:[^]*)\/videos\/|album\/(?:\d+)\/video\/|video\/|)(\d+)(?:[a-zA-Z0-9_]+)?/i
  )[1];
}

const Vimeo = props => {
  const vimeoPlayerDom = useRef(null);

  // const { updateCourseVideo } = useUserCourseVideo();
  const { updateVideoHook } = CommonVideoApiContainer.useContainer();
  const { updateVideo: updateVideoApi } = updateVideoHook();

  const queryStringData = useParseQueryString();

  // Get video player state
  const {
    videoPlayerState,
    updateLessonTimeContinually,
    toggleVideoOverlay,
    setCurrentLessonDetails,
    toggleVideoPlaying
  } = VideoPlayerStateContainer.useContainer();

  const vimeoId = getVimeoID(props.vimeoLink);

  // Create a new player
  const [vimeoPlayer, setVimeoPlayer] = useState();
  useEffect(() => {
    const initialOptions = {
      url: `https://vimeo.com/${vimeoId}`,
      width: 640,
      title: false,
      byline: false,
      color: colors.green.replace('#', ''),
      autoplay: false,
      paused: true,
      domain: document.location.hostname
    };
    setVimeoPlayer(new Player(vimeoPlayerDom.current, initialOptions));
  }, [vimeoId]);

  const [isIntervalRunning, toggleIsIntervalRunning] = useState(false);

  const [isVideoLoaded, toggleVideoLoaded] = useState(false);

  // Interval to periodically update the current video time
  useInterval(
    async () => {
      // Get time from Vimeo
      const currentTimeInSeconds = await vimeoPlayer.getCurrentTime();

      // Start logging time
      await updateVideoApi({
        collectionId: props.courseId,
        lessonId: props.lessonId,
        userId: props.userId,
        payload: {
          watched: 0,
          watched_time: secondsToHms(currentTimeInSeconds)
        }
      });

      // // Set the current lesson details & the set time to 0
      // setCurrentLessonDetails({
      //   currentLessonTime: currentTimeInSeconds
      // });

      // // Update current time in state object
      // updateVideoInfo(prevState => ({ ...prevState, currentLessonTime: currentTimeInSeconds }));

      // Update seconds in query string
      // updateLocationString({ currentLessonTime: currentTimeInSeconds });

      // console.log('done in triggerSetCurrentTime');
    },
    isIntervalRunning ? 1000 * 10 : null
  );

  // Video events effects
  useEffect(() => {
    if (vimeoPlayer) {
      vimeoPlayer.on('play', () => {
        //  Start logging time & update `currentLessonTime` state
        toggleIsIntervalRunning(true);
      });
    }
    return () => {
      if (vimeoPlayer) {
        vimeoPlayer.off('play');
      }
    };
  }, [toggleVideoOverlay, vimeoPlayer]);

  useEffect(() => {
    if (vimeoPlayer) {
      vimeoPlayer.on('pause', () => {
        // TODO: Stop progress interval here
        toggleIsIntervalRunning(false);
      });
    }
    return () => {
      if (vimeoPlayer) {
        vimeoPlayer.off('pause');
      }
    };
  }, [vimeoPlayer]);

  useEffect(() => {
    if (vimeoPlayer) {
      vimeoPlayer.on('timeupdate', event => updateLessonTimeContinually(event.seconds));
    }
    return () => {
      if (vimeoPlayer) {
        vimeoPlayer.off('timeupdate');
      }
    };
  }, [updateLessonTimeContinually, vimeoPlayer]);

  useEffect(() => {
    async function updatedWatchedStatus() {
      // Update the currentLesson's watched status in local state
      setCurrentLessonDetails({
        watched: 1
      });

      // Get time from Vimeo
      const currentTimeInSeconds = await vimeoPlayer.getCurrentTime();

      // console.log('in ended', videoPlayerState.currentLesson);

      // This update is only for `last_seen` object of the course data
      await updateVideoApi({
        collectionId: props.courseId,
        lessonId: props.lessonId,
        userId: props.userId,
        payload: {
          watched: 1,
          watched_time: secondsToHms(currentTimeInSeconds)
        }
      });
    }

    if (vimeoPlayer) {
      vimeoPlayer.on('ended', () => {
        // Toggle Overlay `hideVideoOverlay`
        toggleVideoOverlay(false);

        // Toggle `isVideoPlaying`
        toggleVideoPlaying(false);

        // Kill progress interval
        toggleIsIntervalRunning(false);

        // Mark the video as watched
        updatedWatchedStatus();

        // TODO Start wait timer

        // console.log('in end', props.lessonId);
      });
    }
    return () => {
      if (vimeoPlayer) {
        vimeoPlayer.off('ended');
      }
    };
  }, [
    props.courseId,
    props.lessonId,
    props.userId,
    setCurrentLessonDetails,
    toggleVideoOverlay,
    toggleVideoPlaying,
    updateVideoApi,
    vimeoPlayer
  ]);

  // Play video on videoId change
  useEffect(() => {
    async function updateVideo() {
      if (vimeoPlayer) {
        await vimeoPlayer.unload();
        toggleVideoLoaded(false);
        await vimeoPlayer.loadVideo(vimeoId);
        toggleVideoLoaded(true);

        // console.log('in update videoId change effect');
      }
    }
    updateVideo();
    return () => {};
  }, [props.isVideoPlaying, vimeoId, vimeoPlayer]);

  // Update currentLessonTime param
  const updateCurrentLessonTime = useCallback(async () => {
    if (vimeoPlayer) {
      await vimeoPlayer.setCurrentTime(queryStringData.currentLessonTime);
    }
  }, [queryStringData.currentLessonTime, vimeoPlayer]);

  useEffect(() => {
    if (vimeoPlayer) {
      vimeoPlayer.on('loaded', () => {
        updateCurrentLessonTime();
      });
    }

    if (isVideoLoaded) {
      updateCurrentLessonTime();
    }

    return () => {
      if (vimeoPlayer) {
        vimeoPlayer.off('loaded');
      }
    };
  }, [isVideoLoaded, updateCurrentLessonTime, vimeoPlayer]);

  // Controlled playing of video
  useEffect(() => {
    async function playVideo() {
      if (vimeoPlayer) {
        toggleVideoLoaded(false);
        await vimeoPlayer.loadVideo(vimeoId);
        toggleVideoLoaded(true);

        if (videoPlayerState.isVideoPlaying) {
          await vimeoPlayer.play();
        } else {
          await vimeoPlayer.pause();
        }
      }
    }
    playVideo();
  }, [videoPlayerState.isVideoPlaying, vimeoId, vimeoPlayer]);

  // // Set the initial player time from query string - Run only once
  // useEffect(() => {
  //   async function playerReady() {
  //     console.log(props.currentLessonTime);
  //     await vimeoPlayer.pause();
  //     await vimeoPlayer.setCurrentTime(props.currentLessonTime);
  //     await vimeoPlayer.play();
  //     console.log('playerReady, player ready!!');
  //   }
  //   if (vimeoPlayer) {
  //     vimeoPlayer.on('loaded', event => {
  //       playerReady();
  //     });
  //     playerReady();
  //   }
  // }, [props.currentLessonTime, vimeoPlayer]);

  return <div id="video-player" ref={vimeoPlayerDom} />;
};

Vimeo.propTypes = {
  courseId: PropTypes.number.isRequired,
  lessonId: PropTypes.string.isRequired,
  vimeoLink: PropTypes.string.isRequired,
  userId: PropTypes.number.isRequired,
  isVideoPlaying: PropTypes.bool.isRequired
};

// Vimeo.whyDidYouRender = true;
export default Vimeo;
