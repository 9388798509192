import React from 'react';

import UserWritingServiceContainer from 'api/userWritingService';

import PageLoader from 'components/PageLoader/PageLoader';
import { Redirect } from 'components/Router';
import Layout from 'layouts';
import { ROUTE_PATHS } from 'globals/constants';
import WritingServicePage from './page';

const WritingServiceContainer = () => {
  const {
    writingService,
    error,
    status,
    canFetchMore,
    fetchMore,
    isFetchingMore
  } = UserWritingServiceContainer.useContainer();

  if (error) {
    return <Redirect to={ROUTE_PATHS.notFound} />;
  }

  return (
    <Layout>
      {status !== 'success' && <PageLoader isFetching isRelative atPageHeight />}
      <WritingServicePage
        writingService={writingService}
        canFetchMore={canFetchMore}
        fetchMore={fetchMore}
        isFetchingMore={isFetchingMore}
      />
    </Layout>
  );
};

export default () => (
  <UserWritingServiceContainer.Provider>
    <WritingServiceContainer />
  </UserWritingServiceContainer.Provider>
);
