import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import CourseSectionHeading from 'components/CourseSectionHeading';
import Bookmark from 'components/Bookmark';

const CourseBookmarks = props => {
  const getCurrentSection = currentSectionId => {
    return props.sectionsData.filter(section => {
      return section.id === currentSectionId;
    });
  };

  const isBookmarksSectionEmpty = () => {
    return !!props.courseBookmarks.filter(section => section.bookmarks.length).length;
  };

  return (
    <Fragment>
      {props.courseBookmarks && isBookmarksSectionEmpty() ? (
        props.courseBookmarks.map(section => {
          if (section.bookmarks.length) {
            const currentSectionDetails = getCurrentSection(section.id);
            return (
              <div className="course-section-bookmarks-wrapper" key={section.id}>
                <CourseSectionHeading
                  hideTotalDuration
                  hidePiechart
                  title={currentSectionDetails[0].name}
                  lessonsWatched={currentSectionDetails[0].watched_videos}
                  totalLessons={currentSectionDetails[0].total_videos}
                  totalBookmarks={section.bookmarks.length}
                />
                <div className="section-bookmarks-list">
                  {section.bookmarks.map(bookmark => {
                    return (
                      <Bookmark
                        key={bookmark.id}
                        showBookmarkIcon
                        titleClickable
                        theme="dark"
                        lessonName={section.name}
                        id={bookmark.id}
                        sectionId={section.id}
                        lessonId={bookmark.course_video_id}
                        text={bookmark.name}
                        lessonDuration={bookmark.bookmark_time}
                      />
                    );
                  })}
                </div>
              </div>
            );
          }
          return false;
        })
      ) : (
        <div className="no-course-bookmarks">
          <h1 className="txt-grey-medium mr-t-80 txt-center">No bookmarks added yet</h1>
        </div>
      )}
    </Fragment>
  );
};

CourseBookmarks.propTypes = {
  sectionsData: PropTypes.array.isRequired,
  courseBookmarks: PropTypes.array.isRequired
};

export default CourseBookmarks;
