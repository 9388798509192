/* eslint-disable import/prefer-default-export */
import { css } from '@emotion/core';

import { colors, mixins, misc } from '@styles/utilities';

export const footerStyles = css`
  width: 100%;
  background-color: ${colors.black};
  text-align: center;
  padding: 7px 0 8px;
  position: relative;
  z-index: 10;
  transform: translateZ(0);

  p {
    margin: 0;
    display: inline-block;
    width: 75%;
    float: left;
    font-size: 10px;
    font-weight: normal;
  }

  a {
    color: ${colors.lightblue};
  }

  .pipe {
    margin: 0 1rem;
  }
  p.THE_DOT {
    display: block;
    padding: 1px;
    margin: 0 auto;
    position: absolute;
    width: auto;
    right: 15px;
    top: 50%;
    transform: translate(0%, -50%);

    a {
      &:hover {
        color: #fee252;
      }
    }
  }

  ${mixins.respondToMax(misc.viewport['c-vp'])} {
    p {
      width: 100%;
      text-align: center;
    }

    p.THE_DOT {
      position: relative;
      top: auto;
      left: auto;
      right: auto;
      width: 100%;
      margin-top: 10px;
      transform: none;
    }
  }
  ${mixins.respondToMax(misc.viewport['b-vp'])} {
    .meta-more {
      display: block;
    }
  }
`;
