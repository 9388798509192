import qs from 'qs';
import store from 'store';
import { navigate } from 'components/Router';
import { ROUTE_PATHS, authErrors, apiErrorCodes } from 'globals/constants';
import generateFingerprint from 'utils/device-fingerprint';

generateFingerprint();

const request = async ({ endpoint, method, headers, payload, token }) => {
  let url = `${endpoint}`;

  const userInfo = {
    token: token || (store.get('user') && store.get('user').token)
  };

  const options = {
    method,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'X-Device-Fingerprint': store.get('ufp') && store.get('ufp').murmur,
      'X-Device-Fingerprint-Readable': store.get('ufp') && store.get('ufp').values,
      // mode: 'no-cors',
      ...headers
    }
  };

  if (userInfo.token) {
    options.headers.Authorization = `${userInfo.token}`;
  }

  if (method === 'POST' || method === 'PUT') {
    options.body = JSON.stringify(payload);
  }

  if (method === 'GET' || method === 'PUT') {
    if (payload) {
      url += `?${qs.stringify(payload)}`;
    }
  }

  const response = await fetch(url, options);
  const responseData = await response.text();
  const finalResponse = responseData.length ? JSON.parse(responseData) : responseData;
  // const { errors } = finalResponse;
  const { status } = response;

  if (finalResponse.error_type === authErrors.fingerprintLimitReached.fingerprint_limit_reached) {
    navigate(`${ROUTE_PATHS.login}`, {
      state: {
        logoutUser: true,
        error: authErrors.fingerprintLimitReached.key
      }
    });
  } else if (status === 403) {
    navigate(`${ROUTE_PATHS.login}`, {
      state: {
        logoutUser: true,
        error: authErrors.parallelLogin.key
      }
    });
  } else if (status === 401 && finalResponse.error_type !== apiErrorCodes.download_expired) {
    // Invalid token error
    navigate(`${ROUTE_PATHS.login}`, {
      state: {
        logoutUser: true
      }
    });
  }

  return {
    response: finalResponse,
    status
  };
};

export default request;
