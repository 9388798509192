/** @jsx jsx */
import { Fragment } from 'react';
import { jsx } from '@emotion/core';
import FlipMove from 'react-flip-move';
import { spacer } from '@styles/utilities';
import ProductCard from 'components/ProductCard/ProductCard';
import SectionTitle from 'components/SectionTitle/SectionTitle';
import PropTypes from 'prop-types';
import { Waypoint } from 'react-waypoint';
import NoProducts from 'components/NoProducts/NoProducts';
import { eCommerceSiteLinks } from 'globals/constants';
import Modal from 'components/Modal/Modal';
import { PDFDownload } from 'components/Forms/Forms';
import PageLoader from 'components/PageLoader/PageLoader';

import useHandlePDFProductClick from 'hooks/useHandlePDFProductClick';

const MarksSchemesPage = ({ writingService, canFetchMore, fetchMore, isFetchingMore }) => {
  const {
    clickedItem,
    handleProductCardClick,
    writingServicePDFList,
    isOpen,
    toggle,
    closeModal
  } = useHandlePDFProductClick();

  return (
    <Fragment>
      <div className="container no-push-container" css={spacer.mrBT40}>
        <div className="row">
          <div className="col-12">
            <div css={spacer.padLR30}>
              {!writingService.length ? (
                <NoProducts productName="Writing" linkToBuy={eCommerceSiteLinks.writingService} />
              ) : (
                <Fragment>
                  <SectionTitle title="Writing" />
                </Fragment>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="container no-push-container" css={spacer.mrBT40}>
        <FlipMove className="row">
          {writingService.map(writingServiceProduct => (
            <div
              className="col-6 col-md-4 col-xl-3"
              key={writingServiceProduct.id}
              css={[spacer.padB80]}
            >
              <ProductCard
                {...writingServiceProduct}
                onClick={() => {
                  handleProductCardClick(writingServiceProduct);
                }}
              />
            </div>
          ))}
        </FlipMove>
        <Waypoint
          bottomOffset="-40%"
          onEnter={() => {
            console.info('Loading more Writing');
            if (canFetchMore) {
              fetchMore();
            }
          }}
        />
        {isFetchingMore ? <PageLoader height="100px" isRelative /> : ''}
      </div>

      <Modal isOpen={isOpen} toggle={toggle}>
        <PDFDownload
          markSchemesPDFList={writingServicePDFList}
          productId={clickedItem.id}
          isPaper={clickedItem.isPaper}
          isMarkSchemes={clickedItem.isMarkSchemes}
          isWritingService={clickedItem.isWritingService}
          closeModal={closeModal}
        />
      </Modal>
    </Fragment>
  );
};

MarksSchemesPage.propTypes = {
  writingService: PropTypes.array.isRequired,
  fetchMore: PropTypes.func,
  // canFetchMore can be number or boolean, number if more pages are available, boolean `false` if no next page available
  canFetchMore: PropTypes.any,
  isFetchingMore: PropTypes.bool
};
MarksSchemesPage.defaultProps = {
  fetchMore: () => {},
  canFetchMore: false,
  isFetchingMore: false
};
export default MarksSchemesPage;
