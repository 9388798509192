/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'components/Router';
import PageLoader from 'components/PageLoader';
import { ROUTE_PATHS } from 'globals/constants';
import Layout from 'layouts';

// Fetch from api
import { UserInfoContainer } from 'api/auth';
import UserSolutionContainer, {
  useUserSolutionVideo,
  useUserSolutionRestart
} from 'api/userSolution';
import CommonVideoApiContainer from 'hooks/useCommonVideoApi';

import VideoPlayerStateContainer from 'hooks/useVideoPlayerState';
import SolutionSingle from './page';

const SolutionContainer = () => {
  const { data, solutionError, solutionStatus } = UserSolutionContainer.useContainer();

  return (
    <Layout>
      <VideoPlayerStateContainer.Provider
        initialState={{ providerFor: 'solutions', sidebarDisabled: true }}
      >
        {solutionError && <Redirect to={ROUTE_PATHS.notFound} />}
        {solutionStatus !== 'success' && <PageLoader isFetching isRelative atPageHeight />}
        {data.solutionData && Object.keys(data.solutionData).length && (
          <SolutionSingle courseData={data.solutionData} />
        )}
      </VideoPlayerStateContainer.Provider>
    </Layout>
  );
};

const ContainerWithProvider = props => {
  const { ID: userId } = UserInfoContainer.useContainer();
  return (
    <UserSolutionContainer.Provider initialState={{ solutionId: props.solutionId, userId }}>
      <CommonVideoApiContainer.Provider
        initialState={{
          updateVideoHook: useUserSolutionVideo,
          restartAllHook: useUserSolutionRestart
        }}
      >
        <SolutionContainer {...props} />
      </CommonVideoApiContainer.Provider>
    </UserSolutionContainer.Provider>
  );
};

ContainerWithProvider.propTypes = {
  solutionId: PropTypes.string.isRequired
};

export default ContainerWithProvider;
