/** @jsx jsx */
import { useInfiniteQuery } from 'react-query';
import request from 'utils/request';
import { api } from 'globals/constants';
import { createContainer } from 'unstated-next';
import getProductFields from 'utils/getProductFields';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { useState, useEffect } from 'react';
import { UserInfoContainer } from './auth';

const useUserMarkSchemes = () => {
  const { ID } = UserInfoContainer.useContainer();
  const [markSchemes, setMarkSchemes] = useState([]);
  const {
    status,
    data,
    error,
    // isFetching,
    isFetchingMore,
    fetchMore,
    canFetchMore
  } = useInfiniteQuery(
    'userMarkSchemesKey',
    async (key, page = 0) => {
      const { response } = await request({
        method: 'GET',
        endpoint: `${api.endpoints.userMarkSchemes.findAll({ userId: ID })}?page=${page}`
      });
      return response;
    },
    {
      refetchOnWindowFocus: false,
      getFetchMore: lastGroup => {
        return lastGroup.next_page_url ? lastGroup.current_page + 1 : false;
      }
    }
  );

  // Fetch all data upfront
  useEffect(() => {
    if (canFetchMore && !isFetchingMore) {
      fetchMore();
    }
  }, [canFetchMore, fetchMore, isFetchingMore]);

  useDeepCompareEffect(() => {
    if (data && data.data !== 'undefined') {
      const newMarksSchems = data
        .map(pageData => {
          if (!pageData.data) {
            return false;
          }

          return pageData.data.map(getProductFields);
        })
        .flat(1);
      setMarkSchemes(newMarksSchems);
    }
  }, [data, data.data]);

  return {
    markSchemes,
    error,
    status
    // isFetching,
    // isFetchingMore,
    // fetchMore,
    // canFetchMore
  };
};

const UserMarkSchemesContainer = createContainer(useUserMarkSchemes);

export default UserMarkSchemesContainer;
