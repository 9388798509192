/** @jsx jsx */

import { jsx } from '@emotion/core';
import { spacer } from '@styles/utilities';
import ProductCard from 'components/ProductCard/ProductCard';
import SectionTitle from 'components/SectionTitle/SectionTitle';
import PropTypes from 'prop-types';
import FiltersRow, { useFiltersRow } from 'components/FiltersRow/FiltersRow';
// import { Waypoint } from 'react-waypoint';
import NoProducts from 'components/NoProducts/NoProducts';
import { eCommerceSiteLinks } from 'globals/constants';
import { Fragment, useEffect, useMemo, useState } from 'react';
// import PageLoader from 'components/PageLoader/PageLoader';
import SearchFilter from 'components/SearchFilter/SearchFilter';
import H3 from 'components/htmlElements/H3';
import Button from 'components/htmlElements/Button';
import { Row } from 'components/Grid/Grid';
import fuzzySearch from 'utils/fuzzySearch';

const SolutionsPage = ({
  solutions
  // canFetchMore, fetchMore, isFetchingMore
}) => {
  const { filters, activeFilter, onFilterClick } = useFiltersRow({ products: [] });
  const [searchFilteredSolutions, setSearchFilteredSolutions] = useState([]);
  const [hasLoaded, setHasLoaded] = useState(false);

  const filteredSolutions = useMemo(
    () =>
      solutions.filter(({ categories }) => {
        if (!activeFilter) return true;
        return categories.findIndex(el => el.slug === activeFilter) !== -1;
      }),
    [activeFilter, solutions]
  );

  useEffect(() => {
    setSearchFilteredSolutions(filteredSolutions);
    setHasLoaded(filteredSolutions?.length > 0);
  }, [filteredSolutions]);

  const searchHandler = e => {
    const searchQuery = e.target.value.toLowerCase();

    if (searchQuery.trim().length > 0) {
      const results = fuzzySearch({ query: searchQuery, items: filteredSolutions });
      setSearchFilteredSolutions(results.map(result => result.item));
    } else {
      setSearchFilteredSolutions(filteredSolutions);
    }
  };

  const resetSearch = () => {
    setSearchFilteredSolutions(filteredSolutions);
    document.getElementById('search-input').value = '';
  };

  return (
    <Fragment>
      <div className="container no-push-container" css={spacer.mrBT40}>
        <div className="row">
          <div className="col-12">
            <div css={spacer.padLR30}>
              {!solutions.length ? (
                <NoProducts productName="Solutions" linkToBuy={eCommerceSiteLinks.papers} />
              ) : (
                <Fragment>
                  <SectionTitle title="Solutions" />

                  <div className="d-flex justify-content-between align-items-start flex-wrap">
                    <FiltersRow
                      activeFilter={activeFilter}
                      filters={filters}
                      onFilterClick={onFilterClick}
                    />

                    <SearchFilter searchHandler={searchHandler} />
                  </div>
                </Fragment>
              )}
            </div>
          </div>
        </div>
      </div>

      {solutions?.filter(solution => solution).length > 0 && hasLoaded && (
        <div className="container no-push-container" css={spacer.mrBT40}>
          <Row>
            {searchFilteredSolutions?.length > 0 ? (
              searchFilteredSolutions.map(solution => (
                <div className="col-6 col-md-4 col-xl-3" key={solution.id} css={[spacer.padB80]}>
                  <ProductCard {...solution} />
                </div>
              ))
            ) : (
              <div className="m-auto" css={spacer.padB30}>
                <H3 css={spacer.mrB20}>No results found.</H3>
                <Button onClick={resetSearch}>Clear Search</Button>
              </div>
            )}
          </Row>
          {/* <Waypoint
          bottomOffset="-40%"
          onEnter={() => {
            console.info('Loading more solutions');
            if (canFetchMore) {
              fetchMore();
            }
          }}
        />
        {isFetchingMore ? <PageLoader height="100px" isRelative /> : ''} */}
        </div>
      )}
    </Fragment>
  );
};

SolutionsPage.propTypes = {
  solutions: PropTypes.array.isRequired,
  fetchMore: PropTypes.func,
  // canFetchMore can be number or boolean, number if more pages are available, boolean `false` if no next page available
  canFetchMore: PropTypes.any,
  isFetchingMore: PropTypes.bool
};
SolutionsPage.defaultProps = {
  fetchMore: () => {},
  canFetchMore: false,
  isFetchingMore: false
};

export default SolutionsPage;
