/* eslint-disable no-unused-vars */
import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { navigate } from 'components/Router';
import Login from 'components/Forms/Login';

import AccountSharingNoticeModal from 'components/AccountSharingNoticeModal/AccountSharingNoticeModal';
import { ROUTE_PATHS, authErrors } from 'globals/constants';
import IpRestrictionNoticeModal from 'components/IpRestrictionNoticeModal/IpRestrictionNoticeModal';
import { UserInfoContainer, useLogout } from 'api/auth';

import Modal, { useModal } from 'components/Modal';
import SetPassword from 'components/Forms/SetPassword';
import * as queryString from 'querystringify';

const LoginPage = props => {
  const [accountSharingModalVisible, setAccountSharingModalVisible] = useState(false);
  const [ipRestrictionModalVisible, setIpRestrictionModalVisible] = useState(false);

  const user = UserInfoContainer.useContainer();
  const logout = useLogout();

  const clearState = () => {
    setIpRestrictionModalVisible(false);
    setAccountSharingModalVisible(false);
    navigate(ROUTE_PATHS.login);
    window.location.reload();
  };

  // Reset password modal
  const queryParams = queryString.parse(props.location.search);
  const { isOpen: resetModal, closeModal: closeResetModal } = useModal(
    queryParams.key && queryParams.login
  );
  const handleCloseModal = () => {
    closeResetModal();
    navigate(ROUTE_PATHS.login, { replace: true });
  };

  useEffect(() => {
    if (props.location.state && props.location.state.error === authErrors.parallelLogin.key) {
      setAccountSharingModalVisible(true);
    }
    if (
      props.location.state &&
      props.location.state.error === authErrors.fingerprintLimitReached.key
    ) {
      setIpRestrictionModalVisible(true);
    }
  }, [props.location.state]);

  // If no errors after login or if navigated to login page,
  // go to home page
  useEffect(() => {
    if (user.token && !user.errors) {
      navigate(ROUTE_PATHS.home);
    }
  }, [user.errors, user.token]);

  const [didLogout, updateDidLogout] = useState(false);
  useEffect(() => {
    const logoutAction = () => {
      if (props.location.state && props.location.state.logoutUser) {
        logout();
        updateDidLogout(true);
        // window.location.reload();
      }
    };
    if (!didLogout) {
      logoutAction();
    }
  }, [didLogout, logout, props.location.state]);

  return (
    <Fragment>
      <Login />
      <AccountSharingNoticeModal isOpen={accountSharingModalVisible} toggle={clearState} />
      <IpRestrictionNoticeModal isOpen={ipRestrictionModalVisible} toggle={clearState} />
      <Modal
        shouldCloseOnEsc={false}
        shouldCloseOnOverlayClick={false}
        isOpen={resetModal}
        toggle={handleCloseModal}
      >
        <SetPassword queryParamKey={queryParams.key} queryParamLogin={queryParams.login} />
      </Modal>
    </Fragment>
  );
};

LoginPage.propTypes = {
  location: PropTypes.object
};

LoginPage.defaultProps = {
  location: {}
};

export default LoginPage;
