const typography = {
  fonts: {
    gothamRounded: {
      fontFamily: `"Gotham Rounded SSm A", "Gotham Rounded SSm B"`,
      fontWeight: {
        book: 400,
        medium: 500
      }
    }
  },
  baseSizes: {
    alpha: '36px', // H1
    beta: '24px', // H2
    gamma: '18px', // H3
    delta: '16px', // H4
    epsilon: '14px', // H5
    zeta: '12px',
    eta: '14px', // body, P,
    theta: '12px', // H6
    iota: '10px'
  }
};

export default typography;
