/* eslint-disable import/prefer-default-export */
import { css } from '@emotion/core';

import { colors, mixins, misc } from '@styles/utilities';

export const wrapper = css`
  background-color: ${colors.black3};
`;

export const linksList = css`
  margin: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const linkItem = css`
  margin: 0;
  padding: 0;
  list-style: none;
  &:not(:last-of-type) {
    padding-top: 13px;
    padding-bottom: 13px;
    border-right: 1px solid ${colors.white};
    padding-right: 20px;
  }
  &:not(:nth-last-of-type(-n + 2)) {
    margin-right: 20px;
  }
  ${mixins.respondTo(misc.viewport['e-vp'])} {
    &:not(:last-of-type) {
      padding-top: 12px;
      padding-bottom: 12px;
    }
  }
  &.notification {
    padding-top: 3px;
    padding-bottom: 3px;
  }
`;

export const basketLink = itemsInCart => css`
  padding: 10px 45px 10px 35px;
  background-color: ${itemsInCart ? colors.darkblue : mixins.toRgba(colors.darkblue, 0.2)};
  color: ${colors.white};
  display: inline-block;
  position: relative;
  ${mixins.linkActiveStyles()} {
    color: ${colors.white};
    background-color: ${colors.darkblue};
    .basket-button-item-count {
      background-color: ${colors.green};
    }
  }
  ${'' /* ${mixins.respondTo(misc.viewport['d-vp'])} {
    padding-top: 14px;
    padding-bottom: 14px;
  } */}
  ${mixins.respondTo(misc.viewport['e-vp'])} {
    padding-top: 13px;
    padding-bottom: 13px;
  }
`;

export const basketItemCount = itemsInCart => css`
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  border-radius: 50%;
  width: 25px;
  height: 25px;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${itemsInCart ? colors.green : mixins.toRgba(colors.green, 0.2)};
  ${mixins.transition()};
`;

export const basketIcon = css`
  width: 25px;
  position: absolute;
  top: 50%;
  left: 10px;
  pointer-events: none;
  transform: translateY(-50%);
`;

// Notification
export const notificationCountWrapper = count => css`
  border-radius: ${misc.mediumBorderRadius};
  margin: 3px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${count > 0 ? colors.red : 'transparent'};
  border: 1px solid transparent;
  border-color: ${count > 0 ? '' : `${colors.white}`};
  &:hover {
    color: ${colors.white};
  }
`;

export const unreadNotificationCount = css`
  border-right: 1px solid ${colors.white};
`;

export const notificationCountIcon = css`
  pointer-events: none;
`;
