import { useState, useEffect } from 'react';
import { debounce } from 'lodash-es';

const usePrimarnavHeight = () => {
  const [primarynavHeight, setPrimarynavHeight] = useState(0);

  useEffect(() => {
    const primaryNav = document.querySelector('.primary-nav');
    if (!primaryNav) return null;

    setPrimarynavHeight(primaryNav.clientHeight);

    const handleResize = debounce(() => {
      setPrimarynavHeight(primaryNav.clientHeight);
    }, 100);

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return { primarynavHeight };
};

export default usePrimarnavHeight;
