import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import VideoPlayerStateContainer from 'hooks/useVideoPlayerState';
import { UserInfoContainer } from 'api/auth';
import PlayerOverlay from 'components/PlayerOverlay';
import { COURSE_STATES } from 'globals/constants';
import { getPrevLesson, getNextLesson } from 'utils/videoHelpers/getPrevNextLesson';

import Vimeo from './Vimeo';
import VideoControls from './VideoControls';

const VideoPlayer = ({ courseData }) => {
  const { videoPlayerState } = VideoPlayerStateContainer.useContainer();
  const { ID: userId } = UserInfoContainer.useContainer();

  const [courseStatus, updateCourseStatus] = useState(COURSE_STATES.PROGRESS);

  useEffect(() => {
    if (courseData.watched_videos === 0 && courseData.last_seen === null) {
      updateCourseStatus(COURSE_STATES.NEW);
      return;
    }
    if (courseData.watched_videos === courseData.total_videos) {
      updateCourseStatus(COURSE_STATES.COMPLETED);
      return;
    }
    if (
      courseData.watched_videos > 0 &&
      courseData.watched_videos < courseData.total_videos &&
      videoPlayerState.currentLesson.watched !== 1
    ) {
      updateCourseStatus(COURSE_STATES.PROGRESS);
      return;
    }
    if (courseData.watched_videos > 0 && videoPlayerState.currentLesson.watched === 1) {
      updateCourseStatus(COURSE_STATES.PLAY_NEXT);
      return;
    }
    updateCourseStatus(COURSE_STATES.PROGRESS);
  }, [
    courseData.last_seen,
    courseData.total_videos,
    courseData.watched_videos,
    videoPlayerState.currentLesson.watched
  ]);

  // Get prev/next lesson links
  const [prevNextLessons, updatePrevNextState] = useState({
    prev: null,
    next: null
  });
  useEffect(() => {
    updatePrevNextState({
      prev: getPrevLesson({ data: courseData, videoId: videoPlayerState.currentLesson.lessonId }),
      next: getNextLesson({ data: courseData, videoId: videoPlayerState.currentLesson.lessonId })
    });
  }, [courseData, videoPlayerState.currentLesson.lessonId]);

  return (
    <div
      className={`video-player-wrapper ${!videoPlayerState.isVideoExpanded ? 'show-controls' : ''}`}
    >
      {videoPlayerState.currentLesson.vimeoLink && (
        <Vimeo
          courseId={videoPlayerState.currentLesson.courseId}
          lessonId={videoPlayerState.currentLesson.lessonId}
          vimeoLink={videoPlayerState.currentLesson.vimeoLink}
          userId={userId}
          isVideoPlaying={videoPlayerState.isVideoPlaying}
        />
      )}

      {!videoPlayerState.isVideoExpanded && (
        <VideoControls
          prevLesson={prevNextLessons.prev}
          nextLesson={prevNextLessons.next}
          sidebarDisabled={videoPlayerState.sidebarDisabled}
        />
      )}

      {!videoPlayerState.hideVideoOverlay && (
        <PlayerOverlay
          courseStatus={courseStatus}
          nextLesson={prevNextLessons.next}
          courseId={courseData.id}
        />
      )}
    </div>
  );
};

VideoPlayer.propTypes = {
  courseData: PropTypes.object.isRequired
};

export default VideoPlayer;
