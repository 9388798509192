import { useState, useMemo } from 'react';
import { useQuery, useMutation, queryCache } from 'react-query';
import request from 'utils/request';
import { api } from 'globals/constants';
import { createContainer } from 'unstated-next';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { UserInfoContainer } from 'api/auth';

const useUserSolution = ({ solutionId, userId }) => {
  const [solutionState, updateSolutionState] = useState();

  const { data: solutionData, error: solutionError, status: solutionStatus } = useQuery(
    [
      'userSolution',
      {
        solutionId: parseInt(solutionId),
        userId: parseInt(userId)
      }
    ],
    async () => {
      const { response } = await request({
        method: 'GET',
        endpoint: api.endpoints.userSolution.find({ userId, solutionId })
      });
      return response;
    },
    {
      refetchOnWindowFocus: false
    }
  );

  const memoSolutionData = useMemo(() => solutionData || {}, [solutionData]);

  useDeepCompareEffect(() => {
    updateSolutionState({
      solutionData: memoSolutionData
    });
  }, [memoSolutionData]);

  return {
    data: { ...solutionState },
    solutionError,
    solutionStatus
  };
};

const userSolutionContainer = createContainer(useUserSolution);

// For marking a video watched and updating watched_time
export const useUserSolutionVideo = () => {
  const [updateVideo, { data, error, status }] = useMutation(
    async ({ collectionId, lessonId, userId, payload }) => {
      const { response } = await request({
        method: 'PUT',
        endpoint: api.endpoints.userSolutionVideo.update({
          solutionId: collectionId,
          videoId: lessonId,
          userId
        }),
        payload
      });
      return response;
    },
    {
      onMutate: ({ payload, collectionId, userId }) => {
        // Optimistically update the query watched status
        queryCache.setQueryData(
          ['userSolution', { solutionId: collectionId, userId }],
          previous => {
            return {
              ...previous,
              last_seen: {
                ...previous.last_seen,
                watched: payload.watched
              }
            };
          }
        );
      },
      onError(err) {
        throw new Error(err);
      },

      onSettled(newData, err, variables) {
        queryCache.refetchQueries('userSolution', {
          solutionId: variables.collectionId,
          userId: variables.userId
        });
      }
    }
  );

  return { updateVideo, data, error, status };
};

export const useUserSolutionRestart = () => {
  const { ID: userId } = UserInfoContainer.useContainer();

  const [restartFn, { data, error, status }] = useMutation(
    async ({ id, restart }) => {
      const { response } = await request({
        method: 'PUT',
        endpoint: api.endpoints.userSolution.restart({ solutionId: id, userId }),
        payload: {
          restart,
          restart_confirmation: restart
        }
      });
      return response;
    },
    {
      onSettled(newData, err, variables) {
        queryCache.refetchQueries('userSolution', {
          solutionId: variables.id,
          userId
        });
      }
    }
  );
  return { restartFn, data, error, status };
};

export default userSolutionContainer;
