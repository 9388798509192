import React from 'react';
import Layout from 'layouts';
import UserNotificationsContainer, { useToggleNotificationRead } from 'api/userNotifications';
import { Redirect } from '@reach/router';
import { ROUTE_PATHS } from 'globals/constants';
import PageLoader from 'components/PageLoader/PageLoader';
import NotificationsPage from './page';

const NotificationsContainer = () => {
  const {
    allNotifications,
    fetchMore,
    error,
    status,
    canFetchMore,
    isFetching
  } = UserNotificationsContainer.useContainer();
  const { toggleNotificationRead } = useToggleNotificationRead();

  if (error) return <Redirect to={ROUTE_PATHS.notFound} />;
  return (
    <Layout>
      {status !== 'success' && <PageLoader isFetching isRelative atPageHeight />}
      <NotificationsPage
        fetchMore={fetchMore}
        canFetchMore={canFetchMore}
        isFetching={isFetching}
        notifications={allNotifications}
        toggleNotificationRead={toggleNotificationRead}
      />
    </Layout>
  );
};

export default () => (
  <UserNotificationsContainer.Provider>
    <NotificationsContainer />
  </UserNotificationsContainer.Provider>
);
