const colors = {
  white: '#ffffff',
  'white-shade-1': '#f7f7f7',
  'white-transparent': 'rgba(255,255,255, 0.5)',
  paleWhite: '#eaeaea',

  black: '#000000',
  black3: '#333333',
  black8: '#888888',
  blackTransparent12: 'rgba(0, 0, 0, 0.12)',
  blackTransparent54: 'rgba(0, 0, 0, 0.54)',
  blackTransparent85: 'rgba(0, 0, 0, 0.85)',

  'grey-shade-1': '#989898',

  lightblue: '#428bca',
  darkblue: '#1C6FB7',
  skyBlue: '#eef8ff',

  red: '#c00018',
  green: '#19a14c',
  lightGreen: '#b9e1ba',

  primaryLinkHover: '#1C6FB7',
  primaryLinkActive: '#2654B4',
  secondaryLinkHover: '#428bca',
  secondaryLinkActive: '#2654B4',

  // Class colors start here
  mediumGreen: '#22aa58',
  orange: '#f17034',
  lightOrange: '#fff0da',
  aqua: '#4ac2b8',
  lightAqua: '#ecfbfa',
  violet: '#9a6bb5',
  lightViolet: '#f5f0f8',
  yellow: '#ffae00',
  lightYellow: '#fff8e0',
  cyan: '#41d0ee',
  lightCyan: '#ecfafd'
};

export const colorAlias = {
  '6PlusMain': colors.mediumGreen,
  '7PlusMain': colors.orange,
  '7PlusAccent': colors.lightOrange,
  '8PlusMain': colors.aqua,
  '8PlusAccent': colors.lightAqua,
  '9PlusMain': colors.violet,
  '9PlusAccent': colors.lightViolet,
  '10PlusMain': colors.yellow,
  '10PlusAccent': colors.lightYellow,
  '11PlusMain': colors.cyan,
  '11PlusAccent': colors.lightCyan,
  primaryAnchorDefaultColor: colors.lightblue
};

export default { ...colors, ...colorAlias };
