import styled from '@emotion/styled';
import facepaint from 'facepaint';
import { typography, colors, misc, mixins } from '@styles/utilities';
import A from 'components/htmlElements/A';

const breakPoints = facepaint([
  mixins.respondTo(misc.viewport['c-vp']),
  mixins.respondTo(misc.viewport['f-vp'])
]);

const StyledNavLink = styled(A)`
  font-style: normal;
  margin: ${misc.defaultTextMargin};
  letter-spacing: normal;
  font-family: ${typography.fonts.gothamRounded.fontFamily};
  font-weight: ${typography.fonts.gothamRounded.fontWeight.book};
  line-height: 120%;
  color: ${props => (props.color ? colors[props.color] : colors.black)};
  text-decoration: none;
  cursor: pointer;
  strong {
    font-weight: 400;
  }
  ${breakPoints({
    fontSize: ['14px', '16px', typography.baseSizes.gamma]
  })}
`;

export default StyledNavLink;
