/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { UnstyledLink } from 'components/Router';
import Icon from 'components/Icon';
import Button from 'components/htmlElements/Button';

import { COURSE_STATES } from 'globals/constants';
import VideoPlayerStateContainer, { usePlayVideo } from 'hooks/useVideoPlayerState';
import { setQueryParams } from 'hooks/useLocationQueryString';
import CommonVideoApiContainer from 'hooks/useCommonVideoApi';

import { colors } from '@styles/utilities';

const PlayerOverlay = props => {
  // const [overlayTitle, updateOverlayTitle] = useState('');
  // const [overlayLessonText, updateOverlayLessonText] = useState('');
  const [primaryActionText, updatePrimaryActionText] = useState('');
  const [primaryActionIcon, updatePrimaryActionIcon] = useState('');

  const {
    videoPlayerState: { currentLesson }
  } = VideoPlayerStateContainer.useContainer();

  const togglePlayMode = usePlayVideo();

  const { restartAllHook } = CommonVideoApiContainer.useContainer();
  const { restartFn, status } = restartAllHook();

  useEffect(() => {
    switch (props.courseStatus) {
      case COURSE_STATES.NEW:
        // updateOverlayTitle('Start:');
        updatePrimaryActionText('Begin');
        updatePrimaryActionIcon('play-outline');
        // updateOverlayLessonText(`${currentLesson.sectionName} - ${currentLesson.lessonName}`);
        break;
      case COURSE_STATES.PROGRESS:
        // updateOverlayTitle('Resume Watching:');
        updatePrimaryActionText('Resume');
        updatePrimaryActionIcon('play-outline');
        // updateOverlayLessonText(`${currentLesson.sectionName} - ${currentLesson.lessonName}`);
        break;
      case COURSE_STATES.COMPLETED:
        // updateOverlayTitle('Completed');
        updatePrimaryActionText('See Related Products');
        updatePrimaryActionIcon('arrow-down');
        break;
      case COURSE_STATES.PLAY_NEXT:
        // updateOverlayTitle('Up Next:');
        updatePrimaryActionText('Play Next');
        updatePrimaryActionIcon('play-outline');
        // updateOverlayLessonText(
        //   `${props.nextLesson && `${props.nextLesson.sectionName} - ${props.nextLesson.name}`}`
        // );
        break;

      default:
        break;
    }
  }, [currentLesson.lessonName, currentLesson.sectionName, props.courseStatus, props.nextLesson]);

  const triggerPlayEvent = () => {
    // Scroll to the video
    // props.courseStatus === COURSE_STATES.COMPLETED ? 'do-scroll' : ''

    // Trigger play mode
    togglePlayMode(true);

    // Update video playing state
    // toggleVideoPlaying(true);
  };

  const handleCourseComplete = async () => {
    await restartFn({
      id: props.courseId,
      restart: 1
    });
    window.location = window.location.href.split('?')[0];
  };

  return (
    <div
      className={`player-overlay-wrapper ${
        props.courseStatus === COURSE_STATES.COMPLETED ? 'course-complete-overlay' : ''
      }`}
    >
      <div className="player-overlay-contents">
        {props.courseStatus === COURSE_STATES.COMPLETED && (
          <p className="course-complete-icon pad-b-20">
            <Icon icon="circle-tick" color={colors.green} />
          </p>
        )}

        {/* <h1 className="txt-white">{overlayTitle}</h1>
        {props.courseStatus !== COURSE_STATES.COMPLETED && (
          <h2 className="current-lesson">{overlayLessonText}</h2>
        )} */}

        <div className="primary-action">
          {props.courseStatus === COURSE_STATES.PLAY_NEXT && (
            <Button
              as={UnstyledLink}
              to={`?${setQueryParams({
                sectionId: props.nextLesson && props.nextLesson.sectionId,
                lessonId: props.nextLesson && props.nextLesson.id,
                currentLessonTime: 0
              })}`}
              onClick={triggerPlayEvent}
            >
              {primaryActionText} <Icon icon={primaryActionIcon} />
            </Button>
          )}

          {(props.courseStatus === COURSE_STATES.NEW ||
            props.courseStatus === COURSE_STATES.PROGRESS) && (
            <Button as="span" onClick={triggerPlayEvent}>
              {primaryActionText} <Icon icon={primaryActionIcon} />
            </Button>
          )}
        </div>

        {props.courseStatus === COURSE_STATES.COMPLETED && (
          <div className="secondary-action">
            <UnstyledLink to="?" className="h4 txt-green" onClick={handleCourseComplete}>
              {status === 'loading' ? 'Restarting' : 'Restart'}
            </UnstyledLink>
          </div>
        )}
      </div>
    </div>
  );
};

PlayerOverlay.propTypes = {
  courseStatus: PropTypes.string.isRequired,
  nextLesson: PropTypes.object,
  courseId: PropTypes.number.isRequired
};

PlayerOverlay.defaultProps = {
  nextLesson: null
};

export default PlayerOverlay;
