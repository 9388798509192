/** @jsx jsx */
import { jsx } from '@emotion/core';
import PropTypes from 'prop-types';
import { spacer } from '@styles/utilities';
import Input from 'components/htmlElements/Fields/Input';
import * as styles from './SearchHandler.styles';

const SearchFilter = ({ searchHandler }) => {
  return (
    <div css={[styles.searchInputWrapper, spacer.mrB30]}>
      <Input onChange={searchHandler} placeholder="Search" id="search-input" />
    </div>
  );
};

SearchFilter.propTypes = {
  searchHandler: PropTypes.func.isRequired
};

export default SearchFilter;
