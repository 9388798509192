import styled from '@emotion/styled';
import styledMap from 'styled-map';
import { typography, colors, misc } from '@styles/utilities';

const H6 = styled.h6`
  font-family: ${typography.fonts.gothamRounded.fontFamily};
  font-weight: ${styledMap`
    book: ${typography.fonts.gothamRounded.fontWeight.book};
    default: ${typography.fonts.gothamRounded.fontWeight.medium};
  `};
  line-height: 140%;
  font-style: normal;
  margin: ${misc.defaultTextMargin};
  letter-spacing: normal;
  color: ${props => (props.color ? colors[props.color] : colors.black)};
  font-size: 12px;
`;

export default H6;
