/* eslint-disable import/prefer-default-export */
import { css } from '@emotion/core';

import { colors, misc, breakpoints } from '@styles/utilities';

export const modalGlobalStyles = css`
  .ReactModal__Body--open {
    overflow: hidden;
  }
`;

export const modalWrapper = css`
  background-color: ${colors.blackTransparent54};
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: ${misc.modalZindex};
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  > * {
    flex: 1 1 auto;
    pointer-events: all;
  }
`;

export const contents = css`
  background-color: ${colors.white};
  border-radius: ${misc.mediumBorderRadius};
  ${breakpoints({
    padding: ['', '', '', '', '']
  })}
`;
